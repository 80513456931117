import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import {
  REFERENCES_DETAILS,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  createTitle,
  enterOnlyAlphabates,
  enterOnlyEmail,
  enterOnlyNumbers,
  isAlphabet,
  isArrayNotEmpty,
  isValidEmail,
  isValidMobileNumber
} from '../../utils/utils';
import {
  commonBorrowerDetailsAdd,
  commonBorrowerDetailsUpdate,
  handleBorrowerBackStep,
  validateBorrowerDetails
} from '../../redux/reducers/borrowerSlice';
import {
  fetchFamilyRelationTypeList,
  fetchNonFamilyRelationTypeList
} from '../../redux/reducers/dropDownSlice';
import { useEffect } from 'react';
import { setLayoutTitle } from '../../redux/reducers/layoutSlice';

const labelStyle = {
  display: 'flex',
  flexDirection: 'column'
};

const formControl = {
  margin: '0.5rem auto'
};

function ReferencesBorrower({ borrowerId }) {
  const dispatch = useDispatch();

  const { referenceDetails, borrowerDetails } = useSelector(
    state => state.borrowers
  );

  const { familyNonFamilyRelationshipList } = useSelector(
    state => state.dropdown
  );

  useEffect(() => {
    document.title = createTitle('References');
    dispatch(
      setLayoutTitle({
        title: 'Borrower',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-borrower'
      })
    );
    dispatch(fetchFamilyRelationTypeList());
  }, []);

  useEffect(() => {
    if (referenceDetails?.referenceTag === 'nonFamily') {
      dispatch(fetchNonFamilyRelationTypeList());
    }
    formik.setValues({
      userId: borrowerId,
      referenceTag: referenceDetails?.referenceTag || 'family',
      referenceName: referenceDetails?.referenceName,
      referenceRelationship: referenceDetails?.referenceRelationship || '',
      mobileNo: referenceDetails?.mobileNo,
      emailId: referenceDetails?.emailId
    });
  }, [referenceDetails]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: borrowerId,
      referenceTag: 'family',
      referenceName: '',
      referenceRelationship: '',
      mobileNo: '',
      emailId: ''
    },
    validationSchema: yup.object({
      referenceTag: yup.string().required('Family reference name is required'),
      referenceName: yup
        .string()
        .required('Reference name is required.')
        .test(
          'is-alphabet',
          'Reference Name should consist of alphabetic characters only.',
          value => isAlphabet(value)
        )
        .trim(),
      referenceRelationship: yup.string().required('Relationship is required.'),
      mobileNo: yup
        .string()
        .required('Mobile number is required.')
        .test(
          'is-valid-mobile-number',
          'Mobile number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        ),
      emailId: yup
        .string()
        .test('is-email', 'Please enter a valid email', value => {
          return isValidEmail(value);
        })
        .trim()
    }),
    onSubmit: async values => {
      const newValues = {
        ...values,
        referenceName: values.referenceName.trim()
      };
      handleReferenceSubmit(newValues);
    }
  });

  const handleReferenceSubmit = async values => {
    let borrower = await validateBorrowerDetails(borrowerId);

    const { referenceDetails } = borrower;

    if (!referenceDetails) {
      const newvalues = {
        borrowerDetails: {
          ...borrowerDetails
        },
        referenceDetails: { ...values },
        action: REFERENCES_DETAILS
      };

      dispatch(commonBorrowerDetailsAdd(newvalues));
    } else {
      const newvalues = {
        borrowerDetails: {
          ...borrowerDetails
        },
        referenceDetails: { ...values, id: referenceDetails?.id },
        action: REFERENCES_DETAILS
      };
      dispatch(commonBorrowerDetailsUpdate(newvalues));
    }
  };

  const handleReferenceTag = async value => {
    formik.setValues({
      referenceTag: value,
      referenceName: '',
      referenceRelationship: '',
      mobileNo: '',
      emailId: ''
    });
    switch (value) {
      case 'nonFamily':
        await dispatch(fetchNonFamilyRelationTypeList());
        break;
      case 'family':
        await dispatch(fetchFamilyRelationTypeList());
        break;
      default:
        break;
    }
  };

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <FormContent>
        <Typography
          marginBottom={'1rem'}
          align="center"
          variant="body1"
          fontWeight={700}
        >
          References
        </Typography>

        <FormControl sx={{ margin: '10px' }} fullWidth size="small">
          <RadioGroup
            name="referenceTag"
            value={formik.values.referenceTag}
            onChange={e => handleReferenceTag(e.target.value)}
          >
            <Grid container justifyContent="center">
              <Grid item>
                <Tooltip placement="top" title="Family">
                  <FormControlLabel
                    name="referenceTag"
                    value="family"
                    control={<Radio color="golden" />}
                    label={<Typography sx={labelStyle}>Family</Typography>}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip placement="top" title="Non-Family">
                  <FormControlLabel
                    name="referenceTag"
                    value="nonFamily"
                    control={<Radio color="golden" />}
                    label={<Typography sx={labelStyle}>Non-Family</Typography>}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </RadioGroup>
          {formik.touched.referenceTag && formik.errors.referenceTag && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {formik.errors.referenceTag}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <InputLabel id="select-relation" required>
            Relationship
          </InputLabel>
          <Select
            error={
              formik.touched.referenceRelationship &&
              formik.errors.referenceRelationship
            }
            labelId="select-relation"
            id="select-relation"
            label="Relationship"
            value={formik.values.referenceRelationship}
            onChange={formik.handleChange}
            name="referenceRelationship"
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {isArrayNotEmpty(familyNonFamilyRelationshipList) &&
              familyNonFamilyRelationshipList.map(relation => {
                return (
                  <MenuItem key={relation?.key} value={relation?.key}>
                    <em>{relation?.value}</em>
                  </MenuItem>
                );
              })}
          </Select>
          {formik.touched.referenceRelationship &&
            formik.errors.referenceRelationship && (
              <FormHelperText sx={{ textAlign: 'start' }}>
                {formik.errors.referenceRelationship}
              </FormHelperText>
            )}
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            fullWidth
            size="small"
            label="References Name"
            required
            inputProps={{ maxLength: 50 }}
            name="referenceName"
            value={formik.values.referenceName}
            onChange={e =>
              formik.setFieldValue(
                'referenceName',
                enterOnlyAlphabates(e.target.value)
              )
            }
            error={Boolean(
              formik.touched.referenceName && formik.errors.referenceName
            )}
            helperText={
              formik.touched.referenceName && formik.errors.referenceName
            }
          />
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            fullWidth
            size="small"
            label="References Mobile No"
            required
            inputProps={{ maxLength: 10 }}
            tel="tel"
            name="mobileNo"
            value={formik.values.mobileNo}
            onChange={e =>
              formik.setFieldValue('mobileNo', enterOnlyNumbers(e.target.value))
            }
            error={Boolean(formik.touched.mobileNo && formik.errors.mobileNo)}
            helperText={formik.touched.mobileNo && formik.errors.mobileNo}
          />
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            fullWidth
            type="email"
            size="small"
            label="References Email ID"
            name="emailId"
            value={formik.values.emailId}
            onChange={e =>
              formik.setFieldValue('emailId', enterOnlyEmail(e.target.value))
            }
            error={Boolean(formik.touched.emailId && formik.errors.emailId)}
            helperText={formik.touched.emailId && formik.errors.emailId}
          />
        </FormControl>

        <FormButtonBottom>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleBorrowerBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>

            <Grid item xs={6}>
              <Tooltip title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </FormContent>
    </form>
  );
}

export default ReferencesBorrower;
