import { FormButtonBottom } from '../../utils/styledLayout';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DOCUMENT_UPLOAD,
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_BANK_STATEMENT_OCR,
  DOC_TYPE_PAN,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import UploadAndViewImage from '../../components/UploadAndViewImage';
import {
  clearDocumentUploadState,
  borrowerDocumentList,
  borrowerUploadDocument,
  borrowerDeleteDocument,
  fetchBorrowerDocuments
} from '../../redux/reducers/documentSlice';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { colorDanger } from '../../config/theme';
import {
  commonBorrowerDetailsAdd,
  handleBorrowerBackStep
} from '../../redux/reducers/borrowerSlice';

const formControl = {
  margin: '0.5rem auto'
};

const dashedDividerStyle = {
  borderTop: '2px dashed #636363',
  marginTop: '16px',
  marginBottom: '16px'
};

const DashedDivider = () => {
  return <Divider style={dashedDividerStyle} />;
};

function DocumentUploadBorrower({ borrowerId }) {
  const dispatch = useDispatch();

  const [isDocumentUploadHasError, setIsDocumentUploadHasError] = useState({
    status: false,
    message: ''
  });

  useEffect(() => {
    document.title = createTitle('Document Upload');
    if (borrowerId) {
      dispatch(borrowerDocumentList({ userId: borrowerId }));
    }
    return () => dispatch(clearDocumentUploadState());
  }, []);

  const {
    documentDetails,
    isDocumentUploadLoading,
    isDocumentUploadSuccess,
    isDocumentUploadFailed,
    documentUploadErrorContainer,
    isDeleteDocumentLoading,
    isDeleteDocumentSuccess,
    isDeleteDocumentFailed,
    deleteDocumentErrorContainer
  } = useSelector(state => state.documents);

  const { borrowerDetails } = useSelector(state => state.borrowers);

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('file', fileData?.file);
      dispatch(
        borrowerUploadDocument({
          data: formData,
          userId: borrowerId,
          docType: fileData?.docType
        })
      );
    }
  };

  const handleDelete = (filename, docType) => {
    if (filename) {
      dispatch(
        borrowerDeleteDocument({
          fileName: filename,
          userId: borrowerId,
          docType: docType
        })
      );
    }
  };

  const validateFileUploaded = async documentTypes => {
    const uploadedDocuments = await fetchBorrowerDocuments(borrowerId);

    const types = {
      [DOC_TYPE_PAN]: 'PAN Card',
      [DOC_TYPE_AADHAAR_FRONT]: 'Aadhaar Front',
      [DOC_TYPE_AADHAAR_BACK]: 'Aadhaar Back',
      [DOC_TYPE_BANK_STATEMENT_OCR]: 'Bank Statement'
    };

    if (isArrayNotEmpty(uploadedDocuments)) {
      uploadedDocuments.forEach(document => {
        const { documentType } = document;
        if (documentTypes && documentTypes.includes(documentType)) {
          const indexToRemove = documentTypes.indexOf(documentType);
          documentTypes.splice(indexToRemove, 1);
        }
      });
    }

    const validationResult = {};

    for (const documentType of documentTypes) {
      if (
        documentDetails &&
        documentType in documentDetails &&
        documentDetails[documentType] !== ''
      ) {
        validationResult[documentType] = {
          status: true,
          message: `${types[documentType]} document is uploaded`
        };
      } else {
        validationResult[documentType] = {
          status: false,
          message: `${types[documentType]}`
        };
      }
    }

    return validationResult;
  };

  const handleSubmit = async () => {
    let documentTypesToValidate = [
      DOC_TYPE_PAN,
      DOC_TYPE_AADHAAR_FRONT,
      DOC_TYPE_AADHAAR_BACK,
      DOC_TYPE_BANK_STATEMENT_OCR
    ];

    const validationResults = await validateFileUploaded(
      documentTypesToValidate
    );

    const allDocumentsUploaded = documentTypesToValidate.every(
      documentType => validationResults[documentType].status
    );

    if (allDocumentsUploaded) {
      const newvalues = {
        borrowerDetails: {
          ...borrowerDetails
        },
        action: DOCUMENT_UPLOAD,
        backAction: borrowerDetails?.backAction
      };
      dispatch(commonBorrowerDetailsAdd(newvalues));
    } else {
      const errorMessages = documentTypesToValidate
        .filter(documentType => !validationResults[documentType].status)
        .map(documentType => validationResults[documentType].message);

      setIsDocumentUploadHasError({
        status: true,
        message: errorMessages.join(', ').concat(' is required.')
      });
    }
  };

  return (
    <>
      <Typography
        marginBottom={'1rem'}
        marginTop={3}
        align="center"
        variant="body1"
        fontWeight={700}
      >
        Document Upload
      </Typography>

      <Typography align="left" variant="body2" fontWeight={700}>
        PAN Card <span style={{ color: colorDanger }}> *</span>
      </Typography>
      <FormControl sx={formControl} fullWidth size="small">
        <FormControl
          sx={{
            marginTop: '1rem',
            marginBottom: '1.5rem'
          }}
          size="small"
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <UploadAndViewImage
                onImageUpload={onImageUpload}
                buttonName="Upload"
                handleDelete={handleDelete}
                docType={DOC_TYPE_PAN}
              />
            </Grid>
          </Grid>
        </FormControl>
      </FormControl>

      <DashedDivider />

      <Typography align="left" variant="body2" fontWeight={700}>
        Aadhaar Card<span style={{ color: colorDanger }}> *</span>
      </Typography>

      <Grid
        spacing={2}
        container
        direction="row"
        marginTop={2}
        marginBottom={4}
      >
        <Grid item xs={5} md={4} lg={4} xl={4}>
          <Typography marginBottom={2} variant="subtitle2">
            Front Side
          </Typography>
          <UploadAndViewImage
            onImageUpload={onImageUpload}
            buttonName="Upload"
            handleDelete={handleDelete}
            docType={DOC_TYPE_AADHAAR_FRONT}
          />
        </Grid>
        <Grid item xs={5} md={4} lg={4} xl={4}>
          <Typography marginBottom={2} variant="subtitle2">
            Back Side
          </Typography>
          <UploadAndViewImage
            onImageUpload={onImageUpload}
            buttonName="Upload"
            handleDelete={handleDelete}
            docType={DOC_TYPE_AADHAAR_BACK}
          />
        </Grid>
      </Grid>
      <DashedDivider />

      <Typography align="left" variant="body2" fontWeight={700}>
        Bank Statement (min 3 months - max 6 months){' '}
        <span style={{ color: colorDanger }}> *</span>
      </Typography>
      <Grid
        container
        direction="row"
        marginTop={2}
        marginBottom={4}
        spacing={2}
      >
        <Grid item xs={4}>
          <UploadAndViewImage
            onImageUpload={onImageUpload}
            buttonName="Upload"
            handleDelete={handleDelete}
            docType={DOC_TYPE_BANK_STATEMENT_OCR}
          />
        </Grid>
      </Grid>

      <DashedDivider />

      <Box>
        <FormButtonBottom>
          {isDocumentUploadHasError.status && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {isDocumentUploadHasError.message}
            </FormHelperText>
          )}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleBorrowerBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </Box>
      {(isDocumentUploadLoading || isDeleteDocumentLoading) && <Loader />}
      {!isDocumentUploadLoading && isDocumentUploadFailed && (
        <SnackBarBox
          type="error"
          message={handleError(documentUploadErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteDocumentErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentSuccess && (
        <SnackBarBox type="success" message="Document deleted successfully." />
      )}
      {!isDocumentUploadLoading && isDocumentUploadSuccess && (
        <SnackBarBox type="success" message="Document uploaded successfully." />
      )}
    </>
  );
}

DocumentUploadBorrower.propTypes = {
  borrowerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DocumentUploadBorrower;
