import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FormButtonBottom } from '../utils/styledLayout';
import {
  UNILYFE_HELP_EMAIL,
  UNILYFE_HELP_PHONE,
  UNILYFE_HELP_PHONE_LINK
} from '../utils/constant';
import { colorIconDanger, colorIconGreen, colorWhite } from '../config/theme';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const centerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
});

const Anchor = styled('a')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
});

const StyledIconButton = styled(IconButton)({
  padding: '10px',
  background: colorWhite,
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  borderRadius: '50%'
});

const StyledWhatsAppIcon = styled(WhatsAppIcon)({
  fontSize: '4rem',
  color: colorIconGreen
});
const StyledEmailIcon = styled(EmailOutlinedIcon)({
  fontSize: '4rem',
  color: colorIconDanger
});

export default function NetworthConfirmationModal({
  handleBackButton,
  handleSubmitButton
}) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const closeDialog = () => {
    handleBackButton();
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <DialogTitle id="max-width-dialog-title">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                fontWeight={700}
                marginTop={2}
                marginBottom={2}
                align="center"
                sx={{
                  fontSize: {
                    xs: 14,
                    md: 18,
                    lg: 18,
                    xl: 18
                  }
                }}
              >
                Networth Certificate Required
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="MuiDialogContentRootc">
          <DialogContentText>
            <Typography
              variant="subtitle2"
              fontWeight={800}
              marginTop={2}
              align="left"
              sx={{
                fontSize: {
                  xs: 12,
                  md: 14
                }
              }}
            >
              Review of Master Directions - Non-Banking Financial Company - Peer
              to Peer Lending Platform (Reserve Bank) Directions, 2017
            </Typography>

            <Typography
              variant="subtitle2"
              marginTop={2}
              align="left"
              sx={{
                fontSize: {
                  xs: 11,
                  md: 13
                }
              }}
            >
              The lender investing more than 10,00,000 across P2P platforms
              shall produce a certificate to P2P platforms from a practicing
              Chartered Accountant certifying minimum net-worth of 250,00,000.
              Further, all the lenders shall submit declaration to P2P platforms
              that they have understood all the risks associated with lending
              transactions and that P2P platform does not assure return of
              principal/payment of interest.
            </Typography>

            <Paper
              elevation={0}
              sx={{
                mt: 4,
                p: 2,
                borderRadius: '13px'
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={800}
                align="center"
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14
                  }
                }}
              >
                Connect to our team for assitance
              </Typography>
            </Paper>

            <Grid container marginTop={5} style={{ height: '100%' }}>
              <Grid item xs={6} style={centerStyles}>
                <Container>
                  <Tooltip placement="top" title="Connect via WhatsApp">
                    <StyledIconButton>
                      <Anchor
                        href={`https://api.whatsapp.com/send?phone=${UNILYFE_HELP_PHONE_LINK}`}
                      />
                      <StyledWhatsAppIcon
                        sx={{
                          fontSize: {
                            xs: 30,
                            md: 35,
                            lg: 40,
                            xl: 45
                          }
                        }}
                      />
                    </StyledIconButton>
                  </Tooltip>

                  <Typography
                    sx={{
                      marginTop: '1rem',
                      fontWeight: '500',
                      fontSize: {
                        xs: 11,
                        md: 14
                      }
                    }}
                  >
                    {UNILYFE_HELP_PHONE}
                  </Typography>
                </Container>
              </Grid>

              <Grid item xs={6} style={centerStyles}>
                <Container>
                  <Tooltip placement="top" title="Connect via Email">
                    <StyledIconButton>
                      <Anchor href={`mailto:${UNILYFE_HELP_EMAIL}`} />
                      <StyledEmailIcon
                        sx={{
                          fontSize: {
                            xs: 30,
                            md: 35,
                            lg: 40,
                            xl: 45
                          }
                        }}
                      />
                    </StyledIconButton>
                  </Tooltip>
                  <Typography
                    sx={{
                      marginTop: '1rem',
                      fontWeight: '500',
                      fontSize: {
                        xs: 11,
                        md: 14
                      }
                    }}
                  >
                    {UNILYFE_HELP_EMAIL}
                  </Typography>
                </Container>
              </Grid>
            </Grid>

            <Box>
              <FormButtonBottom>
                <Grid container spacing={2} marginTop={1}>
                  <Grid item xs={6}>
                    <Tooltip title="Go back to investment">
                      <LoadingButton
                        fullWidth
                        style={{ textTransform: 'uppercase' }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        type="button"
                        onClick={() => closeDialog()}
                      >
                        Go Back
                      </LoadingButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6}>
                    <Tooltip title={'Save & Next'}>
                      <LoadingButton
                        fullWidth
                        style={{ textTransform: 'uppercase' }}
                        variant="contained"
                        color="primary"
                        size="small"
                        type="button"
                        onClick={() => handleSubmitButton()}
                      >
                        Save & Next
                      </LoadingButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FormButtonBottom>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
