import {
  formatINR,
  getMonthValue,
  calculateMonthlyEMI
} from '../../utils/utils';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid, Typography } from '@mui/material';

function roundToNearestLakh(value) {
  return value;
}

const DashboardHighChartBorrower = ({ amount, tenure, roi }) => {
  const labels = Array.from({ length: tenure }, (_, index) => index + 1);

  // const totalPayment = calculateTotalLoanPayment(amount, tenure, roi);

  // const maximumAmount = useMemo(() => {
  //   const principalAmount = Number(amount);
  //   const annualInterestRate =
  //     Number(process.env.REACT_APP_RATE_OF_INTEREST) / 100;
  //   return principalAmount * Math.pow(1 + annualInterestRate, 1);
  // }, [amount]);

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: labels,
      title: {
        text: 'Tenure (Months)'
      },
      gridLineWidth: 1
    },
    yAxis: {
      max: roundToNearestLakh(Number(calculateMonthlyEMI(amount, tenure, roi))),
      title: {
        text: 'Amount(₹)'
      },
      labels: {
        formatter: function () {
          return formatINR(this.value);
        }
      }
    },
    tooltip: {
      borderRadius: 10,
      formatter: function () {
        return `<span style="font-weight: bold;">EMI ${getMonthValue(
          this.x
        )}</span><br/><br/><span style="font-size: larger;font-weight: bold;">${formatINR(
          this.y
        )}</span>`;
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        showInLegend: false,
        name: 'Expected Returns',
        data: labels.map(_tenureinMonth =>
          Number(calculateMonthlyEMI(amount, tenure, roi))
        ),
        color: '#FEC310'
      }
    ]
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        paddingTop={1}
        paddingBottom={2}
        paddingRight={1}
      >
        <Grid item>
          <Typography variant="subtitle2" fontWeight={600}>
            Monthly EMI
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" fontWeight={600}>
            {formatINR(calculateMonthlyEMI(amount, tenure, roi))}
          </Typography>
        </Grid>
      </Grid>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <HighchartsReact
          allowChartUpdate={true}
          immutable={false}
          updateArgs={[true, true, true]}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </>
  );
};

DashboardHighChartBorrower.propTypes = {
  amount: PropTypes.any,
  tenure: PropTypes.any,
  roi: PropTypes.any
};

export default DashboardHighChartBorrower;
