import { useEffect, useRef, useState } from 'react';
import { Grid, Button, Box, Tooltip, Skeleton } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { SELECT_IMAGE_MESSAGE, imageAllowedTypes } from '../utils/constant';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBarBox from './SnackBarBox';
import { handleError, isImageExtensionAllowed } from '../utils/utils';
import ImageCropper from './ImageCropper';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocumentUploadState,
  investorDeleteDocument,
  investorDownloadDocument,
  viewImage
} from '../redux/reducers/documentSlice';
import Loader from './Loader';

const uploadButton = {
  backgroundColor: '#FFF8E6',
  border: '1px dashed #F5B103',
  color: '#303157'
};

const UploadImageCheque = ({
  onImageUpload,
  buttonName = 'Upload Image',
  docName = '',
  onDelete
}) => {
  const dispatch = useDispatch();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const fileInputRef = useRef(null);

  const {
    documentDetails,
    isDeleteDocumentLoading,
    isDeleteDocumentSuccess,
    isDeleteDocumentFailed,
    deleteDocumentErrorContainer
  } = useSelector(state => state.documents);
  const { authData } = useSelector(state => state.auth);

  const [actualImage, setActualImage] = useState(null);

  const [isLoadingSavedImage, setIsLoadingSavedImage] = useState(false);

  const [currentUploadedFile, setCurrentUploadedFile] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        setIsLoadingSavedImage(true);
        const uploadedLink = documentDetails[docName];
        if (uploadedLink) {
          setCurrentUploadedFile(uploadedLink);
          const img = await viewImage(uploadedLink?.s3ObjectKey);
          setActualImage(img);
          handleDeleteImage();
        } else {
          handleClearImage();
        }
        setIsLoadingSavedImage(false);
      } catch (error) {
        console.error(error);
      }
    };
    getImage();
  }, [documentDetails, docName]);

  const handleClearImage = () => {
    setUploadedImage(null);
    setCurrentUploadedFile(null);
    setActualImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (imageUploadError.error) {
      const timeoutId = setTimeout(() => {
        setImageUploadError({
          error: false,
          errorMessage: ''
        });
        handleDeleteImage();
        onDelete();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [imageUploadError]);

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = imageAllowedTypes;
      const maxSizeMB = process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT_OCR;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            reader.onload = e => {
              setUploadedImage(e.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: SELECT_IMAGE_MESSAGE
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: SELECT_IMAGE_MESSAGE
        });
      }
    }
    handleDeleteImage();
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileUpload = file => {
    const reader = new FileReader();
    reader.onload = e => {
      setUploadedImage(e.target.result);
    };
    reader.readAsDataURL(file);
    onImageUpload({ file: file, fileName: uploadedFileName, docName: docName });
  };
  const handleDownloadImage = () => {
    dispatch(
      investorDownloadDocument({
        fileName: currentUploadedFile?.fileName,
        fileUrl: currentUploadedFile?.s3ObjectKey
      })
    );
  };

  const deleteActualImage = () => {
    dispatch(
      investorDeleteDocument({
        fileName: currentUploadedFile?.fileName,
        userId: authData?.userId,
        docType: docName
      })
    );
    handleDeleteImage(currentUploadedFile?.fileName, docName);
    handleClearImage();
    onDelete();
  };

  useEffect(() => {
    dispatch(clearDocumentUploadState());
  }, []);

  return (
    <>
      {isLoadingSavedImage ? (
        <>
          <Skeleton variant="sqaure" height={100} width={100} />
        </>
      ) : (
        <Grid item xs={12} md={12}>
          <div style={{ position: 'relative' }}>
            {uploadedImage && (
              <ImageCropper
                key={docName}
                uploadedFileName={uploadedFileName}
                incomingFile={uploadedImage}
                handleFileUpload={handleFileUpload}
                handleFileCancel={handleClearImage}
              />
            )}
            {actualImage ? (
              <>
                <Box>
                  <Tooltip
                    placement="top"
                    title={`Download ${currentUploadedFile?.fileName}`}
                  >
                    <img
                      className="imgbox"
                      src={actualImage}
                      alt="Actual Image"
                      width={120}
                      height={90}
                      onClick={handleDownloadImage}
                    />
                  </Tooltip>
                </Box>
              </>
            ) : (
              <img
                width={110}
                src="/images/image_preview.svg"
                alt="Image Preview"
              />
            )}
          </div>
          {actualImage ? (
            <Tooltip title={`Delete ${currentUploadedFile?.fileName}`}>
              <Button
                color="error"
                size="small"
                onClick={deleteActualImage}
                variant="outlined"
                sx={{ marginTop: '10px' }}
              >
                <DeleteIcon /> Delete
              </Button>
            </Tooltip>
          ) : (
            <>
              <Tooltip title={SELECT_IMAGE_MESSAGE} placement="top">
                <Button
                  component="label"
                  startIcon={<DriveFolderUploadIcon />}
                  size="small"
                  sx={uploadButton}
                >
                  {buttonName}
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept={imageAllowedTypes.join(',')}
                    onChange={handleImageUpload}
                  />
                </Button>
              </Tooltip>
            </>
          )}
          {imageUploadError.error && (
            <SnackBarBox
              type="error"
              message={handleError(imageUploadError.errorMessage)}
            />
          )}
        </Grid>
      )}
      {isDeleteDocumentLoading && <Loader />}
      {!isDeleteDocumentLoading && isDeleteDocumentFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteDocumentErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentSuccess && (
        <SnackBarBox type="success" message="Cheque deleted successfully." />
      )}
    </>
  );
};

UploadImageCheque.propTypes = {
  onImageUpload: PropTypes.func,
  buttonName: PropTypes.string,
  docName: PropTypes.string
};

export default UploadImageCheque;
