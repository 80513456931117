import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import { LoadingButton } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input';
import {
  enterOnlyNumbers,
  getName,
  handleError,
  maskMobile,
  validateChar
} from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import OtpTimer from '../../components/OtpTimer';
import {
  clearAddLeadVerificationState,
  leadOtpVerification,
  resetAddLeadDetails,
  setAgreementChanged,
  setConsentChanged
} from '../../redux/reducers/addLeadSlice';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import CloseIcon from '@mui/icons-material/Close';
import { setLayoutTitle } from '../../redux/reducers/layoutSlice';
import {
  CONSENT_ACCEPTED_SUCCESSFULLY,
  OTP_RE_SENT_TO_NEW_MOBILE_NO,
  RE_SENDING_OTP,
  VERIFYING_OTP
} from '../../utils/messages';
import { useNavigate } from 'react-router';
import { reSendOtpForLeadCreation } from '../../redux/reducers/leadManagementSlice';
import { setInvestorId } from '../../redux/reducers/investorSlice';

const formControl = {
  margin: '0.5rem auto'
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddLeadOtpVerification({ screen = '', type = '' }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    addLeadData,
    isLeadVerificationLoading,
    isLeadVerificationSuccess,
    isLeadVerificationFailed,
    leadVerificationErrorContainer
  } = useSelector(state => state.addlead);

  const {
    isReSendLeadCreationOtpLoading,
    isReSendLeadCreationOtpSuccess,
    isReSendLeadCreationOtpFailed,
    reSendLeadCreationOtpErrorContainer
  } = useSelector(state => state.leadManagement);

  useEffect(() => {
    if (!isLeadVerificationLoading && isLeadVerificationSuccess) {
      dispatch(setInvestorId(addLeadData?.id));
      dispatch(resetAddLeadDetails());
      navigate(`/add-${type}`);
      return;
    }
  }, [isLeadVerificationSuccess]);

  useEffect(() => {
    if (screen === 'edit') {
      dispatch(
        setLayoutTitle({
          title: 'Edit Lead',
          backwardLinkText: 'Go to Lead Management',
          backwardLink: `/lead-management-${type}`
        })
      );
    } else {
      dispatch(
        setLayoutTitle({
          title: 'Add Lead',
          backwardLinkText: 'Go to Lead Management',
          backwardLink: `/lead-management-${type}`
        })
      );
    }
    return () => dispatch(clearAddLeadVerificationState());
  }, []);

  const otpInputRef = useRef(null);
  const [otpExpired, setOTPExpired] = useState(false);
  const [isTermsAndConditionOpened, setIsTermsAndConditionOpened] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: yup.object({
      otp: yup
        .string()
        .test('otp', 'OTP must be of 4 digits', val => val.length === 4)
        .required('OTP is required')
    }),
    onSubmit: values => {
      let leadType =
        type === 'investor' ? { isInvestor: true } : { isBorrower: true };
      const newValues = {
        ...values,
        fullname: addLeadData?.fullname || '',
        mobileNo: addLeadData?.mobileNo || '',
        email: addLeadData?.email || '',
        ...leadType
      };
      dispatch(leadOtpVerification(newValues));
    }
  });

  const oneMinuteFromNow = 60;

  const handleOTPTimerExpired = () => {
    setOTPExpired(true);
  };

  const resendOtp = () => {
    formik.resetForm();
    if (otpExpired) {
      setOTPExpired(false);
      dispatch(reSendOtpForLeadCreation({ userId: addLeadData?.id }));
    }
  };

  const handleAgreementCheck = e => {
    dispatch(setAgreementChanged(e.target.checked));
  };
  const handleConsentCheck = e => {
    dispatch(setConsentChanged(e.target.checked));
  };
  const handleTermsAndConditions = e => {
    e.preventDefault();
    setIsTermsAndConditionOpened(true);
  };
  const handleCloseTermsAndConditions = e => {
    e.preventDefault();
    setIsTermsAndConditionOpened(false);
  };

  return (
    <>
      <Container>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box>
            <FormContent>
              <Typography
                marginBottom={'1rem'}
                align="center"
                variant="body2"
                fontWeight={500}
              >
                OTP verification
              </Typography>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  textAlign: 'start',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleAgreementCheck}
                      checked={addLeadData?.isAgreementChecked}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" fontSize="0.7rem">
                      I, {getName(addLeadData?.fullname)} agree to the{' '}
                      <span
                        onClick={handleTermsAndConditions}
                        style={{ color: '#0017d1' }}
                      >
                        terms and conditions
                      </span>{' '}
                      attached herewith.
                    </Typography>
                  }
                  style={{
                    whiteSpace: 'normal',
                    flex: '1 1 100%'
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  textAlign: 'start',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginTop: '1rem'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleConsentCheck}
                      checked={addLeadData?.isConsentChecked}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" fontSize="0.7rem">
                      I, {getName(addLeadData?.fullname)} agree the concent TWJ
                      for collecting my Aadhaar & PAN for storing and using the
                      same for KYC purpose.
                    </Typography>
                  }
                  style={{
                    whiteSpace: 'normal',
                    flex: '1 1 100%'
                  }}
                />
              </div>

              <Typography margin="1.5rem" align="center" variant="subtitle2">
                An OTP has sent to {maskMobile(addLeadData?.mobileNo)}
              </Typography>

              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                sx={formControl}
              >
                <MuiOtpInput
                  ref={otpInputRef}
                  className="otp_input_custom"
                  value={formik.values.otp}
                  onChange={newValue =>
                    formik.setFieldValue('otp', enterOnlyNumbers(newValue))
                  }
                  validateChar={validateChar}
                  TextFieldsProps={{
                    inputMode: 'tel',
                    pattern: '[0-9]*',
                    type: 'tel'
                  }}
                />
                {formik.touched.otp && formik.errors.otp && (
                  <FormHelperText sx={{ textAlign: 'center' }}>
                    {formik.errors.otp}
                  </FormHelperText>
                )}
              </FormControl>

              {!otpExpired && (
                <OtpTimer
                  expiryTimestamp={oneMinuteFromNow}
                  onTimeout={handleOTPTimerExpired}
                />
              )}
              <Typography margin="1.5rem" align="center" variant="subtitle2">
                Didn&apos;t receive the OTP?
              </Typography>

              <Tooltip
                placement="top"
                title={otpExpired ? `Click to Resend the OTP` : ''}
              >
                <Typography sx={formControl} align="center" variant="body2">
                  <Button
                    size="small"
                    variant="default"
                    onClick={resendOtp}
                    disabled={!otpExpired}
                  >
                    RESEND OTP
                  </Button>
                </Typography>
              </Tooltip>
            </FormContent>
          </Box>
          <Box>
            <FormButtonBottom>
              <LoadingButton
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                disabled={
                  !addLeadData?.isConsentChecked ||
                  !addLeadData?.isAgreementChecked
                }
              >
                Verify & Proceed
              </LoadingButton>
            </FormButtonBottom>
          </Box>
          {isTermsAndConditionOpened && (
            <>
              <Dialog
                fullWidth
                maxWidth="xs"
                open={isTermsAndConditionOpened}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleCloseTermsAndConditions(event);
                  }
                }}
                TransitionComponent={Transition}
                disableEscapeKeyDown
                scroll="body"
                className="custom-dialog"
              >
                <DialogTitle id="max-width-dialog-title">
                  <Grid container spacing={0}>
                    <Grid item xs={11}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        marginTop={1}
                      >
                        Terms and conditions
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="close"
                        onClick={e => handleCloseTermsAndConditions(e)}
                      >
                        <Tooltip title="Close">
                          <CloseIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent className="MuiDialogContentRootc">
                  <DialogContentText>
                    <div className="custom_scrollable_container">
                      <Container disableGutters sx={{ paddingRight: '10px' }}>
                        <Typography paragraph variant="body2" marginTop={2}>
                          <Tooltip
                            placement="top"
                            title="Visit our official website"
                          >
                            <a
                              href="https://tbcservices.in/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              www.TBCServices.in
                            </a>
                          </Tooltip>{' '}
                          &quot;TBC&quot;, &quot;We&quot; , &quot;Website&quot;
                          or &quot;our&quot; is an online platform to bring
                          together lenders and borrowers. The website is
                          operated and owned by TWJ Business Consulting Private
                          Limited.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          The Website provides financial services to a visitor
                          who can browse and search for persons who are willing
                          to lend, persons who are willing to borrow, details of
                          service providers such as verification agencies,
                          collection agents and other such service providers
                          that would provide services to either lenders or to
                          borrowers. For the avoidance of doubt, we shall merely
                          facilitate platform connecting lenders and borrowers
                          that is process by our partner NBFC, Lendbox
                          (Transactree Technologies Private Limited.) for the
                          transaction of borrowing and lending between two such
                          persons. We neither lend nor borrow through the
                          website. TBC is neither a financial institution, nor
                          runs a collective investment scheme or a chit fund or
                          any other such financial scheme.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          As a user (&quot;User&quot;, &quot;You&quot; or
                          &quot;Your&quot;) of this Website, it is implied that
                          you have read, understood and agreed to abide by
                          following, disclaimer, terms & conditions (&quot;Terms
                          & Conditions&quot;). These Terms & Conditions together
                          with our privacy policy as provided on the Website
                          (&quot;Privacy Policy&quot;) govern your usage of the
                          Website. By using the Website, you signify that you
                          have read, understood and are agreeing to these Terms
                          of Use, regardless of whether you are a registered
                          member of our services. If you disagree with any part
                          of these terms and conditions, please do not use the
                          Website. The content of the pages of this website is
                          for your general information and use only. It is
                          subject to change without notice. If you do not agree
                          with any of these Terms and Conditions, you must not
                          access the Website/Platform/Application. If you access
                          the Website/Platform/Application or use the TBC
                          services, the Terms and Conditions shall be a legally
                          binding agreement between you and TBC and its
                          partners.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC reserves the right to make any changes to the
                          Terms and Conditions without any prior notice to you.
                          Any changes to the Terms and Conditions shall be
                          communicated through posting of updated Terms &
                          conditions on the Website/Platform/Application, and
                          will only be effective when posted as such. The
                          Website/Platform/Application may also send emails to
                          the registered user intimating such change. You agree
                          to review the Terms and Conditions regularly to
                          understand any changes.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Eligibility
                        </Typography>
                        <Typography variant="body2" paragraph>
                          The User of this Website declares and agrees that the
                          User is a natural / legal person who has attained the
                          age of at least 23 years.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Persons who cannot enter into legally binding contract
                          in accordance with the provisions of the Indian
                          Contract Act, 1872, including a person of unsound mind
                          and un-discharged, insolvents etc. are not entitled to
                          use the Website. The User should be Indian and should
                          have Indian Citizenship status. The User should also
                          have a valid Savings Bank Account. The User is allowed
                          to utilise the services of the Website in accordance
                          with the Terms and Conditions detailed hereinafter.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          If the User violates any of these Terms & Conditions,
                          or otherwise violate an agreement entered into through
                          the medium of the Website, TBC may, delete User&apos;s
                          profile and any content or information posted by the
                          User on the Website and/or prohibit the User from
                          using or accessing the Website at any time in its sole
                          discretion, with or without notice, including without
                          limitation if it believes that you are under the age
                          of 23 years or any other such violation on the part of
                          the User which is violative as per the provisions
                          listed above and the Terms and Conditions mentioned
                          hereunder:
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Acceptance Of Terms and Conditions
                        </Typography>

                        <Typography variant="body2" paragraph>
                          As a User of this Website you have agreed to the Terms
                          and Conditions that TBC has provided hereunder or
                          anywhere else in this Website including but not
                          limited to disclaimers on this Website. You are
                          advised to read and understand the said Terms and
                          Conditions and in case you do not wish to agree to
                          these Terms and Conditions, please refrain from using
                          the Website/Platform/Application.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Fees for Services
                        </Typography>

                        <Typography variant="body2" paragraph>
                          There is no service charge or any other fee for
                          browsing on the Website/Platform/Application. TBC may,
                          at its sole discretion, introduce new services and
                          modify some or all of the existing services offered.
                          In such an event, TBC reserves the right to introduce
                          fees for the new services offered or amend/introduce
                          fees for existing services, as the case may be.
                          Changes to the fee policies shall be posted on the
                          Website/App and shall automatically become effective
                          immediately. Unless otherwise stated, all fees shall
                          be quoted in Indian Rupees and be payable to TBC
                          within such time as specified in the invoice. The
                          processing fee may vary from one Loan to the other.
                          All users shall register and open an Account by paying
                          the fee as is listed on Website/Platform/Application.
                          Borrower may be required to pay a loan posting fee to
                          make his/her request for loan visible to investors for
                          funding. Loan posting fee is part of Loan processing
                          fee and will be adjusted against the final loan
                          processing fee. Remaining loan processing fee will be
                          deducted at the time of loan disbursal from the
                          disbursal amount itself. Processing fee details are
                          available on the Website/Platform/Application. Details
                          of this fee are available on the
                          Website/Platform/Application. All the fee mentioned
                          does not include any applicable taxes which will be
                          exclusive and charged in addition to the fees. In case
                          of any default in payment, TBC reserves the right to
                          issue a warning, temporarily/indefinitely/permanently
                          suspend the User’s membership of the
                          Website/Platform/Application and reserves the right to
                          take legal action against the user. The User can pay
                          TBC through certain designated gateway services
                          available on the Website/Platform/Application or
                          direct transfer to bank account of TBC.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Not with standing anything contained in this Terms and
                          Conditions, all borrowers must pay TBC&apos;s service
                          fees immediately after the first transfer of funds
                          from any one of the investors in the entire loan.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          All Investors must transfer the funds within 7 days of
                          receipt of the loan agreement and confirmation from
                          Lendbox about the receipt of security cheques.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          All borrowers must issue three blank security cheques
                          in favour of each investor in the loan deal and send
                          the same to Lendbox. The security cheques are to
                          contain the investor&apos;s name and the
                          borrower&apos;s signature but the date and amount is
                          to be left blank. Security cheques shall be utilised
                          and encashed to recover the outstanding amount
                          including principle, interest, penalties and late
                          payment charges in the event of default by the
                          borrower/user.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          All borrowers must pay the EMI on or before the due
                          date or be liable to pay late fees of 2% per month
                          calculated on a daily basis
                        </Typography>

                        <Typography variant="body2" paragraph>
                          By signing up, all Lendbox users authorize Lendbox to
                          perform background checks on their profiles in
                          accordance with Anti-money-laundering laws, if
                          required.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Your Account
                        </Typography>

                        <Typography variant="body2" paragraph>
                          By using the Website, you agree that you are
                          responsible for maintaining the confidentiality of
                          your account and password and for restricting access
                          to your computer to prevent unauthorised access to
                          your account. You agree to accept responsibility for
                          all activities that occur under your account or
                          password. You should take all necessary steps to
                          ensure that the password is kept confidential and
                          secure and should inform us immediately if you have
                          any reason to believe that your password has become
                          known to anyone else, or if the password is being, or
                          is likely to be, used in an unauthorised manner.
                          Please ensure that the details you provide us with are
                          correct and complete and inform us immediately of any
                          changes to the information that you provided when
                          registering. You can access and update much of the
                          information you provided us with in
                          &apos;dashboard&apos; area of the website after you
                          log-in or by writing to us at support@tbcservices.in.
                          You undertake, not to directly communicate in any
                          manner with the other users of this Website. All
                          communication between prospective Lenders and
                          Borrowers should be through TBC on this Website. TBC
                          reserves the right to refuse access to the Website,
                          terminate accounts, remove or edit content at any time
                          without notice to you. The right to use this Website
                          is personal to the User and is not transferable to any
                          other person or entity. TBC or Lendbox shall not be
                          responsible for any data lost while transmitting
                          information on the Internet. By submitting your
                          contact details, you authorise TBC to call or text you
                          for the purpose of account opening, advertising and
                          marketing even though you may be registered on DND.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Cancellation By TBC
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC reserves the right, at its sole discretion, to
                          accept, refuse or cancel any request for registrations
                          on the website for any reason. TBC may also require
                          additional verifications or information before
                          accepting any instruction or registering you on the
                          portal as a Lender or a Borrower. The Borrower(s) and
                          Lender(s) are required to note that negative third
                          party verification report(s) are crucial factors
                          considered during the funding process. The funding may
                          get rejected at any stage due to lack of information
                          or verifiable information.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Payments
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Bank information collected for the purpose of payment
                          for using our services will be stored securely using a
                          256 bit SSL encryption technology or on a cloud.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Bank information will be masked on your account and
                          will not be visible to TBC&apos;s Customer Service
                          operators.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          You hereby acknowledge, comprehend, and affirm that
                          the banking information furnished by you for the
                          purpose of availing services on TBC is both accurate
                          and precise. Moreover, you explicitly undertake not to
                          employ a bank account that is unlawfully under your
                          ownership, and consequently, you must refrain from
                          executing or endeavouring to execute payments for
                          services through deceitful banking particulars.
                          Furthermore, you commit and assure that you will
                          furnish TBC with accurate and valid banking
                          information. It is agreed that your designated bank
                          account shall remain active and operational, devoid of
                          any dormant status, and devoid of any limitations or
                          encumbrances imposed by the bank on transactions
                          associated with said account.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Additionally, the aforementioned data will not be
                          utilised or shared by TBC or Lendbox to any external
                          parties, except in cases where such revelation is
                          necessitated for the purposes of fraud validation, or
                          as mandated by legal statutes, regulations, or court
                          directives. It is explicitly clarified that TBC or
                          Lendbox shall not assume responsibility for any
                          instances of banking fraud. The onus for any
                          fraudulent use of a bank account rests solely upon
                          you, and the burden to establish a contrary stance
                          shall squarely lie with you.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Fraudulent /Declined Transactions
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Please note TBC employees or representatives will
                          never ask for any OTPs, personal, financial or
                          transactional information.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC and its affiliates do not take any responsibility
                          for any financial losses incurred by its customers
                          through fraudulent calls, scam artists or any
                          information being given out willingly by its customers
                          where the scam artists may use the Lendbox/Per
                          Annum/Transactree payments systems to commit such
                          frauds.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC reserves the right to initiate legal proceedings
                          against any persons for fraudulent use of the Website
                          and any other unlawful acts or omissions in breach of
                          these terms and conditions.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Security Cheques from Borrowers
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC shall maintain possession of the security cheques
                          that are issued by the borrowers subsequent to the
                          loan&apos;s execution. The utilization of said
                          security cheques will solely occur subsequent to
                          deliberation with both the borrower and the investors.
                          It is explicitly stated that TBC or Lendbox bear no
                          responsibility for the enforceability of the cheques,
                          including their potential dishonour upon presentation
                          to the bank in the event of payment default or delay.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Interest Rate Format
                        </Typography>

                        <Typography variant="body2" paragraph>
                          The interest rate will be communicated to you over an
                          email attaching a Key Fact Statement (KFS) based on
                          approval.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Loan approvals and information provided
                        </Typography>

                        <Typography variant="body2" paragraph>
                          The information provided in the registration form is
                          property of TBC and is no guarantee that a loan will
                          be provided to the user. TBC and Lendbox will not
                          distribute the complete information without prior
                          consent from the user and it will only use the
                          information to assist in the user&apos;s endeavour to
                          get a loan. The borrower registration form is only
                          used for initial assessment purposes and TBC does not
                          provide a guarantee that a loan will be provided even
                          after all the information is provided by the user.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Modification Of Terms & Conditions
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC reserves the right, at its sole discretion, to
                          modify or replace these Terms and conditions, or
                          change, suspend, or discontinue the website and its
                          services (including, but not limited to, the
                          availability of any feature, database, or Content) at
                          any time for any reason. TBC may also impose limits on
                          certain features and services or restrict your access
                          to parts or all of the Website without notice or
                          liability. It is your responsibility to check the
                          Terms and Conditions periodically for changes. Your
                          continued use of the Website following the posting of
                          any changes to the Terms and conditions constitutes
                          acceptance of those changes.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Monitoring Of the Website and Your Account
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC has the right and liberty to monitor the content
                          of the Website at all times which shall include
                          information provided in your account. The monitoring
                          of the Website is important to determine the veracity
                          of the information provided by you and that every User
                          remains in consonance with the Terms and Conditions
                          provided herein. Subject to the Terms and Conditions
                          mentioned herein TBC shall also have the right to
                          remove any objectionable content which is in
                          contravention of the Terms and Conditions herein or
                          share such information with any governmental authority
                          as per procedures laid down by the law for the time
                          being in force in India.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          License and Site Access
                        </Typography>

                        <Typography variant="body2" paragraph>
                          TBC grants you a limited license to access and make
                          personal use of the Website, but not to download
                          (other than page caching) or modify it, or any portion
                          of it, except with express written consent of TBC.
                          This license does not include any resale or commercial
                          use of this Website or its contents; any downloading
                          or copying of account information for the benefit of
                          anyone other than your use; or any use of data mining,
                          robots, or similar data gathering and extraction
                          tools. This website or any portion of this website
                          (including but not limited to any copyrighted
                          material, trademarks, or other proprietary
                          information) may not be reproduced, duplicated,
                          copied, sold, resold, visited, distributed or
                          otherwise exploited for any commercial purpose without
                          express written consent of TBC and / or its
                          affiliates, as may be applicable. Any nauthorised use
                          of the Website shall terminate the permission or
                          license granted by TBC.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Limitation Of Liability
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Notwithstanding anything to the contrary contained
                          herein, neither TBC nor its affiliated companies,
                          subsidiaries, officers, directors, employees or any
                          related party shall have any liability towards you or
                          to any third party for any indirect, incidental,
                          special or consequential damages or any loss of
                          revenue or profits arising under or relating to these
                          Terms and Conditions. To the maximum extent permitted
                          by law, you waive, release, discharge and hold
                          harmless TBC and its affiliated and subsidiary
                          companies, and each of their directors, officers,
                          employees, and agents, from any and all claims,
                          losses, damages, liabilities, expenses and causes of
                          action arising out of your use of the Website.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Applicable Laws
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Your use of this Website and any Term and Conditions
                          is subject to laws of India. In case of any disputes
                          arising out of the use of the Website, Courts of
                          Mumbai, Maharashtra will have exclusive jurisdiction.
                          The Arbitration & Conciliation Act, 1996 (or any
                          statutory modification or replacement thereof), shall
                          govern the arbitration proceedings. The arbitration
                          proceedings shall be in the English language.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Indemnity
                        </Typography>

                        <Typography variant="body2" paragraph>
                          You agree to indemnify, save, and hold Lendbox, its
                          affiliates, contractors, employees, officers,
                          directors, agents and its third party associates,
                          licensors, and partners harmless from any and all
                          claims, losses, damages, and liabilities, costs and
                          expenses, including without limitation legal fees and
                          expenses, arising out of or related to your use or
                          misuse of the services or of the Website, any
                          violation by you of these Terms and Conditions, or any
                          breach of the representations, warranties, and
                          covenants made by you herein. Lendbox reserves the
                          right, at your expense, to assume the exclusive
                          defence and control of any matter for which you are
                          required to indemnify Lendbox, including rights to
                          settle, and you agree to cooperate with Lendbox
                          defence and settlement of these claims. Lendbox will
                          use reasonable efforts to notify you of any claim,
                          action, or proceeding brought by a third party that is
                          subject to the foregoing indemnification upon becoming
                          aware of it.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Disclaimer Of Warranties
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Your use of the service and all information, products
                          and other content (including that of third parties)
                          included in or accessible from the Service is at your
                          sole risk. The Service is provided on an &quot;as
                          is&quot; and &quot;as available&quot; basis. Lendbox
                          expressly disclaim all warranties of any kind as to
                          the Service and all information, products and other
                          content (including that of third parties) included in
                          or accessible from the Service, whether express or
                          implied, including, but not limited to the implied
                          warranties of merchantability, fitness for a
                          particular purpose and non-infringement. Lendbox make
                          no warranty that (i) the Service will meet your
                          requirements, (ii) the Service will be uninterrupted,
                          timely, secure, or error-free, (iii) the results that
                          may be obtained from the use of the Service will be
                          accurate or reliable, (iv) the quality of any
                          products, services, information, or other material
                          purchased or obtained by you through the Service will
                          meet your expectations, or (v) any errors in the
                          technology will be corrected.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          And you are solely responsible for any damage to your
                          computer system or loss of data that results from the
                          download of any such material. No advice or
                          information, whether oral or written, obtained by you
                          from Lendbox through or from the Service will create
                          any warranty not expressly stated in these Terms and
                          Condition.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          The lenders registering on this website
                          &quot;Lenders&quot; are advised to astutely assess all
                          apparent risks and expected benefits before investing.
                          Lendbox.in is a marketplace that carefully assesses
                          and list borrower (s) profiles for all types of
                          investor(s) with varying risk appetites.
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Lendbox.in highlights all relevant details for every
                          borrower, however, decision of investment solely rest
                          with the Lenders.
                        </Typography>

                        <Typography
                          variant="body2"
                          marginTop={3}
                          marginBottom={3}
                          align="center"
                          fontWeight={700}
                          paragraph
                        >
                          Grievance Redressal Mechanism of the Platform
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Level 1:
                        </Typography>

                        <Typography variant="body2" paragraph>
                          A customer can escalate the matter to (email id) or
                          (Contact No.) in case he/she is not satisfied with the
                          response from the Customer Care team. Depending on the
                          query/dispute/grievance, a written reply/resolution
                          will be sent to the customer within 7 business days at
                          his/her registered email id with the Company.
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Level 2:
                        </Typography>

                        <Typography variant="body2" paragraph>
                          If the customer is not satisfied with the resolution
                          received from above channels, or if the customer does
                          not hear from us within fifteen (15) days, the
                          customer can address the grievance to the below
                          address for escalation:
                        </Typography>

                        <Typography variant="body2" marginTop={1}>
                          Name: Mr. Avinash Kadam
                        </Typography>
                        <Typography variant="body2" marginTop={1}>
                          Head of Operation,
                        </Typography>
                        <Typography variant="body2" marginTop={1}>
                          E-mail id: avinash.kadam@tradewithjazz.com
                        </Typography>
                        <Typography variant="body2" paragraph marginTop={1}>
                          Address: Unit – 1100 to 1110, Zion Apartment, Sector
                          10, Kharghar, Navi Mumbai, Maharashtra 410210
                        </Typography>

                        <Typography variant="body2" fontWeight={700} paragraph>
                          Level 3:
                        </Typography>

                        <Typography variant="body2" paragraph>
                          If the complaint/dispute is not redressed within a
                          period of one month from date of its receipt, the
                          customer may appeal to:
                        </Typography>

                        <Typography variant="body2" marginTop={1}>
                          CEPD
                        </Typography>
                        <Typography variant="body2" marginTop={1}>
                          Reserve Bank of India, Department of Non-Banking
                          Supervision, Central Office,
                        </Typography>
                        <Typography variant="body2" marginTop={1}>
                          Centre I, World Trade Centre, Mumbai-400 005.
                        </Typography>
                        <Typography variant="body2" paragraph marginTop={1}>
                          Email Id: cms.odtkolkata@rbi.org.in
                        </Typography>

                        <Typography variant="body2" paragraph>
                          Complaints to Ombudsman In case the Borrower does not
                          receive a response from the Grievance Redressal
                          Official or the Nodal Officer within one month from
                          the date of making a representation to the Lender, or
                          if the Borrower is not satisfied with the response so
                          received, a complaint may be made in accordance with
                          the &apos;The Ombudsman Scheme for Non-Banking
                          Financial Companies, 2018&apos; (&quot;Ombudsman
                          Scheme&quot;) to the Ombudsman in whose jurisdiction
                          the office of the Lender complained against, is
                          located. For contact details of the Ombudsman and for
                          salient features of the Ombudsman Scheme, Fair
                          Practices Code adopted by the Company and available on
                          our website https://faceofindia.org. A copy of the
                          Ombudsman Scheme is available on the website of the
                          Reserve Bank of India at www.rbi.org.in and also with
                          our Nodal.{' '}
                        </Typography>

                        <Typography fontSize="8px" paragraph>
                          Copyright: TBC Private Limited 2022
                        </Typography>
                      </Container>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </>
          )}
        </form>
      </Container>

      {isReSendLeadCreationOtpLoading && <Loader text={RE_SENDING_OTP} />}

      {!isReSendLeadCreationOtpLoading && isReSendLeadCreationOtpFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            reSendLeadCreationOtpErrorContainer?.errorMessage
          )}
        />
      )}
      {!isReSendLeadCreationOtpLoading && isReSendLeadCreationOtpSuccess && (
        <SnackBarBox type="success" message={OTP_RE_SENT_TO_NEW_MOBILE_NO} />
      )}

      {isLeadVerificationLoading && <Loader title={VERIFYING_OTP} />}

      {!isLeadVerificationLoading && isLeadVerificationFailed && (
        <SnackBarBox
          type="error"
          message={handleError(leadVerificationErrorContainer?.errorMessage)}
        />
      )}
      {!isLeadVerificationLoading && isLeadVerificationSuccess && (
        <SnackBarBox type="success" message={CONSENT_ACCEPTED_SUCCESSFULLY} />
      )}
    </>
  );
}

export default AddLeadOtpVerification;
