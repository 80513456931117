import { Backdrop, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { colorWhite } from '../config/theme';

function Loader({ text = 'Processing... Please wait.' }) {
  return (
    <Backdrop sx={{ zIndex: 5, color: colorWhite }} open={true}>
      <div>
        <CircularProgress color="golden" size={60} />
        <Typography sx={{ color: 'white' }} fontWeight={600}>
          {text}
        </Typography>
      </div>
    </Backdrop>
  );
}
Loader.propTypes = {
  text: PropTypes.string
};
export default Loader;
