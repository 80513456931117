import { styled } from '@mui/material';

export const FormLayout = styled('div')(({ theme }) => ({
  // height: 'calc(100vh - 20vh)', //Removing for sometime
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  justifyItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    width: '42%',
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '10vh',
    boxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    WebkitBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    MozBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    padding: '0.5rem 0rem 1.5rem 0rem',
    height: 'max-content',
    borderRadius: '10px'
  }
}));

export const FooterLayout = styled('div')(({ theme }) => ({
  justifyItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-between',
  padding: '1rem 2rem 1rem 2rem',
  [theme.breakpoints.up('md')]: {
    padding: '1rem 2rem 1rem 2rem',
    width: '40%',
    margin: '1rem auto',
    justifyContent: 'center',
    height: 'max-content'
  }
}));
export const FormButtonBottom = styled('div')(() => ({
  margin: '2rem auto'
}));

export const FormContent = styled('div')(() => ({
  paddingTop: '2rem'
}));

export const StepperLayout = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '40%',
    margin: '0rem auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 'auto 1rem'
  }
}));

export const BoxShadowMdUp = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    WebkitBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    MozBoxShadow: '0px 0px 5px 1px rgba(145,145,145,1)',
    padding: '1.5rem',
    height: 'max-content',
    borderRadius: '10px'
  }
}));
