import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');

export const fetchGenderList = createAsyncThunk(
  'dropdown/gender',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(
        '/dropDown/identifier?dropdownIdentifier=gender'
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchAccountTypeList = createAsyncThunk(
  'dropdown/accountType',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(
        '/dropDown/identifier?dropdownIdentifier=accountType'
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchRelationTypeList = createAsyncThunk(
  'dropdown/relationType',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(
        '/dropDown/identifier?dropdownIdentifier=relationType'
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFamilyRelationTypeList = createAsyncThunk(
  'dropdown/familyRelationType',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(
        '/dropDown/identifier?dropdownIdentifier=familyRelation'
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchNonFamilyRelationTypeList = createAsyncThunk(
  'dropdown/nonFamilyRelationType',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(
        '/dropDown/identifier?dropdownIdentifier=nonFamilyRelation'
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  isGenderLoading: false,
  isGenderLoadingSuccess: false,
  isGenderLoadingFailed: false,
  genderErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  genderList: [],
  isAccountTypeLoading: false,
  isAccountTypeLoadingSuccess: false,
  isAccountTypeLoadingFailed: false,
  accountTypeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  accountTypeList: [],
  concernTypeList: [],
  isRelationTypeLoading: false,
  isRelationTypeLoadingSuccess: false,
  isRelationTypeLoadingFailed: false,
  relationTypeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  relationTypeList: [],
  isFamilyRelationTypeLoading: false,
  isFamilyRelationTypeLoadingSuccess: false,
  isFamilyRelationTypeLoadingFailed: false,
  familyRelationTypeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isNonFamilyRelationTypeLoading: false,
  isNonFamilyRelationTypeLoadingSuccess: false,
  isNonFamilyRelationTypeLoadingFailed: false,
  nonFamilyRelationTypeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  familyNonFamilyRelationshipList: []
};

export const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(fetchGenderList.pending, state => {
        state.isGenderLoading = true;
        (state.isGenderLoadingFailed = false),
          (state.genderErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchGenderList.fulfilled, (state, action) => {
        (state.isGenderLoading = false),
          (state.genderList = [...action.payload]);
      })
      .addCase(fetchGenderList.rejected, (state, action) => {
        (state.isGenderLoading = false),
          (state.isGenderLoadingFailed = false),
          (state.genderErrorContainer = {
            ...state.genderErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchAccountTypeList.pending, state => {
        state.isAccountTypeLoading = true;
        (state.isAccountTypeLoadingFailed = false),
          (state.accountTypeErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchAccountTypeList.fulfilled, (state, action) => {
        (state.isAccountTypeLoading = false),
          (state.accountTypeList = [...action.payload]);
      })
      .addCase(fetchAccountTypeList.rejected, (state, action) => {
        (state.isAccountTypeLoading = false),
          (state.isAccountTypeLoadingFailed = false),
          (state.accountTypeErrorContainer = {
            ...state.accountTypeErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchRelationTypeList.pending, state => {
        state.isRelationTypeLoading = true;
        (state.isRelationTypeLoadingFailed = false),
          (state.relationTypeErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchRelationTypeList.fulfilled, (state, action) => {
        (state.isRelationTypeLoading = false),
          (state.relationTypeList = [...action.payload]);
      })
      .addCase(fetchRelationTypeList.rejected, (state, action) => {
        (state.isRelationTypeLoading = false),
          (state.isRelationTypeLoadingFailed = false),
          (state.relationTypeErrorContainer = {
            ...state.relationTypeErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchFamilyRelationTypeList.pending, state => {
        state.isFamilyRelationTypeLoading = true;
        (state.isFamilyRelationTypeLoadingFailed = false),
          (state.familyRelationTypeErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchFamilyRelationTypeList.fulfilled, (state, action) => {
        (state.isFamilyRelationTypeLoading = false),
          (state.familyNonFamilyRelationshipList = [...action.payload]);
      })
      .addCase(fetchFamilyRelationTypeList.rejected, (state, action) => {
        (state.isFamilyRelationTypeLoading = false),
          (state.isFamilyRelationTypeLoadingFailed = false),
          (state.familyRelationTypeErrorContainer = {
            ...state.familyRelationTypeErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchNonFamilyRelationTypeList.pending, state => {
        state.isNonFamilyRelationTypeLoading = true;
        (state.isNonFamilyRelationTypeLoadingFailed = false),
          (state.nonFamilyRelationTypeErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchNonFamilyRelationTypeList.fulfilled, (state, action) => {
        (state.isNonFamilyRelationTypeLoading = false),
          (state.familyNonFamilyRelationshipList = [...action.payload]);
      })
      .addCase(fetchNonFamilyRelationTypeList.rejected, (state, action) => {
        (state.isNonFamilyRelationTypeLoading = false),
          (state.isNonFamilyRelationTypeLoadingFailed = false),
          (state.nonFamilyRelationTypeErrorContainer = {
            ...state.nonFamilyRelationTypeErrorContainer,
            ...action.payload
          });
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      });
  }
});

export default dropdownSlice.reducer;
