import { FormButtonBottom } from '../../utils/styledLayout';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DOCUMENT_UPLOAD,
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_NET_WORTH_CERT,
  DOC_TYPE_PAN,
  NET_WORTH_MANDATORY_LIMIT,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import UploadAndViewImage from '../../components/UploadAndViewImage';
import {
  clearDocumentUploadState,
  fetchDocuments,
  investorDeleteDocument,
  investorDocumentsList,
  investorUploadDocument
} from '../../redux/reducers/documentSlice';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { useEffect, useState } from 'react';
import {
  commonInvestorDetailsAdd,
  handleInvesterBackStep,
  validateInvestorDetails
} from '../../redux/reducers/investorSlice';
import ErrorIcon from '@mui/icons-material/Error';
import { colorDanger, colorGolden } from '../../config/theme';

const formControl = {
  margin: '0.5rem auto'
};

const dashedDividerStyle = {
  borderTop: '2px dashed #636363',
  marginTop: '16px',
  marginBottom: '16px'
};

const DashedDivider = () => {
  return <Divider style={dashedDividerStyle} />;
};

function DocumentUpload({ investorId }) {
  const dispatch = useDispatch();

  const [isDocumentUploadHasError, setIsDocumentUploadHasError] = useState({
    status: false,
    message: ''
  });

  useEffect(() => {
    document.title = createTitle('Document Upload');
    if (investorId) {
      dispatch(investorDocumentsList({ userId: investorId }));
    }
    return () => dispatch(clearDocumentUploadState());
  }, []);

  const {
    documentDetails,
    isDocumentUploadLoading,
    isDocumentUploadSuccess,
    isDocumentUploadFailed,
    documentUploadErrorContainer,
    isDeleteDocumentLoading,
    isDeleteDocumentSuccess,
    isDeleteDocumentFailed,
    deleteDocumentErrorContainer
  } = useSelector(state => state.documents);

  const { investorDetails } = useSelector(state => state.investor);

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('file', fileData?.file);
      dispatch(
        investorUploadDocument({
          data: formData,
          userId: investorId,
          docType: fileData?.docType
        })
      );
    }
  };

  const handleDelete = (filename, docType) => {
    if (filename) {
      dispatch(
        investorDeleteDocument({
          fileName: filename,
          userId: investorId,
          docType: docType
        })
      );
    }
  };

  const validateFileUploaded = async (documentTypes, savedInvestorDetails) => {
    const uploadedDocuments = await fetchDocuments(investorId);

    const types = {
      [DOC_TYPE_NET_WORTH_CERT]: 'Net Worth Certificate'
    };

    if (
      Number(savedInvestorDetails?.amount) < Number(NET_WORTH_MANDATORY_LIMIT)
    ) {
      delete types[DOC_TYPE_NET_WORTH_CERT];
    }

    if (isArrayNotEmpty(uploadedDocuments)) {
      uploadedDocuments.forEach(document => {
        const { documentType } = document;
        if (documentTypes && documentTypes.includes(documentType)) {
          const indexToRemove = documentTypes.indexOf(documentType);
          documentTypes.splice(indexToRemove, 1);
        }
      });
    }

    const validationResult = {};

    for (const documentType of documentTypes) {
      if (
        documentDetails &&
        documentType in documentDetails &&
        documentDetails[documentType] !== ''
      ) {
        validationResult[documentType] = {
          status: true,
          message: `${types[documentType]} document is uploaded`
        };
      } else {
        validationResult[documentType] = {
          status: false,
          message: `${types[documentType]}`
        };
      }
    }

    return validationResult;
  };

  const handleSubmit = async () => {
    let documentTypesToValidate = [];

    const savedInvestor = await validateInvestorDetails(investorId);
    const savedInvestorDetails = savedInvestor?.investorDetails;

    if (
      savedInvestorDetails &&
      Number(savedInvestorDetails?.amount) >= Number(NET_WORTH_MANDATORY_LIMIT)
    ) {
      documentTypesToValidate = [DOC_TYPE_NET_WORTH_CERT];
    }

    const validationResults = await validateFileUploaded(
      documentTypesToValidate,
      savedInvestorDetails
    );

    const allDocumentsUploaded = documentTypesToValidate.every(
      documentType => validationResults[documentType].status
    );

    if (allDocumentsUploaded) {
      const newvalues = {
        investorDetails: {
          ...investorDetails
        },
        action: DOCUMENT_UPLOAD,
        backAction: investorDetails?.backAction
      };
      dispatch(commonInvestorDetailsAdd(newvalues));
    } else {
      const errorMessages = documentTypesToValidate
        .filter(documentType => !validationResults[documentType].status)
        .map(documentType => validationResults[documentType].message);

      setIsDocumentUploadHasError({
        status: true,
        message: errorMessages.join(', ').concat('  not uploaded')
      });
    }
  };

  return (
    <>
      <Typography
        marginBottom={'1rem'}
        marginTop={3}
        align="center"
        variant="body2"
        fontWeight={700}
      >
        Document Upload
      </Typography>

      <Typography align="left" variant="body2" fontWeight={700}>
        PAN Card
      </Typography>
      <FormControl sx={formControl} fullWidth size="small">
        <FormControl
          sx={{
            marginTop: '1rem',
            marginBottom: '1.5rem'
          }}
          size="small"
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <UploadAndViewImage
                onImageUpload={onImageUpload}
                buttonName="Upload"
                handleDelete={handleDelete}
                docType={DOC_TYPE_PAN}
              />
            </Grid>
          </Grid>
        </FormControl>
      </FormControl>

      <DashedDivider />

      <Typography align="left" variant="body2" fontWeight={700}>
        Aadhaar Card
      </Typography>

      <Grid
        spacing={2}
        container
        direction="row"
        marginTop={2}
        marginBottom={4}
      >
        <Grid item xs={5} md={4} lg={4} xl={4}>
          <Typography marginBottom={2} variant="subtitle2">
            Front Side
          </Typography>
          <UploadAndViewImage
            onImageUpload={onImageUpload}
            buttonName="Upload"
            handleDelete={handleDelete}
            docType={DOC_TYPE_AADHAAR_FRONT}
          />
        </Grid>
        <Grid item xs={5} md={4} lg={4} xl={4}>
          <Typography marginBottom={2} variant="subtitle2">
            Back Side
          </Typography>
          <UploadAndViewImage
            onImageUpload={onImageUpload}
            buttonName="Upload"
            handleDelete={handleDelete}
            docType={DOC_TYPE_AADHAAR_BACK}
          />
        </Grid>
      </Grid>
      <DashedDivider />

      <Typography align="left" variant="body2" fontWeight={700}>
        Net Worth Certificate{' '}
        {Number(investorDetails?.amount) >=
          Number(NET_WORTH_MANDATORY_LIMIT) && (
          <span style={{ color: colorDanger }}>*</span>
        )}
      </Typography>
      <Box display="flex" alignItems="center" marginBottom="1rem">
        <ErrorIcon sx={{ fontSize: 'small', color: colorGolden }} />
        <Typography
          align="left"
          sx={{ fontSize: '10px' }}
          marginLeft="5px"
          variant="body2"
        >
          Net Worth Certificate is required for investments above 10 lakh
          rupees.
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        marginTop={2}
        marginBottom={4}
        spacing={2}
      >
        <Grid item xs={4}>
          <UploadAndViewImage
            onImageUpload={onImageUpload}
            buttonName="Upload"
            handleDelete={handleDelete}
            docType={DOC_TYPE_NET_WORTH_CERT}
          />
        </Grid>
      </Grid>

      <DashedDivider />

      <Box>
        <FormButtonBottom>
          {isDocumentUploadHasError.status && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {isDocumentUploadHasError.message}
            </FormHelperText>
          )}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleInvesterBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </Box>
      {(isDocumentUploadLoading || isDeleteDocumentLoading) && <Loader />}
      {!isDocumentUploadLoading && isDocumentUploadFailed && (
        <SnackBarBox
          type="error"
          message={handleError(documentUploadErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteDocumentErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentSuccess && (
        <SnackBarBox type="success" message="Document deleted successfully." />
      )}
      {!isDocumentUploadLoading && isDocumentUploadSuccess && (
        <SnackBarBox type="success" message="Document uploaded successfully." />
      )}
    </>
  );
}

DocumentUpload.propTypes = {
  investorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DocumentUpload;
