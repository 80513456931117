import { combineReducers } from 'redux';
import authSlice from './reducers/authSlice';
import addLeadSlice from './reducers/addLeadSlice';
import investorSlice from './reducers/investorSlice';
import layoutSlice from './reducers/layoutSlice';
import dropDownSlice from './reducers/dropDownSlice';
import profileSlice from './reducers/profileSlice';
import leadManagementSlice from './reducers/leadManagementSlice';
import sessionSlice from './reducers/sessionSlice';
import borrowerSlice from './reducers/borrowerSlice';
import documentSlice from './reducers/documentSlice';

const reducers = {
  auth: authSlice,
  addlead: addLeadSlice,
  investor: investorSlice,
  documents: documentSlice,
  layout: layoutSlice,
  dropdown: dropDownSlice,
  profile: profileSlice,
  leadManagement: leadManagementSlice,
  session: sessionSlice,
  borrowers: borrowerSlice
};

export default combineReducers(reducers);
