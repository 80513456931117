import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTitle } from '../utils/utils';
import { IMG_ERROR_404 } from '../utils/imageUrls';

function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    document.title = createTitle('Page Not Found');
  }, []);

  return (
    <section className="error_container div_not_found">
      <Typography className="error_message" align="center" variant="h5">
        The page {location.pathname} is not found..!
      </Typography>
      <img className="not_found_image" src={IMG_ERROR_404} />
      <Box textAlign="center" marginTop="1rem">
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => navigate('/')}
        >
          Go to Homepage
        </Button>
      </Box>
    </section>
  );
}

export default NotFound;
