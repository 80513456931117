import { useEffect, useState } from 'react';
import { createTitle, handleError } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutTitle } from '../redux/reducers/layoutSlice';
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Tooltip
} from '@mui/material';
import LoanDetailsBorrower from '../containers/borrower/LoanDetailsBorrower';
import PanVerificationBorrower from '../containers/borrower/PanVerificationBorrower';
import AadhaarVerificationBorrower from '../containers/borrower/AadhaarVerificationBorrower';
import UploadLatestPhotoBorrower from '../containers/borrower/UploadLatestPhotoBorrower';
import DocumentUploadBorrower from '../containers/borrower/DocumentUploadBorrower';
import PennyDropVerificationBorrower from '../containers/borrower/PennyDropVerificationBorrower';
import ReferencesBorrower from '../containers/borrower/ReferencesBorrower';
import SummaryBorrower from '../containers/borrower/SummaryBorrower';
import {
  getBorrowerDetails,
  getListOfProducts,
  setBackButtonActionFalse,
  setBorrowerAction
} from '../redux/reducers/borrowerSlice';
import {
  AADHAR_VERIFICATION,
  BORROWER_REQUIREMENTS,
  DOCUMENT_UPLOAD,
  FINISH,
  PAN_VERIFICATION,
  PENNY_DROP_VERIFICATION,
  REFERENCES_DETAILS,
  UPLOAD_LATEST_PHOTO,
  borrowerSteps
} from '../utils/constant';
import { useNavigate } from 'react-router-dom';
import SkeletonBars from '../components/SkeletonBars';
import Loader from '../components/Loader';
import SnackBarBox from '../components/SnackBarBox';

const stepStyle = {
  '.MuiStepConnector-line': {
    borderTopWidth: '3px',
    width: '100%',
    display: 'block'
  },
  '.MuiStepConnector-root span::before': {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '6px solid',
    width: '100%'
  },
  '.Mui-completed .MuiSvgIcon-root': {
    color: '#FEC310'
  }
};

function BorrowerStepper() {
  const steps = borrowerSteps;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, errorContainer, borrowerDetails } = useSelector(
    state => state.borrowers
  );

  const [borrowerDetailsAvailable, setBorrowerDetailsAvailable] =
    useState(false);

  useEffect(() => {
    document.title = createTitle('Borrower');
    dispatch(
      setLayoutTitle({
        title: 'Borrower',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-borrower'
      })
    );

    dispatch(setBackButtonActionFalse());

    if (borrowerDetails?.borrowerId === 0) {
      navigate('/lead-management-borrower');
      return;
    }

    fetchProducts();
    fetchPaymentStatusAndBorrowerDetails();
    return () => dispatch(setBorrowerAction(BORROWER_REQUIREMENTS));
  }, []);

  const fetchPaymentStatusAndBorrowerDetails = async () => {
    if (borrowerDetails?.borrowerId) {
      await dispatch(getBorrowerDetails(borrowerDetails?.borrowerId));
      setBorrowerDetailsAvailable(true);
    }
  };

  const fetchProducts = () => {
    dispatch(getListOfProducts());
  };

  return (
    <Container component="main" maxWidth="xs">
      {borrowerDetailsAvailable ? (
        <Box sx={{ width: '100%', marginTop: '2rem' }}>
          {borrowerDetails?.action === FINISH ? (
            <SummaryBorrower />
          ) : (
            <Stepper
              sx={stepStyle}
              activeStep={steps.indexOf(borrowerDetails?.action)}
              orientation="horizontal"
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <Tooltip
                      title={
                        steps.indexOf(borrowerDetails?.action) > index
                          ? `Click back button to revisit the previous option`
                          : `Finish the current step`
                      }
                    >
                      <StepLabel {...labelProps}></StepLabel>
                    </Tooltip>
                  </Step>
                );
              })}
            </Stepper>
          )}

          {borrowerDetails?.action === BORROWER_REQUIREMENTS && (
            <LoanDetailsBorrower borrowerId={borrowerDetails?.borrowerId} />
          )}

          {borrowerDetails?.action === PAN_VERIFICATION && (
            <PanVerificationBorrower borrowerId={borrowerDetails?.borrowerId} />
          )}

          {borrowerDetails?.action === AADHAR_VERIFICATION && (
            <AadhaarVerificationBorrower
              borrowerId={borrowerDetails?.borrowerId}
            />
          )}

          {borrowerDetails?.action === UPLOAD_LATEST_PHOTO && (
            <UploadLatestPhotoBorrower
              borrowerId={borrowerDetails?.borrowerId}
            />
          )}

          {borrowerDetails?.action === DOCUMENT_UPLOAD && (
            <DocumentUploadBorrower borrowerId={borrowerDetails?.borrowerId} />
          )}

          {borrowerDetails?.action === PENNY_DROP_VERIFICATION && (
            <PennyDropVerificationBorrower
              borrowerId={borrowerDetails?.borrowerId}
            />
          )}

          {borrowerDetails?.action === REFERENCES_DETAILS && (
            <ReferencesBorrower borrowerId={borrowerDetails?.borrowerId} />
          )}
        </Box>
      ) : (
        <>
          <SkeletonBars />
        </>
      )}

      {isLoading && <Loader text="Please wait.." />}
      {!isLoading && isError && (
        <SnackBarBox
          type="error"
          message={handleError(errorContainer?.errorMessage)}
        />
      )}
    </Container>
  );
}

export default BorrowerStepper;
