import {
  Box,
  FormControl,
  Grid,
  Input,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import { colorGolden } from '../../config/theme';
import ErrorIcon from '@mui/icons-material/Error';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  BORROWER_REQUIREMENTS,
  SELECT_NEXT_BUTTON
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import {
  calculateTotalLoanPayment,
  createTitle,
  enterOnlyNumbers,
  formatINR
} from '../../utils/utils';
import DashboardHighChartBorrower from '../dashboard/DashboardHighChartBorrower';
import { useDispatch, useSelector } from 'react-redux';
import {
  commonBorrowerDetailsAdd,
  commonBorrowerDetailsUpdate,
  validateBorrowerDetails
} from '../../redux/reducers/borrowerSlice';
import { setLayoutTitle } from '../../redux/reducers/layoutSlice';
import { useEffect, useState } from 'react';
import TimeLineComponent from '../../components/TimeLineComponent';

const formControl = {
  margin: '0.5rem auto'
};

function LoanDetailsBorrower({ borrowerId }) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [tenureList] = useState([3, 6, 9, 12]);

  const { borrowerDetails } = useSelector(state => state.borrowers);

  const borrowingDetails = borrowerDetails?.borrowingDetails
    ? borrowerDetails?.borrowingDetails
    : [];

  const borrowingDetailId = Array.isArray(borrowingDetails)
    ? borrowingDetails[0]
    : {};

  useEffect(() => {
    document.title = createTitle('Loan Details');
    dispatch(
      setLayoutTitle({
        title: 'Borrower',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-borrower'
      })
    );
  }, []);

  useEffect(() => {
    const index = tenureList.findIndex(
      element => element === Number(borrowingDetailId?.tenureInMonths)
    );
    if (index !== -1) setActiveStep(Number(index));

    formik.setValues({
      userId: borrowerId || 0,
      tenureInMonths: borrowingDetailId?.tenureInMonths || 3,
      amount: borrowingDetailId?.amount,
      roi: borrowingDetailId?.roi || '24'
    });
  }, [borrowingDetailId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tenureInMonths: borrowingDetailId?.tenureInMonths || 3,
      amount: borrowingDetailId?.amount || '',
      roi: borrowingDetailId?.roi || '24',
      userId: borrowerId || 0
    },
    validationSchema: yup.object({
      tenureInMonths: yup
        .number()
        .required('Please provide the required tenure.')
        .positive('The tenure must be positive.')
        .moreThan(0, 'The tenure must be greater than 0 Month')
        .max(12, 'Maximum tenure should be 12 Months')
        .min(3, 'Minimum tenure should be 3 Months'),
      amount: yup
        .number()
        .required('Please enter the necessary loan amount.')
        .positive('The loan amount must be positive.')
        .moreThan(0, 'The loan amount must be greater than 0 rupees')
        .max(
          Number(process.env.REACT_APP_MAXIMUM_LOAN_LIMIT),
          `Maximum loan amount limit is ${formatINR(
            Number(process.env.REACT_APP_MAXIMUM_LOAN_LIMIT)
          )} .`
        )
        .min(
          Number(process.env.REACT_APP_MINIMUM_LOAN_LIMIT),
          `Minimum loan amount limit is ${formatINR(
            Number(process.env.REACT_APP_MINIMUM_LOAN_LIMIT)
          )} .`
        ),
      roi: yup
        .string()
        .required('Please enter the ROI.')
        .test('valid-roi', 'Allowed ROI is 21 and 24', value => {
          return value === '21' || value === '24';
        })
    }),
    onSubmit: async values => {
      await handleLoanRequirementSubmit(values);
    }
  });

  const handleLoanRequirementSubmit = async values => {
    let borrower = await validateBorrowerDetails(borrowerId);

    const { borrowerDetails } = borrower;

    if (!borrowerDetails) {
      const newvalues = {
        borrowerDetails: { ...values },
        action: BORROWER_REQUIREMENTS
      };

      dispatch(commonBorrowerDetailsAdd(newvalues));
    } else {
      const newvalues = {
        borrowerDetails: { ...values, id: borrowerDetails?.id },
        action: BORROWER_REQUIREMENTS
      };
      dispatch(commonBorrowerDetailsUpdate(newvalues));
    }
  };

  const handleTenureChange = value => {
    formik.setFieldValue('tenureInMonths', value);
    const index = tenureList.findIndex(element => element === Number(value));
    setActiveStep(Number(index));

    if (value >= 3 && value <= 6) {
      formik.setFieldValue('roi', '24');
    } else if (value >= 9 && value <= 12) {
      formik.setFieldValue('roi', '21');
    }
  };

  const handleLoanAmount = amount => {
    if (!amount || isNaN(amount)) {
      formik.setFieldTouched('amount', false);
      formik.setFieldError('amount', '');
    }

    if (Number(amount) > Number(process.env.REACT_APP_MAXIMUM_LOAN_LIMIT)) {
      formik.setFieldTouched('amount', true);
      formik.setFieldError(
        'amount',
        `Maximum loan amount limit is ${formatINR(
          Number(process.env.REACT_APP_MAXIMUM_LOAN_LIMIT)
        )}.`
      );
    } else if (
      Number(amount) < Number(process.env.REACT_APP_MINIMUM_LOAN_LIMIT)
    ) {
      formik.setFieldTouched('amount', true);
      formik.setFieldError(
        'amount',
        `Minimum loan amount limit is ${formatINR(
          Number(process.env.REACT_APP_MINIMUM_LOAN_LIMIT)
        )}.`
      );
    }

    formik.setFieldValue('amount', enterOnlyNumbers(amount));
  };

  return (
    <>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormContent>
          <Typography
            marginBottom={'1rem'}
            align="center"
            variant="body1"
            fontWeight={700}
          >
            Loan Details
          </Typography>

          <FormControl sx={formControl} fullWidth size="small">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography>Tenure</Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: 'inline-flex' }}>
                <label style={{ marginTop: '15px', marginRight: '8px' }}>
                  Months
                </label>
                <Input
                  value={formik.values.tenureInMonths}
                  error={Boolean(
                    formik.touched.tenureInMonths &&
                      formik.errors.tenureInMonths
                  )}
                  readOnly
                  size="small"
                  inputProps={{
                    type: 'number',
                    'aria-labelledby': 'tenureInMonths-slider'
                  }}
                />
              </Grid>
            </Grid>

            <Box>
              <TimeLineComponent
                steps={[3, 6, 9, 12]}
                activeStep={activeStep}
                handleStepClick={handleTenureChange}
              />
            </Box>
          </FormControl>

          <FormControl sx={formControl} fullWidth size="small">
            <TextField
              size="small"
              label="Amount(₹)"
              required
              name="amount"
              type="tel"
              inputProps={{ maxLength: 10 }}
              value={formik.values.amount}
              onChange={e => handleLoanAmount(e.target.value)}
              error={Boolean(formik.touched.amount && formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </FormControl>

          <Box display="flex" alignItems="center" marginBottom="1rem">
            <ErrorIcon sx={{ fontSize: 'large', color: colorGolden }} />
            <Typography marginLeft="5px" variant="body2">
              10K Minimum, 10 lacs maximum.
            </Typography>
          </Box>

          <FormControl sx={formControl} fullWidth size="small">
            <TextField
              size="small"
              label="ROI"
              name="roi"
              type="tel"
              onChange={e =>
                formik.setFieldValue('roi', enterOnlyNumbers(e.target.value))
              }
              required
              inputProps={{ maxLength: 3, readOnly: true }}
              value={`${formik.values.roi}%`}
              error={Boolean(formik.touched.roi && formik.errors.roi)}
              helperText={formik.touched.roi && formik.errors.roi}
            />
          </FormControl>

          <FormControl sx={formControl} fullWidth size="small">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="subtitle2" fontWeight={500}>
                  Total Payment
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight={700}
                  style={{ marginRight: '8px', color: '#FEC310' }}
                >
                  {formatINR(
                    calculateTotalLoanPayment(
                      formik.values.amount,
                      formik.values.tenureInMonths,
                      formik.values.roi
                    )
                  )}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>

          {formik.values.amount && (
            <FormControl sx={formControl} fullWidth size="small">
              <DashboardHighChartBorrower
                amount={formik.values.amount}
                tenure={formik.values.tenureInMonths}
                roi={formik.values.roi}
              />
            </FormControl>
          )}
        </FormContent>

        <Box>
          <FormButtonBottom>
            <Tooltip title={SELECT_NEXT_BUTTON} placement="top">
              <LoadingButton
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                autoFocus={false}
              >
                Save & next
              </LoadingButton>
            </Tooltip>
          </FormButtonBottom>
        </Box>
      </form>
    </>
  );
}

export default LoanDetailsBorrower;
