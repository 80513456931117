export const IMG_SPLASH_LOGO = '/images/splash_logo.png';

export const IMG_SPLASH_LOGO_LEFT_TOP = '/images/splash_left_top.svg';

export const IMG_SPLASH_LOGO_RIGHT_BOTTOM = '/images/splash_right_bottom.svg';

export const IMG_SPLASH_FULL_LOGO = '/images/tbc_splash_full_logo.png';

export const IMG_ERROR_404 = '/images/error_404.gif';

export const IMG_ERROR_403 = '/images/error_403.gif';

export const IMG_LENDBOX = '/images/lendbox.png';

export const IMG_RBI = '/images/rbi_logo.png';

export const IMG_PERSON_AVTAR = '/images/person_preview.png';

export const IMG_PERSON_AVTAR_SMALL =
  '/images/person.jpg'; /** For Profile Picture */

export const IMG_PDF_PREVIEW = '/images/pdf_preview.png';

export const IMG_IMAGE_PREVIEW = '/images/image_preview.svg';

export const IMG_SUCCESS = '/images/success.png';

export const IMG_FAILED = '/images/failed.png';
