import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colorGolden } from '../config/theme';

function AccordionComponent({ title, children, titleStyleProp, ...props }) {
  return (
    <Accordion
      className="accordian"
      sx={{
        marginTop: '1rem',
        boxShadow: '0px 0px 3px 2px rgb(226 226 226 / 80%)',
        borderRadius: '10px!important',
        border: 'none!important'
      }}
      elevation={0}
      {...props}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: colorGolden, fontSize: '2rem' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="subtitle2"
          fontWeight={600}
          style={{ ...titleStyleProp }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AccordionComponent;
