import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import {
  DOC_TYPE_BANK_STATEMENT_OCR,
  USER_TYPE_BORROWER,
  USER_TYPE_INVESTOR
} from '../../utils/constant';
import { categorizeDocuments, getImageDetails } from '../../utils/utils';
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');

export const clearDocumentUploadState = createAction(
  'CLEAR_DOCUMENT_UPLOAD_STATE'
);
export const clearDocumentDownloadState = createAction(
  'CLEAR_DOCUMENT_DOWNLOAD_STATE'
);
export const clearAgreementSignedState = createAction(
  'CLEAR_AGREEMENT_SIGNED_STATE'
);

export const updateUploadedDocumentDetails = createAction(
  'UPDATE_UPLOADED_DOCUMENT_DETAILS'
);

export const setDocumentDetails = createAction('SET_DOCUMENT_DETAILS');

export const investorUploadDocument = createAsyncThunk(
  'documents/upload',
  async ({ data, userId, docType }, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        `/documents?userId=${userId}&userType=${USER_TYPE_INVESTOR}&documentType=${docType}`,
        data
      );

      const docList = await api.get(
        `/documents/list?userId=${userId}&userType=${USER_TYPE_INVESTOR}`
      );

      const imageDetails = getImageDetails(response.data, docList.data);

      return {
        response: response.data,
        moduleType: docType,
        imageDetails: imageDetails
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const borrowerUploadDocument = createAsyncThunk(
  'documents/borrower/upload',
  async ({ data, userId, docType }, { rejectWithValue }) => {
    let url = '';
    try {
      if (docType === DOC_TYPE_BANK_STATEMENT_OCR) {
        url = `/documents?userId=${userId}&userType=${USER_TYPE_BORROWER}&documentType=${docType}&isPerfiosRequired=true`;
      } else {
        url = `/documents?userId=${userId}&userType=${USER_TYPE_BORROWER}&documentType=${docType}`;
      }
      const response = await multipartApi.post(url, data);

      const docList = await api.get(
        `/documents/list?userId=${userId}&userType=${USER_TYPE_BORROWER}`
      );

      const imageDetails = getImageDetails(response.data, docList.data);

      return {
        response: response.data,
        moduleType: docType,
        imageDetails: imageDetails
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const investorViewDocument = createAsyncThunk(
  'documents/view',
  async ({ fileName }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/documents/view?fileName=${fileName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const investorDownloadDocument = createAsyncThunk(
  'documents/download',
  async ({ fileName, fileUrl }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/documents/download?fileUrl=${fileUrl}`, {
        responseType: 'blob'
      });

      if (response) {
        const blobData = response.data;
        const blobUrl = URL.createObjectURL(blobData);
        let filename = `${fileName}`;
        if (blobUrl) {
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          a.click();
        }
        return blobUrl;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const investorDeleteDocument = createAsyncThunk(
  'documents/delete',
  async ({ fileName, userId, docType }, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/documents?userId=${userId}&userType=${USER_TYPE_INVESTOR}&documentType=${docType}&fileName=${fileName}`
      );
      return { response: response.data, moduleType: docType };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const borrowerDeleteDocument = createAsyncThunk(
  'documents/borrower/delete',
  async ({ fileName, userId, docType }, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/documents?userId=${userId}&userType=${USER_TYPE_BORROWER}&documentType=${docType}&fileName=${fileName}`
      );
      return { response: response.data, moduleType: docType };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const investorDocumentsList = createAsyncThunk(
  'documents/list',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/documents/list?userId=${userId}&userType=${USER_TYPE_INVESTOR}`
      );
      const uploadedDocuments = categorizeDocuments(response.data);
      return { response: response.data, uploadedDocuments: uploadedDocuments };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const borrowerDocumentList = createAsyncThunk(
  'documents/borrower/list',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/documents/list?userId=${userId}&userType=${USER_TYPE_BORROWER}`
      );
      const uploadedDocuments = categorizeDocuments(response.data);
      return { response: response.data, uploadedDocuments: uploadedDocuments };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewImage = async uploadedImage => {
  try {
    const imageSource = await api.get(
      `documents/view?fileUrl=${uploadedImage}`,
      {
        responseType: 'blob'
      }
    );

    if (imageSource) {
      const blobData = imageSource.data;
      const blobUrl = URL.createObjectURL(blobData);
      return blobUrl;
    }
  } catch (error) {
    console.error('Error fetching image:', error);
  }
};

export const fetchDocuments = async userId => {
  try {
    const response = await api.get(
      `/documents/list?userId=${userId}&userType=${USER_TYPE_INVESTOR}`
    );
    return response?.data;
  } catch (error) {
    console.error('Error while fetching document list ', error);
  }
};
export const fetchBorrowerDocuments = async userId => {
  try {
    const response = await api.get(
      `/documents/list?userId=${userId}&userType=${USER_TYPE_BORROWER}`
    );
    return response?.data;
  } catch (error) {
    console.error('Error while fetching document list ', error);
  }
};

const intial = {
  documentDetails: {
    fileName: '',
    action: ''
  },
  isAllDocumentsUploaded: false,
  documentsList: [],
  signedAgreementList: [],
  isDocumentListLoading: false,
  isDocumentListSuccess: false,
  isDocumentListFailed: false,
  documentListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isBorrowerDocumentListLoading: false,
  isBorrowerDocumentListSuccess: false,
  isBorrowerDocumentListFailed: false,
  borrowerDocumentListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDocumentUploadLoading: false,
  isDocumentUploadSuccess: false,
  isDocumentUploadFailed: false,
  documentUploadErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDocumentViewLoaing: false,
  isDocumentViewSuccess: false,
  isDocumentViewFailed: false,
  documentViewErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeleteDocumentLoading: false,
  isDeleteDocumentSuccess: false,
  isDeleteDocumentFailed: false,
  deleteDocumentErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const documentSlice = createSlice({
  name: 'documents',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(setDocumentDetails, (state, action) => {
        const { moduleType, imageDetails } = action.payload;
        const newUploadedFile = { [moduleType]: imageDetails };
        state.documentDetails = {
          ...state.documentDetails,
          ...newUploadedFile
        };
      })
      .addCase(investorUploadDocument.pending, state => {
        (state.isDocumentUploadLoading = true),
          (state.isDocumentUploadFailed = false),
          (state.documentUploadErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(investorUploadDocument.fulfilled, (state, action) => {
        const { response, moduleType, imageDetails } = action.payload;
        state.isDocumentUploadLoading = false;
        state.isDocumentUploadSuccess = true;
        state.isAllDocumentsUploaded = response?.isAllDocumentsUploaded;

        const newUploadedFile = { [moduleType]: imageDetails };
        state.documentDetails = {
          ...state.documentDetails,
          ...newUploadedFile
        };
      })
      .addCase(investorUploadDocument.rejected, (state, action) => {
        (state.isDocumentUploadLoading = false),
          (state.isDocumentUploadSuccess = false),
          (state.isDocumentUploadFailed = true),
          (state.documentUploadErrorContainer = {
            ...state.documentUploadErrorContainer,
            ...action.payload
          });
      })
      .addCase(investorDeleteDocument.pending, state => {
        (state.isDeleteDocumentLoading = true),
          (state.isDeleteDocumentSuccess = false),
          (state.isDeleteDocumentFailed = false),
          (state.deleteDocumentErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(investorDeleteDocument.fulfilled, (state, action) => {
        const { moduleType } = action.payload;
        state.isDeleteDocumentLoading = false;
        state.isDeleteDocumentSuccess = true;

        const newUploadedFile = state.documentDetails;
        delete newUploadedFile[moduleType];
        state.documentDetails = {
          ...state.documentDetails,
          ...newUploadedFile
        };
      })
      .addCase(investorDeleteDocument.rejected, (state, action) => {
        (state.isDeleteDocumentLoading = false),
          (state.isDeleteDocumentSuccess = false),
          (state.isDeleteDocumentFailed = true),
          (state.deleteDocumentErrorContainer = {
            ...state.deleteDocumentErrorContainer,
            ...action.payload
          });
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(clearDocumentUploadState, state => {
        (state.isDocumentUploadSuccess = false),
          (state.isDocumentUploadFailed = false),
          (state.isDeleteDocumentLoading = false),
          (state.isDeleteDocumentSuccess = false);
      })
      .addCase(clearAgreementSignedState, state => {
        (state.isFetchSignedDocumentSuccess = false),
          (state.isFetchSignedDocumentFailed = false),
          (state.isDownloadSignedDocumentSuccess = false),
          (state.isDownloadSignedDocumentFailed = false);
      })
      .addCase(clearDocumentDownloadState, state => {
        (state.isDownloadReportSuccess = false),
          (state.isDownloadReportFailed = false);
      })
      .addCase(investorDocumentsList.pending, state => {
        (state.documentDetails = {}),
          (state.documentsList = []),
          (state.isDocumentListLoading = true),
          (state.isDocumentListSuccess = false),
          (state.isDocumentListFailed = false),
          (state.documentListErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(investorDocumentsList.fulfilled, (state, action) => {
        const { response, uploadedDocuments } = action.payload;
        state.isDocumentListLoading = false;
        state.isDocumentListSuccess = true;
        state.documentsList = [...response];
        state.documentDetails = {
          ...state.documentDetails,
          ...uploadedDocuments
        };
      })
      .addCase(investorDocumentsList.rejected, (state, action) => {
        (state.isDocumentListLoading = false),
          (state.isDocumentListSuccess = false),
          (state.isDocumentListFailed = true),
          (state.documentListErrorContainer = {
            ...state.documentListErrorContainer,
            ...action.payload
          });
      })
      .addCase(borrowerDocumentList.pending, state => {
        (state.documentDetails = {}),
          (state.documentsList = []),
          (state.isDocumentListLoading = true),
          (state.isDocumentListSuccess = false),
          (state.isDocumentListFailed = false),
          (state.documentListErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(borrowerDocumentList.fulfilled, (state, action) => {
        const { response, uploadedDocuments } = action.payload;
        state.isDocumentListLoading = false;
        state.isDocumentListSuccess = true;
        state.documentsList = [...response];
        state.documentDetails = {
          ...state.documentDetails,
          ...uploadedDocuments
        };
      })
      .addCase(borrowerDocumentList.rejected, (state, action) => {
        (state.isDocumentListLoading = false),
          (state.isDocumentListSuccess = false),
          (state.isDocumentListFailed = true),
          (state.documentListErrorContainer = {
            ...state.documentListErrorContainer,
            ...action.payload
          });
      })

      .addCase(borrowerUploadDocument.pending, state => {
        (state.isDocumentUploadLoading = true),
          (state.isDocumentUploadFailed = false),
          (state.documentUploadErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(borrowerUploadDocument.fulfilled, (state, action) => {
        const { response, moduleType, imageDetails } = action.payload;
        state.isDocumentUploadLoading = false;
        state.isDocumentUploadSuccess = true;
        state.isAllDocumentsUploaded = response?.isAllDocumentsUploaded;

        const newUploadedFile = { [moduleType]: imageDetails };
        state.documentDetails = {
          ...state.documentDetails,
          ...newUploadedFile
        };
      })
      .addCase(borrowerUploadDocument.rejected, (state, action) => {
        (state.isDocumentUploadLoading = false),
          (state.isDocumentUploadSuccess = false),
          (state.isDocumentUploadFailed = true),
          (state.documentUploadErrorContainer = {
            ...state.documentUploadErrorContainer,
            ...action.payload
          });
      })

      .addCase(borrowerDeleteDocument.pending, state => {
        (state.isDeleteDocumentLoading = true),
          (state.isDeleteDocumentSuccess = false),
          (state.isDeleteDocumentFailed = false),
          (state.deleteDocumentErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(borrowerDeleteDocument.fulfilled, (state, action) => {
        const { moduleType } = action.payload;
        state.isDeleteDocumentLoading = false;
        state.isDeleteDocumentSuccess = true;

        const newUploadedFile = state.documentDetails;
        delete newUploadedFile[moduleType];
        state.documentDetails = {
          ...state.documentDetails,
          ...newUploadedFile
        };
      })
      .addCase(borrowerDeleteDocument.rejected, (state, action) => {
        (state.isDeleteDocumentLoading = false),
          (state.isDeleteDocumentSuccess = false),
          (state.isDeleteDocumentFailed = true),
          (state.deleteDocumentErrorContainer = {
            ...state.deleteDocumentErrorContainer,
            ...action.payload
          });
      });
  }
});

export default documentSlice.reducer;
