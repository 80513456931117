import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp } from '../../redux/reducers/authSlice';
import {
  createTitle,
  enterEmailOrMobile,
  isEmail,
  isValidEmailId
} from '../../utils/utils';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GenericInput from '../../components/GenericInput';
import React, { useEffect, useState } from 'react';
import { FormButtonBottom } from '../../utils/styledLayout';
import OtpVerification from './OtpVerification';
import { OTP_VERIFICATION_MESSAGE } from '../../utils/messages';
import SnackBarBox from '../../components/SnackBarBox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ title, isOpen, handleClose, children }) => {
  const closeDialog = handleClose => {
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog(event, reason);
        }
      }}
      TransitionComponent={Transition}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogTitle id="max-width-dialog-title">
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              marginTop={2}
              align="left"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              className="closeButton"
              onClick={() => closeDialog(handleClose)}
            >
              <Tooltip title="Close">
                <CloseIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="MuiDialogContentRootc">
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

function UserLogin() {
  const dispatch = useDispatch();
  const { isOtpRequestSucess } = useSelector(state => state.auth);
  const [isEnterOTP, setIsEnterOTP] = useState(false);

  useEffect(() => {
    document.title = createTitle('Verify Otp');
  }, []);

  const handleSubmit = values => {
    if (isEmail(values.username)) {
      dispatch(sendOtp({ email: values.username }));
    } else {
      dispatch(sendOtp({ mobileNo: values.username }));
    }
  };

  useEffect(() => {
    if (isOtpRequestSucess) {
      setIsEnterOTP(true);
    }
  }, [isOtpRequestSucess]);

  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .required('Email ID or Mobile number is required')
        .test(
          'is-email-or-phone',
          'Please enter a valid email or mobile number',
          value => {
            return isValidEmailId(value) || /^\d{10}$/.test(value);
          }
        )
    }),
    onSubmit: handleSubmit
  });

  const handleOnlyEmailOrMobile = (e, field) => {
    formik.setFieldValue(field, enterEmailOrMobile(e.target.value));
  };

  return (
    <Box pt={7}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormControl variant="outlined" fullWidth size="small">
          <GenericInput
            label="Email ID or Mobile number"
            name="username"
            inputProps={{ maxLength: 64 }}
            value={formik.values.username}
            onChange={e => handleOnlyEmailOrMobile(e, 'username')}
            error={formik.touched.username && formik.errors.username}
            startIcon={<MailOutlineIcon className="icon_color" />}
          />
        </FormControl>
        <FormButtonBottom>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ textTransform: 'uppercase' }}
            sx={{ mt: 3, mb: 2 }}
          >
            Get OTP
          </LoadingButton>
        </FormButtonBottom>
      </form>
      <Modal
        isOpen={isEnterOTP}
        handleClose={() => {
          setIsEnterOTP(false);
        }}
        title={OTP_VERIFICATION_MESSAGE}
      >
        <OtpVerification username={formik.values.username} />
      </Modal>
      {isOtpRequestSucess && (
        <SnackBarBox
          type="success"
          message={`An OTP has been send to the : ${formik.values.username}`}
        />
      )}
    </Box>
  );
}

export default UserLogin;
