export const imageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const CaptureImageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const UploadDocumentAllowedTypes = [
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];
export const UploadPdfAllowedTypes = ['application/pdf'];
export const imageAllowedExtention = ['jpg', 'jpeg'];
export const UploadDocumentAllowedExtention = ['jpg', 'jpeg', 'pdf'];
export const CaptureImageAllowedExtention = ['jpg', 'jpeg'];
export const IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT} MB.`;
export const OCR_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG format.`;
export const UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG or PDF format.`;
export const UPLOAD_DOCUMENT_SELECT_PDF_MESSAGE = `Please upload file in PDF format.`;

export const BORROWER_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_BORROWER_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG, PNG, or PDF format.`;
export const BORROWER_IMAGE_ALLOWED_TYPE = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
];
export const BORROWER_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png', 'pdf'];

export const NET_WORTH_MANDATORY_LIMIT = 1000000;

export const USER_TYPE = 'User';
export const RERECTED_TO_THE_LOGIN_PAGE =
  'You will be redirected to the login page';
export const GO_BACK = 'Go Back';

export const PASSWORD_RESET_TYPE = 'password';
export const PIN_RESET_TYPE = 'pin';
export const CREATE_ACCOUNT = 'Create Account';
export const UNEXPECTED_ERROR =
  'It appears that there is an error on the server.';

export const INVESTOR_REQUIREMENT = 'investorRequirements';
export const PAN_VERIFICATION = 'panVerification';
export const AADHAR_VERIFICATION = 'aadhaarVerification';
export const UPLOAD_LATEST_PHOTO = 'photoUpload';
export const PENNY_DROP_VERIFICATION = 'bankAccountVerification';
export const NOMINEE_DETAILS = 'nomineeDetails';
export const DOCUMENT_UPLOAD = 'documentUpload';
export const INVESTOR_SUMMARY = 'investorSummary';

export const BORROWER_REQUIREMENTS = 'borrowerRequirements';
export const REFERENCES_DETAILS = 'referenceDetails';

export const USER_TYPE_INVESTOR = 'Investor';

export const USER_TYPE_BORROWER = 'Borrower';

export const OTP_VERIFICATION = 'otpVerification';
export const ADD_BASIC_DETAILS = 'addBasicDetails';
export const PASSWORD_SETUP = 'passwordSetup';
export const PIN_SETUP = 'pinSetup';
export const FINISH = 'finish';

export const LEND_BOX_INVESTOR_INITIAL = 'initial';
export const LEND_BOX_INVESTOR_REGISTRATION_STAGE = 'investorRegistration';
export const LEND_BOX_INVESTOR_DOCUMENT_UPLOAD = 'documentUpload';
export const LEND_BOX_INVESTOR_WALLET_ORDER = 'walletOrder';

export const LEND_BOX_REGISTRATION = 'lendboxRegistration';
export const LEND_BOX_E_SIGNING = 'eSigning';

export const PAYMENT_INITIATED = 'paymentInitiated';

export const CAPTURE_IMAGE = 'Capture image';

export const investorSteps = [
  INVESTOR_REQUIREMENT,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION,
  NOMINEE_DETAILS
];

export const borrowerSteps = [
  BORROWER_REQUIREMENTS,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION,
  REFERENCES_DETAILS
];

export const createUserSteps = [
  ADD_BASIC_DETAILS,
  OTP_VERIFICATION,
  PASSWORD_SETUP,
  PIN_SETUP
];

export const SELECT_NEXT_BUTTON =
  'Clicking "Save & Next" will save your details.';
export const SELECT_NOTIFY_BUTTON =
  'Clicking "Save & Notify" will trigger a mail.';
export const SELECT_BACK_BUTTON = 'Click "Back" to view your details.';

export const DOC_TYPE_PAN = 'pan';
export const DOC_TYPE_AADHAAR = 'aadhaar';
export const DOC_TYPE_NET_WORTH_CERT = 'networth';
export const DOC_TYPE_AADHAAR_FRONT = 'aadhaarFront';
export const DOC_TYPE_AADHAAR_BACK = 'aadhaarBack';
export const DOC_TYPE_PHOTO = 'photo';
export const AADHAR_OCR_FRONT = 'aadhaarFront';
export const AADHAR_OCR_BACK = 'aadhaarBack';
export const DOC_TYPE_CHEQUE_OCR = 'cheque';
export const DOC_TYPE_BANK_STATEMENT_OCR = 'bankStatement';

export const documentTypes = [
  DOC_TYPE_PAN,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_NET_WORTH_CERT
];

/** Razor Pay configs */

export const RAZORPAY_COLOR = '#FEC310';
export const RAZORPAY_COMPANY_NAME = 'UniLyfe';
export const RAZORPAY_IMAGE = '/images/splash_logo.png';

export const LENDBOX_HELP_EMAIL = 'support@lendbox.in';
export const LENDBOX_HELP_PHONE = '+91 7291029298';

export const UNILYFE_HELP_EMAIL = 'support@unilyfep2p.com';
export const UNILYFE_HELP_PHONE = '+91 7775965644';
export const UNILYFE_HELP_PHONE_LINK = '917775965644';

export const WHATSAPP_HELP_LINE = '918928380243';
