import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTitle } from '../utils/utils';
import { IMG_ERROR_403 } from '../utils/imageUrls';

function Forbidden() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = createTitle('Unauthorized');
  }, []);

  return (
    <section className="error_container div_not_found">
      <Typography className="error_message" align="center" variant="h5">
        You do not have the necessary authorization to access this resource.
      </Typography>
      <img
        style={{ marginTop: '1rem', borderRadius: '1rem' }}
        className="not_found_image"
        src={IMG_ERROR_403}
      />
      <Box textAlign="center" marginTop="1rem">
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => navigate('/')}
        >
          Go to Homepage
        </Button>
      </Box>
    </section>
  );
}

export default Forbidden;
