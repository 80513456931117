import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  // timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'public, max-age=31536000, immutable'
  }
});
export const apiNoAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'public, max-age=31536000, immutable'
  }
});

export const multipartApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  // timeout: 60000,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  }
});
