import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { FormButtonBottom } from '../../utils/styledLayout';
import {
  AADHAR_VERIFICATION,
  BORROWER_REQUIREMENTS,
  DOCUMENT_UPLOAD,
  PAN_VERIFICATION,
  PENNY_DROP_VERIFICATION,
  REFERENCES_DETAILS,
  SELECT_BACK_BUTTON,
  SELECT_NOTIFY_BUTTON,
  UPLOAD_LATEST_PHOTO
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutTitle } from '../../redux/reducers/layoutSlice';
import { createTitle, handleError } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { colorSuccess } from '../../config/theme';
import {
  clearNotificationState,
  notifyBorrower,
  setBackButtonAction,
  setBorrowerAction
} from '../../redux/reducers/borrowerSlice';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';

const dashedDividerStyle = {
  borderTop: '1.5px solid #636363',
  marginTop: '10px',
  marginBottom: '10px'
};

const DashedDivider = () => {
  return <Divider style={dashedDividerStyle} />;
};

const listItemStyle = {
  paddingLeft: '0px',
  marginLeft: '0px',
  paddingRight: '0px',
  marginRight: '0px',
  transition: 'background-color 0.3s ease'
};

const successArchStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#F3FCF7',
  height: '130px',
  borderRadius: '0 0 50% 50%'
};

const successStyle = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  marginTop: '2rem'
};

const ModalDialog = ({ fullwidth, children }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={fullwidth}
      maxWidth="xs"
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogContent className="MuiDialogContentRootc">
        {children}
      </DialogContent>
    </Dialog>
  );
};

function SummaryBorrower() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    borrowerDetails,
    isNotifyBorrowerLoading,
    isNotifyBorrowerSuccess,
    isNotifyBorrowerFailed,
    notifyBorrowerErrorContainer
  } = useSelector(state => state.borrowers);

  const { authData } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle('Borrower Summary');
    dispatch(
      setLayoutTitle({
        title: 'Borrower Summary',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-borrower'
      })
    );
    return () => dispatch(clearNotificationState());
  }, []);

  // useEffect(() => {
  //   async function fetchDetails() {
  //     try {
  //       const borrowerDetails = await validateInvestorDetails(authData?.userId);
  //       if (
  //         borrowerDetails &&
  //         borrowerDetails.action !== LEND_BOX_REGISTRATION
  //       ) {
  //         navigate('/add-investor');
  //         return;
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   fetchDetails();
  // }, []);

  const handleSubmit = () => {
    dispatch(
      notifyBorrower({
        userId: borrowerDetails?.borrowerId,
        rmId: authData?.userId
      })
    );
  };

  const handleBackStage = () => {
    dispatch(setBorrowerAction(REFERENCES_DETAILS));
    dispatch(setBackButtonAction());
    dispatch(
      setLayoutTitle({
        title: 'Borrower',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-borrower'
      })
    );
    navigate('/add-borrower');
    return;
  };

  return (
    <>
      <Container disableGutters>
        <ListItemButton sx={listItemStyle}>
          <ListItemText
            primary={
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Borrower - Quick Data Entry
              </Typography>
            }
          />
          <ListItemIcon>
            <EditNoteIcon />
          </ListItemIcon>
        </ListItemButton>

        <DashedDivider />

        <List>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(BORROWER_REQUIREMENTS));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body1" style={{ fontWeight: '600' }}>
                  Loan Details
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(PAN_VERIFICATION));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  PAN Verification
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(AADHAR_VERIFICATION));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Aadhaar Verification
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(UPLOAD_LATEST_PHOTO));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Upload Latest Photo
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(DOCUMENT_UPLOAD));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Document Upload
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(PENNY_DROP_VERIFICATION));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Bank Account Verification
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setBorrowerAction(REFERENCES_DETAILS));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  References
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
        </List>

        <Box>
          <FormButtonBottom>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Tooltip title={SELECT_BACK_BUTTON}>
                  <LoadingButton
                    fullWidth
                    style={{ textTransform: 'uppercase' }}
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      handleBackStage();
                    }}
                  >
                    Back
                  </LoadingButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={SELECT_NOTIFY_BUTTON}>
                  <LoadingButton
                    fullWidth
                    style={{ textTransform: 'uppercase' }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save & NOTIFY
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </FormButtonBottom>
        </Box>

        {!isNotifyBorrowerLoading && isNotifyBorrowerSuccess && (
          <ModalDialog fullwidth={true}>
            <Box>
              <div style={successArchStyle}></div>
              <img
                src="/images/success.png"
                alt="success"
                style={successStyle}
              />
              <Typography
                align="center"
                variant="body1"
                fontWeight={600}
                sx={{
                  fontSize: '1.5rem',
                  color: '#5EAC1B',
                  marginTop: '150px'
                }}
              >
                Congratulations!
              </Typography>

              <Typography
                align="center"
                variant="body1"
                fontWeight={600}
                marginTop={2}
              >
                Pre-KYC for the borrower profile has been done, it is subjected
                to credit decision here onwards.
              </Typography>

              <Typography
                align="center"
                variant="body1"
                fontWeight={500}
                marginTop={2}
              >
                Please ask borrower to complete the journey, for availing
                exiting loan offers.
              </Typography>

              <LoadingButton
                fullWidth
                style={{
                  textTransform: 'uppercase',
                  marginTop: '3.5rem',
                  textAlign: 'center',
                  justifyContent: 'center'
                }}
                variant="contained"
                color="primary"
                onClick={() => navigate('/lead-management-borrower')}
              >
                Ok
              </LoadingButton>
            </Box>
          </ModalDialog>
        )}

        {isNotifyBorrowerLoading && <Loader text="Sending notification" />}

        {!isNotifyBorrowerLoading && isNotifyBorrowerFailed && (
          <SnackBarBox
            type="error"
            message={handleError(notifyBorrowerErrorContainer?.errorMessage)}
          />
        )}
      </Container>
    </>
  );
}

export default SummaryBorrower;
