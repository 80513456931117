import { useEffect } from 'react';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Tooltip,
  Typography
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { FormButtonBottom } from '../../utils/styledLayout';
import {
  AADHAR_VERIFICATION,
  DOCUMENT_UPLOAD,
  INVESTOR_REQUIREMENT,
  LEND_BOX_REGISTRATION,
  NOMINEE_DETAILS,
  PAN_VERIFICATION,
  PENNY_DROP_VERIFICATION,
  SELECT_BACK_BUTTON,
  SELECT_NOTIFY_BUTTON,
  UPLOAD_LATEST_PHOTO
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutTitle } from '../../redux/reducers/layoutSlice';
import { createTitle, handleError } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import {
  clearNotificationState,
  notifyInvestor,
  setBackButtonAction,
  setInvestorAction,
  validateInvestorDetails
} from '../../redux/reducers/investorSlice';
import { colorSuccess } from '../../config/theme';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import React from 'react';

const dashedDividerStyle = {
  borderTop: '1.5px solid #636363',
  marginTop: '10px',
  marginBottom: '10px'
};

const DashedDivider = () => {
  return <Divider style={dashedDividerStyle} />;
};

const listItemStyle = {
  paddingLeft: '0px',
  marginLeft: '0px',
  paddingRight: '0px',
  marginRight: '0px',
  transition: 'background-color 0.3s ease'
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ title, children }) => {
  const closeDialog = handleClose => {
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog(event, reason);
        }
      }}
      TransitionComponent={Transition}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogTitle id="max-width-dialog-title">
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              marginTop={2}
              align="left"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="MuiDialogContentRootc">
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

function InvestorSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authData } = useSelector(state => state.auth);

  const {
    investorDetails,
    isNotifyInvestorLoading,
    isNotifyInvestorSuccess,
    isNotifyInvestorFailed,
    notifyInvestorErrorContainer
  } = useSelector(state => state.investor);

  useEffect(() => {
    document.title = createTitle('Investor Summary');
    dispatch(
      setLayoutTitle({
        title: 'Investor Summary',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-investor'
      })
    );
    return () => dispatch(clearNotificationState());
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      try {
        const investorDetails = await validateInvestorDetails(authData?.userId);
        if (
          investorDetails &&
          investorDetails.action !== LEND_BOX_REGISTRATION
        ) {
          navigate('/add-investor');
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, []);

  const handleSubmit = () => {
    dispatch(
      notifyInvestor({
        userId: investorDetails?.investorId,
        rmId: authData?.userId
      })
    );
  };

  const handleBackStage = () => {
    dispatch(setInvestorAction(NOMINEE_DETAILS));
    dispatch(setBackButtonAction());
    dispatch(
      setLayoutTitle({
        title: 'Investor',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-investor'
      })
    );
    navigate('/add-investor');
    return;
  };

  const handleDialogClose = () => {
    navigate('/lead-management-investor');
    return;
  };

  return (
    <>
      <Container>
        <ListItemButton sx={listItemStyle}>
          <ListItemText
            primary={
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Investor - Quick Data Entry
              </Typography>
            }
          />
          <ListItemIcon>
            <EditNoteIcon />
          </ListItemIcon>
        </ListItemButton>

        <DashedDivider />

        <List>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(INVESTOR_REQUIREMENT));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Investment Requirement
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(PAN_VERIFICATION));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  PAN Verification
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(AADHAR_VERIFICATION));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Aadhaar Verification
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(UPLOAD_LATEST_PHOTO));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Upload Latest Photo
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(DOCUMENT_UPLOAD));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Document Upload
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(PENNY_DROP_VERIFICATION));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Bank Account Verification
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton
            sx={listItemStyle}
            onClick={() => {
              dispatch(setInvestorAction(NOMINEE_DETAILS));
              dispatch(setBackButtonAction());
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body2" style={{ fontWeight: '600' }}>
                  Nominee Details
                </Typography>
              }
            />
            <ListItemIcon>
              <CheckCircleSharpIcon sx={{ color: colorSuccess }} />
            </ListItemIcon>
          </ListItemButton>
        </List>

        <Box>
          <FormButtonBottom>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Tooltip title={SELECT_BACK_BUTTON}>
                  <LoadingButton
                    fullWidth
                    style={{ textTransform: 'uppercase' }}
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      handleBackStage();
                    }}
                  >
                    Back
                  </LoadingButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={SELECT_NOTIFY_BUTTON}>
                  <LoadingButton
                    fullWidth
                    style={{ textTransform: 'uppercase' }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save & NOTIFY
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </FormButtonBottom>
        </Box>

        {!isNotifyInvestorLoading && isNotifyInvestorSuccess && (
          <Modal key="confirmation" title="Investor Registration">
            <Typography variant="subtitle2" marginTop={2}>
              A investor profile has been established and notified investor to
              proceed further.
            </Typography>

            <LoadingButton
              fullWidth
              style={{ textTransform: 'uppercase', marginTop: '2rem' }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleDialogClose}
            >
              Ok
            </LoadingButton>
          </Modal>
        )}

        {isNotifyInvestorLoading && <Loader text="Sending notification" />}

        {!isNotifyInvestorLoading && isNotifyInvestorFailed && (
          <SnackBarBox
            type="error"
            message={handleError(notifyInvestorErrorContainer?.errorMessage)}
          />
        )}
      </Container>
    </>
  );
}

export default InvestorSummary;
