import { FormControl, IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function Searchbar({ ...props }) {
  return (
    <FormControl fullWidth size="small">
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
      >
        <IconButton disableRipple sx={{ p: '10px' }} aria-label="menu">
          <SearchIcon />
        </IconButton>
        <InputBase
          fullWidth
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search here.."
          inputProps={{ 'aria-label': 'Search here' }}
          {...props}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </Paper>
    </FormControl>
  );
}

export default Searchbar;
