import { useEffect, useState } from 'react';
import {
  FINISH,
  INVESTOR_REQUIREMENT,
  investorSteps,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION,
  NOMINEE_DETAILS
} from '../utils/constant';
import { createTitle, handleError } from '../utils/utils';
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Tooltip
} from '@mui/material';
import {
  getInvestorDetails,
  getListOfProducts,
  setBackButtonActionFalse,
  setInvestorAction
} from '../redux/reducers/investorSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import SnackBarBox from '../components/SnackBarBox';
import InvestmentRequirement from '../containers/investor/InvestmentRequirement';
import { setLayoutTitle } from '../redux/reducers/layoutSlice';
import InvestorSummary from '../containers/investor/InvestorSummary';
import { useNavigate } from 'react-router-dom';
import SkeletonBars from '../components/SkeletonBars';
import PanVerification from '../containers/investor/PanVerification';
import AadhaarVerification from '../containers/investor/AadhaarVerification';
import UploadLatestPhoto from '../containers/investor/UploadLatestPhoto';
import DocumentUpload from '../containers/investor/DocumentUpload';
import PennyDropVerification from '../containers/investor/PennyDropVerification';
import NomineeDetails from '../containers/investor/NomineeDetails';

const stepStyle = {
  '.MuiStepConnector-line': {
    borderTopWidth: '3px',
    width: '100%',
    display: 'block'
  },
  '.MuiStepConnector-root span::before': {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '6px solid',
    width: '100%'
  },
  '.Mui-completed .MuiSvgIcon-root': {
    color: '#FEC310'
  }
};

function InvestorStepper() {
  const steps = investorSteps;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [investorDetailsAvailable, setInvestorDetailsAvailable] =
    useState(false);

  const { isLoading, isError, errorContainer, investorDetails } = useSelector(
    state => state.investor
  );

  useEffect(() => {
    document.title = createTitle('Investor');
    dispatch(setBackButtonActionFalse());

    if (investorDetails?.investorId === 0) {
      navigate('/lead-management-investor');
      return;
    }

    dispatch(
      setLayoutTitle({
        title: 'Investor',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: '/lead-management-investor'
      })
    );
    fetchProducts();
    fetchPaymentStatusAndInvestorDetails();
    return () => dispatch(setInvestorAction(INVESTOR_REQUIREMENT));
  }, []);

  const fetchPaymentStatusAndInvestorDetails = async () => {
    if (investorDetails?.investorId) {
      await dispatch(getInvestorDetails(investorDetails?.investorId));
      setInvestorDetailsAvailable(true);
    }
  };

  const fetchProducts = () => {
    dispatch(getListOfProducts());
  };

  return (
    <>
      <Container>
        {investorDetailsAvailable ? (
          <Box sx={{ width: '100%', marginTop: '2rem' }}>
            {investorDetails?.action === FINISH ? (
              <>
                <InvestorSummary />
              </>
            ) : (
              <>
                <Stepper
                  sx={stepStyle}
                  activeStep={steps.indexOf(investorDetails?.action)}
                  orientation="horizontal"
                >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <Tooltip
                          title={
                            steps.indexOf(investorDetails?.action) > index
                              ? `Click back button to revisit the previous option`
                              : `Finish the current step`
                          }
                        >
                          <StepLabel {...labelProps}></StepLabel>
                        </Tooltip>
                      </Step>
                    );
                  })}
                </Stepper>
                {investorDetails?.action === INVESTOR_REQUIREMENT && (
                  <InvestmentRequirement
                    investorId={investorDetails?.investorId}
                  />
                )}
                {investorDetails?.action === PAN_VERIFICATION && (
                  <PanVerification investorId={investorDetails?.investorId} />
                )}
                {investorDetails?.action === AADHAR_VERIFICATION && (
                  <AadhaarVerification
                    investorId={investorDetails?.investorId}
                  />
                )}
                {investorDetails?.action === UPLOAD_LATEST_PHOTO && (
                  <UploadLatestPhoto investorId={investorDetails?.investorId} />
                )}
                {investorDetails?.action === DOCUMENT_UPLOAD && (
                  <DocumentUpload investorId={investorDetails?.investorId} />
                )}
                {investorDetails?.action === PENNY_DROP_VERIFICATION && (
                  <PennyDropVerification
                    investorId={investorDetails?.investorId}
                  />
                )}
                {investorDetails?.action === NOMINEE_DETAILS && (
                  <NomineeDetails investorId={investorDetails?.investorId} />
                )}
              </>
            )}
          </Box>
        ) : (
          <>
            <SkeletonBars />
          </>
        )}

        {isLoading && <Loader />}
        {!isLoading && isError && (
          <SnackBarBox
            type="error"
            message={handleError(errorContainer?.errorMessage)}
          />
        )}
      </Container>
    </>
  );
}

export default InvestorStepper;
