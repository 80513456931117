import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  FINISH,
  INVESTOR_REQUIREMENT,
  USER_TYPE_INVESTOR,
  investorSteps
} from '../../utils/constant';
import { api, multipartApi } from '../../config/api';
import { getImageDetails } from '../../utils/utils';
import { setDocumentDetails } from './documentSlice';
export const setInvestorAction = createAction('SET_INVESTER_ACTION');
export const handleInvesterNextStep = createAction('HANDLE_INVESTOR_NEXT_STEP');
export const handleInvesterBackStep = createAction('HANDLE_INVESTOR_BACK_STEP');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const setBackButtonAction = createAction('SET_BACK_BUTTON_ACTION');
export const setBackButtonActionFalse = createAction(
  'SET_BACK_BUTTON_ACTION_FALSE'
);
export const clearAadhaarVerificationState = createAction(
  'CLEAR_AADHAAR_VERIFICATION_STATE'
);
export const clearPanVerificationState = createAction(
  'CLEAR_PAN_VERIFICATION_STATE'
);
export const clearPennyDropVerificationState = createAction(
  'CLEAR_PENNY_DROP_VERIFICATION_STATE'
);
export const clearEsignState = createAction('CLEAR_E_SIGN_STATE');

export const clearNotificationState = createAction('CLEAR_NOTIFICATION_STATE');

export const resetPreDataOfOcr = createAction('RESET_PRE_DATA_OF_OCR');

export const setInvestorId = createAction('SET_INVESTOR_ID');

export const getListOfProducts = createAsyncThunk(
  'investor/dropDown/products',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/dropDown/products');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const commonInvestorDetailsAdd = createAsyncThunk(
  'investor/common',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post('/investors', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const commonInvestorDetailsUpdate = createAsyncThunk(
  'investor/common_update',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.put('/investors', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPanByPanNumber = createAsyncThunk(
  'investor/verify_pan_number',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/karza/panVerification',
        detailsToBeUploaded
      );
      return response.data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('service unavailable')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Please enter a valid PAN Number.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Please enter a valid PAN Number.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyPanByPanOcr = createAsyncThunk(
  'investor/verify_pan_ocr',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        `/karza/ocrDetails`,
        detailsToBeUploaded
      );
      return response.data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('service unavailable')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'The OCR image service is currently unavailable. Please try Aadhaar OTP option.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'Please upload a clear image, or try Aadhaar OTP option.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyChequeOcr = createAsyncThunk(
  'investor/verify_cheque_ocr',
  async (
    { formData, userId, ocrType, userType },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await multipartApi.post(
        `/karza/chequeOcr?userId=${userId}&ocrType=${ocrType}&userType=${userType}`,
        formData
      );

      const originalFileName = response?.data?.originalFileName;

      const docList = await api.get(
        `/documents/list?userId=${userId}&userType=${USER_TYPE_INVESTOR}`
      );
      const imageDetails = getImageDetails(
        { fileName: originalFileName },
        docList.data
      );

      dispatch(
        setDocumentDetails({ moduleType: ocrType, imageDetails: imageDetails })
      );
      return response.data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('service unavailable')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'The cheque image service is currently unavailable. Please enter the bank details manually.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'Please upload a clear image, or enter the bank details manually.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddharOtp = createAsyncThunk(
  'investor/get_addhar_otp',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post('/karza/aadhaarOTP', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAadhaarByOtp = createAsyncThunk(
  'investor/verify_aadhar_details_by_otp',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/karza/aadhaarDetailsByOTP',
        detailsToBeUploaded
      );
      return response.data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'The OTP provided is invalid. Please double-check and try again.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAadhaarByAadhaarOcr = createAsyncThunk(
  'investor/verify_aadhaar_ocr',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        '/karza/ocrDetails',
        detailsToBeUploaded
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBankDetails = createAsyncThunk(
  'investor/bankDetails',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post('/karza/pennyDrop', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'Please ensure the entered Account Number and IFSC Code is correct.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getInvestorDetails = createAsyncThunk(
  'investor/getDetailsById',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/investors?userId=${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getNameMatchDetails = createAsyncThunk(
  'investor/karza/nameMatch',
  async ({ userId, nameToBeMatched, userType }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/karza/nameMatch?userId=${userId}&userType=${userType}&nameToBeMatched=${nameToBeMatched}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateInvestorDetails = async userId => {
  try {
    const response = await api.get(`/investors?userId=${userId}`);
    return response?.data;
  } catch (error) {
    console.error('Error while fetching investor details ', error);
  }
};

export const notifyInvestor = createAsyncThunk(
  'investor/notifications/sendNotificationInvestor',
  async ({ userId, rmId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/notifications/sendNotificationToProspect?userId=${userId}&rmId=${rmId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  productList: [],
  investorDetails: {
    investorId: 0,
    action: INVESTOR_REQUIREMENT,
    backAction: false,
    id: 0,
    productType: '',
    tenureInMonths: 0,
    amount: 0,
    panNumber: '',
    nameAsPerPan: '',
    dobAsPerPan: '',
    gender: '',
    aadhaarNumber: '',
    nameAsPerAadhaar: '',
    dobAsPerAadhaar: '',
    address1: '',
    address2: '',
    landmark: '',
    pinCode: 0,
    city: '',
    state: '',
    investmentDetails: []
  },
  nomineeDetails: {
    id: 0,
    nomineeName: '',
    age: '',
    nomineeRelationship: '',
    mobileNo: ''
  },
  bankDetails: {
    accountHolderName: '',
    accountType: '',
    accountNo: '',
    confirmAccountNo: '',
    ifsc: '',
    branch: '',
    bank: '',
    address: ''
  },
  aadharOtpDetails: {
    requestId: '',
    result: {
      message: ''
    },
    statusCode: 0
  },
  isLoading: false,
  isSuccess: false,
  isNameMatched: false,
  isLoadingProducts: false,
  isLoadProductsSuccess: false,
  isLoadProductsFailed: false,
  isPanVerificationByNumberLoading: false,
  isPanVerificationByNumberSuccess: false,
  isPanVerificationByNumberFailed: false,
  isPanVerificationByOcrLoading: false,
  isPanVerificationByOcrSuccess: false,
  isPanVerificationByOcrFailed: false,
  isAadhaarVerificationByOtpLoading: false,
  isAadhaarVerificationByOtpSuccess: false,
  isAadhaarVerificationByOtpFailed: false,
  isAadhaarVerificationByOcrLoading: false,
  isAadhaarVerificationByOcrSuccess: false,
  isAadhaarVerificationByOcrFailed: false,
  isGetAadhaarOtpLoading: false,
  isGetAadhaarOtpSuccess: false,
  isGetAadhaarOtpFailed: false,
  isFetchBankDetailsLoading: false,
  isFetchBankDetailsSuccess: false,
  isFetchBankDetailsFailed: false,
  isNameMatchedLoading: false,
  isNameMatchedSuccess: false,
  isNameMatchedFailed: false,
  isEsignVerificationLoading: false,
  isEsignVerificationSuccess: false,
  isEsignVerificationFailed: false,
  EsignVerificationErrorContainer: {
    error: false,
    errorMessage: 'Esign details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isChequeVerificationByOcrLoading: false,
  isChequeVerificationByOcrSuccess: false,
  isChequeVerificationByOcrFailed: false,
  chequeVerificationErrorContainer: {
    error: false,
    errorMessage: 'Cheque details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  NameMatchedErrorContainer: {
    error: false,
    errorMessage: 'Error occured while fetching name match details.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  fetchBankDetailsErrorContainer: {
    error: false,
    errorMessage: 'Error occured while fetching aadhaar details.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  getAddharOtpErrorContainer: {
    error: false,
    errorMessage: 'Aadhaar OTP could not be received.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  aadharVerificationByOcrErrorContainer: {
    error: false,
    errorMessage: 'Aadhaar details could not be verified via image.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  aadhaarVerificationByOtpErrorContainer: {
    error: false,
    errorMessage: 'Aadhaar details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  panVerificationByOcrErrorContainer: {
    error: false,
    errorMessage: 'PAN details could not be verified via image.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  panVerificationByNumberErrorContainer: {
    error: false,
    errorMessage: 'PAN details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  productsErrorContainer: {
    error: false,
    errorMessage: 'Products retrieval unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isError: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isNotifyInvestorLoading: false,
  isNotifyInvestorSuccess: false,
  isNotifyInvestorFailed: false,
  notifyInvestorErrorContainer: {
    error: false,
    errorMessage: 'Cheque details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const investorSlice = createSlice({
  name: 'investor',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(setInvestorAction, (state, action) => {
        state.investorDetails.action = action.payload;
      })
      .addCase(setInvestorId, (state, action) => {
        state.investorDetails = {};
        state.bankDetails = {};
        state.nomineeDetails = {};
        state.investorDetails.investorId = action.payload;
      })
      .addCase(handleInvesterNextStep, state => {
        const currentIndex = investorSteps.indexOf(
          state.investorDetails.action
        );
        if (currentIndex < investorSteps.length - 1) {
          state.investorDetails.action = investorSteps[currentIndex + 1];
        } else {
          state.investorDetails.action = FINISH;
        }
      })
      .addCase(handleInvesterBackStep, state => {
        state.investorDetails.backAction = true;
        const currentIndex = investorSteps.indexOf(
          state.investorDetails.action
        );
        if (currentIndex < investorSteps.length) {
          state.investorDetails.action = investorSteps[currentIndex - 1];
        } else {
          // state.investorDetails.action = FINISH;
        }
      })
      .addCase(setBackButtonAction, state => {
        state.investorDetails.backAction = true;
      })
      .addCase(setBackButtonActionFalse, state => {
        state.investorDetails.backAction = false;
      })
      .addCase(getListOfProducts.pending, state => {
        (state.isLoadingProducts = true),
          (state.isLoadProductsSuccess = false),
          (state.isLoadProductsFailed = false);
      })
      .addCase(getListOfProducts.fulfilled, (state, action) => {
        (state.isLoadingProducts = false), (state.productList = action.payload);
      })
      .addCase(getListOfProducts.rejected, (state, action) => {
        (state.isLoadProductsSuccess = false),
          (state.isLoadingProducts = false),
          (state.isLoadProductsFailed = true),
          (state.productsErrorContainer = {
            ...state.productsErrorContainer,
            ...action.payload
          });
      })
      .addCase(commonInvestorDetailsAdd.pending, state => {
        (state.isLoading = true),
          (state.isSuccess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(commonInvestorDetailsAdd.fulfilled, (state, action) => {
        state.investorDetails.backAction = false;
        if (action.payload?.action === 'lendboxRegistration') {
          state.investorDetails.action = FINISH;
        } else {
          const currentIndex = investorSteps.indexOf(action?.payload?.action);
          if (currentIndex < investorSteps.length - 1) {
            state.investorDetails.action = investorSteps[currentIndex + 1];
          } else {
            state.investorDetails.action = FINISH;
          }
        }

        const updatedDetails = { ...state.investorDetails };
        const payloadDetails = action.payload?.investorDetails;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });

        const updatedNomineeDetails = { ...state.nomineeDetails };
        const payloadnomineeDetails = action.payload?.nomineeDetails
          ? action.payload?.nomineeDetails
          : null;

        if (payloadnomineeDetails) {
          Object.keys(payloadnomineeDetails).forEach(key => {
            if (payloadnomineeDetails[key] !== null) {
              updatedNomineeDetails[key] =
                payloadnomineeDetails[key] &&
                payloadnomineeDetails[key] !== 'null'
                  ? payloadnomineeDetails[key]
                  : '';
            }
          });
          state.nomineeDetails = { ...updatedNomineeDetails };
        }

        const updatedBankDetails = { ...state.bankDetails };
        const payloadbankDetails = action.payload?.bankDetails
          ? action.payload?.bankDetails
          : null;

        if (payloadbankDetails) {
          Object.keys(payloadbankDetails).forEach(key => {
            if (payloadbankDetails[key] !== null) {
              updatedBankDetails[key] =
                payloadbankDetails[key] && payloadbankDetails[key] !== 'null'
                  ? payloadbankDetails[key]
                  : '';
            }
          });
          state.bankDetails = { ...updatedBankDetails };
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.investorDetails = { ...updatedDetails };
      })
      .addCase(commonInvestorDetailsAdd.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isSuccess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(verifyPanByPanNumber.pending, state => {
        (state.isPanVerificationByNumberLoading = true),
          (state.isPanVerificationByNumberSuccess = false),
          (state.isPanVerificationByNumberFailed = false),
          (state.panVerificationByNumberErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyPanByPanNumber.fulfilled, (state, action) => {
        state.isPanVerificationByNumberLoading = false;
        state.isPanVerificationByNumberSuccess = true;

        const updatedDetails = { ...state.investorDetails };
        const payloadDetails = action.payload?.investorDetails
          ? action.payload?.investorDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.investorDetails = { ...updatedDetails };
      })
      .addCase(verifyPanByPanNumber.rejected, (state, action) => {
        (state.isPanVerificationByNumberLoading = false),
          (state.isPanVerificationByNumberLoading = false),
          (state.isPanVerificationByNumberFailed = true),
          (state.panVerificationByNumberErrorContainer = {
            ...state.panVerificationByNumberErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyPanByPanOcr.pending, state => {
        (state.isPanVerificationByOcrLoading = true),
          (state.isPanVerificationByOcrSuccess = false),
          (state.isPanVerificationByOcrFailed = false),
          (state.panVerificationByOcrErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyPanByPanOcr.fulfilled, (state, action) => {
        state.isPanVerificationByOcrLoading = false;
        state.isPanVerificationByOcrSuccess = true;

        const updatedDetails = { ...state.investorDetails };
        const payloadDetails = action.payload?.investorDetails
          ? action.payload?.investorDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.investorDetails = { ...updatedDetails };
      })
      .addCase(verifyPanByPanOcr.rejected, (state, action) => {
        (state.isPanVerificationByOcrLoading = false),
          (state.isPanVerificationByOcrSuccess = false),
          (state.isPanVerificationByOcrFailed = true),
          (state.panVerificationByOcrErrorContainer = {
            ...state.panVerificationByOcrErrorContainer,
            ...action.payload
          });
      })
      .addCase(getAddharOtp.pending, state => {
        (state.isGetAadhaarOtpLoading = true),
          (state.isGetAadhaarOtpSuccess = false),
          (state.isGetAadhaarOtpFailed = false),
          (state.aadharOtpDetails = {}),
          (state.getAddharOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getAddharOtp.fulfilled, (state, action) => {
        state.isGetAadhaarOtpLoading = false;
        state.isGetAadhaarOtpSuccess = true;
        state.aadharOtpDetails = {
          ...state.aadharOtpDetails,
          ...action.payload
        };
      })
      .addCase(getAddharOtp.rejected, (state, action) => {
        (state.isGetAadhaarOtpLoading = false),
          (state.aadharOtpDetails = {}),
          (state.isGetAadhaarOtpSuccess = false),
          (state.isGetAadhaarOtpFailed = true),
          (state.getAddharOtpErrorContainer = {
            ...state.getAddharOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyAadhaarByOtp.pending, state => {
        (state.isAadhaarVerificationByOtpLoading = true),
          (state.isAadhaarVerificationByOtpSuccess = false),
          (state.isAadhaarVerificationByOtpFailed = false),
          (state.aadhaarVerificationByOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyAadhaarByOtp.fulfilled, (state, action) => {
        state.isAadhaarVerificationByOtpLoading = false;
        state.isAadhaarVerificationByOtpSuccess = true;

        const updatedDetails = { ...state.investorDetails };
        const payloadDetails = action.payload?.investorDetails
          ? action.payload?.investorDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.investorDetails = { ...updatedDetails };
      })
      .addCase(verifyAadhaarByOtp.rejected, (state, action) => {
        (state.isAadhaarVerificationByOtpLoading = false),
          (state.isAadhaarVerificationByOtpSuccess = false),
          (state.isAadhaarVerificationByOtpFailed = true),
          (state.aadhaarVerificationByOtpErrorContainer = {
            ...state.aadhaarVerificationByOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyAadhaarByAadhaarOcr.pending, state => {
        (state.isAadhaarVerificationByOcrLoading = true),
          (state.isAadhaarVerificationByOcrSuccess = false),
          (state.isAadhaarVerificationByOcrFailed = false),
          (state.aadharVerificationByOcrErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyAadhaarByAadhaarOcr.fulfilled, (state, action) => {
        state.isAadhaarVerificationByOcrLoading = false;
        state.isAadhaarVerificationByOcrSuccess = true;

        const updatedDetails = { ...state.investorDetails };
        const payloadDetails = action.payload?.investorDetails
          ? action.payload?.investorDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.investorDetails = { ...updatedDetails };
      })
      .addCase(verifyAadhaarByAadhaarOcr.rejected, (state, action) => {
        (state.isAadhaarVerificationByOcrLoading = false),
          (state.isAadhaarVerificationByOcrSuccess = false),
          (state.isAadhaarVerificationByOcrFailed = true),
          (state.aadharVerificationByOcrErrorContainer = {
            ...state.aadharVerificationByOcrErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchBankDetails.pending, state => {
        (state.isFetchBankDetailsLoading = true),
          (state.isFetchBankDetailsSuccess = false),
          (state.isFetchBankDetailsFailed = false),
          (state.fetchBankDetailsErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchBankDetails.fulfilled, (state, action) => {
        state.isFetchBankDetailsLoading = false;
        state.isFetchBankDetailsSuccess = true;
        state.isFetchBankDetailsFailed = false;

        const updatedDetails = { ...state.bankDetails };
        const payloadDetails = action.payload ? action.payload : null;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.bankDetails = { ...updatedDetails };
      })
      .addCase(fetchBankDetails.rejected, (state, action) => {
        (state.isFetchBankDetailsLoading = false),
          (state.isFetchBankDetailsSuccess = false),
          (state.isFetchBankDetailsFailed = true),
          (state.fetchBankDetailsErrorContainer = {
            ...state.fetchBankDetailsErrorContainer,
            ...action.payload
          });
      })
      .addCase(getInvestorDetails.pending, state => {
        (state.isLoading = true),
          (state.isSuccess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getInvestorDetails.fulfilled, (state, action) => {
        if (action.payload?.action === 'lendboxRegistration') {
          state.investorDetails.action = FINISH;
        } else {
          const currentIndex = investorSteps.indexOf(action?.payload?.action);
          if (currentIndex < investorSteps.length - 1) {
            state.investorDetails.action = investorSteps[currentIndex + 1];
          } else {
            state.investorDetails.action = FINISH;
          }
        }

        const updatedDetails = { ...state.investorDetails };

        const payloadDetails = action.payload?.investorDetails
          ? action.payload?.investorDetails
          : null;

        const updatedBankDetails = { ...state.bankDetails };
        const payloadbankDetails = action.payload?.bankDetails
          ? action.payload?.bankDetails
          : null;

        const updatedNomineeDetails = { ...state.nomineeDetails };
        const payloadnomineeDetails = action.payload?.nomineeDetails
          ? action.payload?.nomineeDetails
          : null;

        if (payloadDetails) {
          Object.keys(payloadDetails).forEach(key => {
            if (payloadDetails[key] !== null) {
              updatedDetails[key] =
                payloadDetails[key] && payloadDetails[key] !== 'null'
                  ? payloadDetails[key]
                  : '';
            }
          });
          state.investorDetails = { ...updatedDetails };
        }
        if (payloadbankDetails) {
          Object.keys(payloadbankDetails).forEach(key => {
            if (payloadbankDetails[key] !== null) {
              updatedBankDetails[key] =
                payloadbankDetails[key] && payloadbankDetails[key] !== 'null'
                  ? payloadbankDetails[key]
                  : '';
            }
          });
          state.bankDetails = { ...updatedBankDetails };
        }
        if (payloadnomineeDetails) {
          Object.keys(payloadnomineeDetails).forEach(key => {
            if (payloadnomineeDetails[key] !== null) {
              updatedNomineeDetails[key] =
                payloadnomineeDetails[key] &&
                payloadnomineeDetails[key] !== 'null'
                  ? payloadnomineeDetails[key]
                  : '';
            }
          });
          state.nomineeDetails = { ...updatedNomineeDetails };
        }

        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(getInvestorDetails.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isSuccess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(getNameMatchDetails.pending, state => {
        (state.isNameMatchedLoading = true),
          (state.isNameMatchedSuccess = false),
          (state.isNameMatchedFailed = false),
          (state.NameMatchedErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getNameMatchDetails.fulfilled, (state, action) => {
        state.isNameMatchedLoading = false;
        state.isNameMatchedSuccess = true;
        state.isNameMatched = action.payload?.isNameMatched
          ? action.payload?.isNameMatched
          : false;
      })
      .addCase(getNameMatchDetails.rejected, (state, action) => {
        (state.isNameMatchedLoading = false),
          (state.isNameMatchedSuccess = false),
          (state.isNameMatchedFailed = true),
          (state.NameMatchedErrorContainer = {
            ...state.NameMatchedErrorContainer,
            ...action.payload
          });
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(clearAadhaarVerificationState, state => {
        (state.isAadhaarVerificationByOtpSuccess = false),
          (state.isAadhaarVerificationByOtpFailed = false),
          (state.isAadhaarVerificationByOcrSuccess = false),
          (state.isAadhaarVerificationByOcrFailed = false),
          (state.isGetAadhaarOtpSuccess = false),
          (state.isGetAadhaarOtpFailed = false);
      })
      .addCase(clearPanVerificationState, state => {
        (state.isPanVerificationByNumberSuccess = false),
          (state.isPanVerificationByNumberFailed = false),
          (state.isPanVerificationByOcrSuccess = false),
          (state.isPanVerificationByOcrFailed = false);
      })
      .addCase(clearPennyDropVerificationState, state => {
        (state.isFetchBankDetailsSuccess = false),
          (state.isFetchBankDetailsFailed = false),
          (state.isNameMatchedFailed = false),
          (state.isChequeVerificationByOcrSuccess = false),
          (state.isChequeVerificationByOcrFailed = false);
      })
      .addCase(clearEsignState, state => {
        (state.isEsignVerificationSuccess = false),
          (state.isEsignVerificationFailed = false);
      })
      .addCase(resetPreDataOfOcr, state => {
        (state.bankDetails.accountNo = ''),
          (state.bankDetails.ifsc = ''),
          (state.bankDetails.accountHolderName = ''),
          (state.bankDetails.accountType = ''),
          (state.bankDetails.confirmAccountNo = ''),
          (state.bankDetails.branch = ''),
          (state.bankDetails.bank = ''),
          (state.bankDetails.address = ''),
          (state.isFetchBankDetailsLoading = false),
          (state.isFetchBankDetailsSuccess = false);
      })
      .addCase(commonInvestorDetailsUpdate.pending, state => {
        (state.isLoading = true),
          (state.isSuccess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(commonInvestorDetailsUpdate.fulfilled, (state, action) => {
        // state.investorDetails.backAction = false;
        if (action.payload?.action === 'lendboxRegistration') {
          state.investorDetails.action = FINISH;
        } else {
          const currentIndex = investorSteps.indexOf(action?.payload?.action);
          if (currentIndex < investorSteps.length - 1) {
            state.investorDetails.action = investorSteps[currentIndex + 1];
          } else {
            state.investorDetails.action = FINISH;
          }
        }

        const updatedDetails = { ...state.investorDetails };
        const payloadDetails = action.payload?.investorDetails;

        const updatedNomineeDetails = { ...state.nomineeDetails };
        const payloadnomineeDetails = action.payload?.nomineeDetails
          ? action.payload?.nomineeDetails
          : null;

        if (payloadnomineeDetails) {
          Object.keys(payloadnomineeDetails).forEach(key => {
            if (payloadnomineeDetails[key] !== null) {
              updatedNomineeDetails[key] =
                payloadnomineeDetails[key] &&
                payloadnomineeDetails[key] !== 'null'
                  ? payloadnomineeDetails[key]
                  : '';
            } else if (payloadnomineeDetails[key] === null) {
              updatedNomineeDetails[key] =
                payloadnomineeDetails[key] &&
                payloadnomineeDetails[key] !== 'null'
                  ? payloadnomineeDetails[key]
                  : '';
            }
          });
          state.nomineeDetails = { ...updatedNomineeDetails };
        }

        const updatedBankDetails = { ...state.bankDetails };
        const payloadbankDetails = action.payload?.bankDetails
          ? action.payload?.bankDetails
          : null;

        if (payloadbankDetails) {
          Object.keys(payloadbankDetails).forEach(key => {
            if (payloadbankDetails[key] !== null) {
              updatedBankDetails[key] =
                payloadbankDetails[key] && payloadbankDetails[key] !== 'null'
                  ? payloadbankDetails[key]
                  : '';
            }
          });
          state.bankDetails = { ...updatedBankDetails };
        }

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });

        state.isLoading = false;
        state.isSuccess = true;
        state.investorDetails = { ...updatedDetails };
      })
      .addCase(commonInvestorDetailsUpdate.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isSuccess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(verifyChequeOcr.pending, state => {
        (state.isChequeVerificationByOcrLoading = true),
          (state.isChequeVerificationByOcrSuccess = false),
          (state.isChequeVerificationByOcrFailed = false),
          (state.chequeVerificationErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyChequeOcr.fulfilled, (state, action) => {
        state.isChequeVerificationByOcrLoading = false;
        state.isChequeVerificationByOcrSuccess = true;

        const updatedDetails = { ...state.bankDetails };
        const payloadDetails = action.payload ? action.payload : null;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.bankDetails = { ...updatedDetails };
      })
      .addCase(verifyChequeOcr.rejected, (state, action) => {
        (state.isChequeVerificationByOcrLoading = false),
          (state.isChequeVerificationByOcrSuccess = false),
          (state.isChequeVerificationByOcrFailed = true),
          (state.chequeVerificationErrorContainer = {
            ...state.chequeVerificationErrorContainer,
            ...action.payload
          });
      })
      .addCase(notifyInvestor.pending, state => {
        (state.isNotifyInvestorLoading = true),
          (state.isNotifyInvestorSuccess = false),
          (state.isNotifyInvestorFailed = false),
          (state.notifyInvestorErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(notifyInvestor.fulfilled, state => {
        state.isNotifyInvestorLoading = false;
        (state.isNotifyInvestorSuccess = true),
          (state.isNotifyInvestorFailed = false);
      })
      .addCase(notifyInvestor.rejected, (state, action) => {
        (state.isNotifyInvestorLoading = false),
          (state.isNotifyInvestorSuccess = false),
          (state.isNotifyInvestorFailed = true),
          (state.notifyInvestorErrorContainer = {
            ...state.notifyInvestorErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearNotificationState, state => {
        (state.isNotifyInvestorSuccess = false),
          (state.isNotifyInvestorFailed = false);
      });
  }
});

export default investorSlice.reducer;
