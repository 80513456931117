import { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTitle } from '../utils/utils';
import { IMG_ERROR_404 } from '../utils/imageUrls';

function Maintenance() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = createTitle('Under Maintenance');
  }, []);

  return (
    <section className="error_container div_not_found">
      <Typography className="error_message" align="center" variant="h5">
        The Application is under maintenance. Please try again later.
      </Typography>
      <img
        className="not_found_image"
        src={IMG_ERROR_404}
        alt="Under Maintenance"
      />
      <Box textAlign="center" marginTop="1rem">
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => navigate('/')}
        >
          Go to Home
        </Button>
      </Box>
    </section>
  );
}

export default Maintenance;
