import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Tooltip,
  Typography
} from '@mui/material';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  calculateMonthlyReturn,
  createTitle,
  enterOnlyNumbers,
  isArrayNotEmpty,
  isDisplayNetworthCertificate
} from '../../utils/utils';
import { INVESTOR_REQUIREMENT, SELECT_NEXT_BUTTON } from '../../utils/constant';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  commonInvestorDetailsAdd,
  commonInvestorDetailsUpdate,
  getListOfProducts,
  validateInvestorDetails
} from '../../redux/reducers/investorSlice';
import { useEffect, useMemo, useState } from 'react';
import NetworthConfirmationModal from '../../components/NetworthConfirmationModal';
import TimeLineComponent from '../../components/TimeLineComponent';
import InvestorRequirementGraph from '../dashboard/InvestorRequirementGraph';

const formControl = {
  margin: '0.5rem auto'
};
function InvestmentRequirement({ investorId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('Investment Requirement');
    dispatch(getListOfProducts());
  }, []);

  const [returnAmount, setReturnAmount] = useState(0);

  const [isOpenNetworthDialog, setIsOpenNetworthDialog] = useState(false);

  const { investorDetails, productList } = useSelector(state => state.investor);

  const investmentDetails = investorDetails?.investmentDetails
    ? investorDetails?.investmentDetails
    : [];

  const investmentDetailsId = Array.isArray(investmentDetails)
    ? investmentDetails[0]
    : {};

  const [productValues, setProductValues] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (isArrayNotEmpty(productList) && productList.length > 0) {
      const tenureList = productList.map(item => parseInt(item.others, 10));
      const minValue = Math.min(...tenureList);
      const maxValue = Math.max(...tenureList);
      setProductValues({ minValue, maxValue, tenureList });

      if (investmentDetailsId) {
        for (let key in productList) {
          if (
            Number(investmentDetailsId?.tenureInMonths) ===
            Number(productList[key].others)
          ) {
            setActiveStep(Number(key));
            break;
          }
        }
      } else {
        for (let key in productList) {
          if (minValue === Number(productList[key].others)) {
            formik.setFieldValue('productType', productList[key].key);
            setActiveStep(Number(key));
            break;
          }
        }
      }
    }
  }, [productList]);

  useEffect(() => {
    formik.setValues({
      userId: investorId || 0,
      productType: investmentDetailsId?.productType || '',
      tenureInMonths: investmentDetailsId?.tenureInMonths || 3,
      amount:
        investmentDetailsId?.amount ||
        Number(process.env.REACT_APP_MINIMUM_INVESTMENT_LIMIT)
    });
  }, [investmentDetailsId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: investorId || 0,
      productType: investmentDetailsId?.productType || '',
      tenureInMonths: investmentDetailsId?.tenureInMonths || 3,
      amount:
        investmentDetailsId?.amount ||
        Number(process.env.REACT_APP_MINIMUM_INVESTMENT_LIMIT)
    },
    validationSchema: yup.object({
      productType: yup
        .string()
        .required('Please choose the desired product type.'),
      tenureInMonths: yup
        .number()
        .required('Please provide the required tenure.')
        .positive('The tenure must be positive.')
        .moreThan(0, 'The tenure must be greater than 0 Month')
        .max(12, 'Maximum tenure should be 12 Months')
        .min(3, 'Minimum tenure should be 3 Months'),
      amount: yup
        .number()
        .required('Please enter the necessary investment amount.')
        .positive('The amount must be positive.')
        .moreThan(0, 'The amount must be greater than 0 rupees')
        .max(
          Number(process.env.REACT_APP_MAXIMUM_INVESTMENT_LIMIT),
          `Maximum amount limit is ${Number(
            process.env.REACT_APP_MAXIMUM_INVESTMENT_LIMIT
          )}.`
        )
        .min(
          Number(process.env.REACT_APP_MINIMUM_INVESTMENT_LIMIT),
          `Minimum amount limit is ${Number(
            process.env.REACT_APP_MINIMUM_INVESTMENT_LIMIT
          )}.`
        )
    }),
    onSubmit: async values => {
      try {
        const isShowNetworth = isDisplayNetworthCertificate(
          formik.values.amount,
          0
        );

        if (isShowNetworth) {
          setIsOpenNetworthDialog(true);
        } else {
          let investor = await validateInvestorDetails(investorId);
          const { investorDetails } = investor;
          if (!investorDetails) {
            const newvalues = {
              investorDetails: { ...values },
              action: INVESTOR_REQUIREMENT
            };
            dispatch(commonInvestorDetailsAdd(newvalues));
          } else {
            const newvalues = {
              investorDetails: { ...values, id: investorDetails?.id },
              action: INVESTOR_REQUIREMENT
            };
            dispatch(commonInvestorDetailsUpdate(newvalues));
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const calculatedAmount = useMemo(() => {
    return calculateMonthlyReturn(
      formik.values.amount,
      formik.values.tenureInMonths
    );
  }, [formik.values.amount, formik.values.tenureInMonths]);

  useEffect(() => {
    setReturnAmount(Number(calculatedAmount));
  }, [calculatedAmount]);

  const handleBackButton = () => {
    setIsOpenNetworthDialog(false);
  };

  const handleSubmitButton = async () => {
    try {
      const values = {
        userId: formik.values.userId,
        productType: formik.values.productType,
        tenureInMonths: formik.values.tenureInMonths,
        amount: formik.values.amount
      };

      let investor = await validateInvestorDetails(investorId);

      const { investorDetails } = investor;
      if (!investorDetails) {
        const newvalues = {
          investorDetails: { ...values },
          action: INVESTOR_REQUIREMENT
        };
        dispatch(commonInvestorDetailsAdd(newvalues));
      } else {
        const newvalues = {
          investorDetails: { ...values, id: investorDetails?.id },
          action: INVESTOR_REQUIREMENT
        };
        dispatch(commonInvestorDetailsUpdate(newvalues));
      }
      setIsOpenNetworthDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductChange = products => {
    formik.setFieldError('productType', '');
    formik.setFieldTouched('productType', false);
    formik.setFieldValue('productType', products?.key);
    formik.setFieldValue('tenureInMonths', Number(products?.others));

    for (let key in productList) {
      if (Number(products?.others) === Number(productList[key].others)) {
        setActiveStep(Number(key));
        break;
      }
    }
  };

  const handleStepClick = value => {
    for (let key in productList) {
      if (value === Number(productList[key].others)) {
        formik.setFieldError('productType', '');
        formik.setFieldTouched('productType', false);
        formik.setFieldValue('productType', productList[key].key);
        formik.setFieldValue('tenureInMonths', Number(productList[key].others));
        setActiveStep(Number(key));
        break;
      }
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormContent>
          <Typography
            marginBottom={'1rem'}
            align="center"
            variant="body2"
            fontWeight={700}
          >
            Investment Requirement
          </Typography>
          <FormControl sx={formControl} fullWidth size="small">
            <InputLabel id="select-product" required>
              Select Product
            </InputLabel>
            <Select
              labelId="select-product"
              id="select-product"
              label="Select Product"
              name="productType"
              value={formik.values.productType || ''}
              error={Boolean(
                formik.touched.productType && formik.errors.productType
              )}
              sx={{ textAlign: 'left' }}
            >
              {productList &&
                isArrayNotEmpty(productList) &&
                productList.map(products => {
                  return (
                    <MenuItem
                      onClick={() => handleProductChange(products)}
                      key={products?.key}
                      value={products?.key}
                    >
                      {products?.value}
                    </MenuItem>
                  );
                })}
            </Select>
            {Boolean(
              formik.touched.productType && formik.errors.productType
            ) && (
              <FormHelperText sx={{ textAlign: 'start' }}>
                {formik.errors.productType}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={formControl} fullWidth size="small">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography>Tenure</Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: 'inline-flex' }}>
                <label style={{ marginTop: '15px', marginRight: '8px' }}>
                  Months
                </label>
                <Input
                  value={formik.values.tenureInMonths}
                  error={Boolean(
                    formik.touched.tenureInMonths &&
                      formik.errors.tenureInMonths
                  )}
                  readOnly
                  size="small"
                  inputProps={{
                    type: 'number',
                    'aria-labelledby': 'tenureInMonths-slider'
                  }}
                />
              </Grid>
            </Grid>
            {isArrayNotEmpty(productValues.tenureList) && (
              <Box>
                <TimeLineComponent
                  steps={productValues?.tenureList}
                  activeStep={activeStep}
                  handleStepClick={handleStepClick}
                />
              </Box>
            )}

            {formik.errors.tenureInMonths && (
              <FormHelperText sx={{ textAlign: 'start' }}>
                {formik.errors.tenureInMonths}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={formControl} fullWidth size="small">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography>Invested Amount</Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: 'inline-flex' }}>
                <label
                  aria-labelledby="amount"
                  style={{ marginTop: '15px', marginRight: '8px' }}
                >
                  ₹
                </label>
                <Input
                  name="amount"
                  value={formik.values.amount}
                  size="small"
                  fullWidth
                  onChange={e =>
                    formik.setFieldValue(
                      'amount',
                      enterOnlyNumbers(e.target.value)
                    )
                  }
                  inputProps={{
                    type: 'number',
                    'aria-labelledby': 'invested-amount-slider'
                  }}
                />
              </Grid>
            </Grid>
            <Slider
              color="golden"
              value={formik.values.amount}
              name="amount"
              onChange={formik.handleChange}
              aria-labelledby="invested-amount-slider"
              step={Number(process.env.REACT_APP_MINIMUM_INVESTMENT_LIMIT)}
              marks
              min={Number(process.env.REACT_APP_MINIMUM_INVESTMENT_LIMIT)}
              max={Number(process.env.REACT_APP_MAXIMUM_INVESTMENT_LIMIT)}
            />
            {formik.touched.amount && formik.errors.amount && (
              <FormHelperText sx={{ textAlign: 'start' }}>
                {formik.errors.amount}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={formControl} fullWidth size="small">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography>You will earn</Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight={700}
                  style={{ marginRight: '8px', color: '#FEC310' }}
                >
                  ₹ {new Intl.NumberFormat('en-IN').format(returnAmount)}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
        </FormContent>
        <FormControl sx={formControl} fullWidth size="small">
          {Number(formik.values.amount) <=
            Number(process.env.REACT_APP_MAXIMUM_INVESTMENT_LIMIT) && (
            <InvestorRequirementGraph amount={formik.values.amount} />
          )}
        </FormControl>

        <Box>
          <FormButtonBottom>
            <Tooltip title={SELECT_NEXT_BUTTON} placement="top">
              <LoadingButton
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                size="small"
                type="submit"
              >
                Save & next
              </LoadingButton>
            </Tooltip>
          </FormButtonBottom>
        </Box>
      </form>

      {isOpenNetworthDialog && (
        <NetworthConfirmationModal
          key={'Networth'}
          handleBackButton={handleBackButton}
          handleSubmitButton={handleSubmitButton}
        />
      )}
    </>
  );
}

InvestmentRequirement.propTypes = {
  investorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InvestmentRequirement;
