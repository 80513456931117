import { useEffect, useRef, useState } from 'react';
import { Grid, Button, Box, Tooltip } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { SELECT_IMAGE_MESSAGE, imageAllowedTypes } from '../utils/constant';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBarBox from './SnackBarBox';
import { handleError, isImageExtensionAllowed } from '../utils/utils';
import ImageCropper from './ImageCropper';
import { colorLightYellow, colorPrimaryBlue } from '../config/theme';
import { IMG_IMAGE_PREVIEW } from '../utils/imageUrls';

const uploadButton = {
  backgroundColor: colorLightYellow,
  border: '1px dashed #F5B103',
  color: colorPrimaryBlue
};

const UploadImage = ({
  onImageUpload,
  buttonName = 'Upload Image',
  docName = ''
}) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (imageUploadError.error) {
      const timeoutId = setTimeout(() => {
        setImageUploadError({
          error: false,
          errorMessage: ''
        });
        handleDeleteImage();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [imageUploadError]);

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = imageAllowedTypes;
      const maxSizeMB = process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT_OCR;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            reader.onload = e => {
              setUploadedImage(e.target.result);
            };

            reader.readAsDataURL(file);
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: SELECT_IMAGE_MESSAGE
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: SELECT_IMAGE_MESSAGE
        });
      }
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileUpload = file => {
    const reader = new FileReader();
    reader.onload = e => {
      setUploadedImage(e.target.result);
    };
    reader.readAsDataURL(file);
    onImageUpload({ file: file, fileName: uploadedFileName, docName: docName });
  };

  return (
    <Grid item xs={12} md={12}>
      <div style={{ position: 'relative' }}>
        {uploadedImage ? (
          <>
            <ImageCropper
              key={docName}
              uploadedFileName={uploadedFileName}
              incomingFile={uploadedImage}
              handleFileUpload={handleFileUpload}
              handleFileCancel={handleDeleteImage}
            />
            <Box>
              <img
                src={uploadedImage}
                alt="Uploaded Preview"
                width={130}
                height={90}
              />
            </Box>
          </>
        ) : (
          <img width={130} src={IMG_IMAGE_PREVIEW} alt="Image Preview" />
        )}
      </div>
      {uploadedImage ? (
        <Tooltip title="Delete uploaded image">
          <Button
            color="error"
            size="small"
            variant="outlined"
            onClick={handleDeleteImage}
          >
            <DeleteIcon /> Delete
          </Button>
        </Tooltip>
      ) : (
        <>
          <Tooltip title={SELECT_IMAGE_MESSAGE} placement="top">
            <Button
              component="label"
              startIcon={<DriveFolderUploadIcon />}
              size="small"
              sx={uploadButton}
            >
              {buttonName}
              <input
                ref={fileInputRef}
                key={uploadedImage ? 'image-uploaded' : 'no-image'}
                type="file"
                style={{ display: 'none' }}
                accept={imageAllowedTypes.join(',')}
                onChange={handleImageUpload}
              />
            </Button>
          </Tooltip>
          {/* <Typography
            align="center"
            marginLeft="5px"
            variant="body2"
            sx={{ fontSize: '11px' }}
          >
            {OCR_IMAGE_SIZE_LIMIT_MESSAGE}
          </Typography> */}
        </>
      )}

      {imageUploadError.error && (
        <SnackBarBox
          type="error"
          message={handleError(imageUploadError.errorMessage)}
        />
      )}
    </Grid>
  );
};

UploadImage.propTypes = {
  onImageUpload: PropTypes.func,
  buttonName: PropTypes.string,
  docName: PropTypes.string
};

export default UploadImage;
