import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  NOMINEE_DETAILS,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTitle,
  enterOnlyAlphabates,
  enterOnlyNumbers,
  isAlphabet,
  isArrayNotEmpty,
  isValidMobileNumber
} from '../../utils/utils';
import {
  commonInvestorDetailsAdd,
  commonInvestorDetailsUpdate,
  handleInvesterBackStep,
  validateInvestorDetails
} from '../../redux/reducers/investorSlice';
import { useEffect } from 'react';
import { fetchRelationTypeList } from '../../redux/reducers/dropDownSlice';
import { colorGolden } from '../../config/theme';

const formControl = {
  margin: '0.5rem auto'
};
function NomineeDetails({ investorId }) {
  const { investorDetails, nomineeDetails } = useSelector(
    state => state.investor
  );
  const { relationTypeList } = useSelector(state => state.dropdown);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('Nominee Details');
    dispatch(fetchRelationTypeList());
  }, []);

  const handleSubmitWithValidation = async values => {
    try {
      let investor = await validateInvestorDetails(investorId);

      if (investor && investor.nomineeDetails) {
        const { id } = investor.nomineeDetails;

        if (id) {
          const newvalues = {
            nomineeDetails: {
              ...values,
              id: nomineeDetails?.id
            },
            investorDetails: {
              ...investorDetails
            },
            action: NOMINEE_DETAILS
          };
          dispatch(commonInvestorDetailsUpdate(newvalues));
        } else {
          const newvalues = {
            nomineeDetails: {
              ...values
            },
            investorDetails: {
              ...investorDetails
            },
            action: NOMINEE_DETAILS
          };
          dispatch(commonInvestorDetailsAdd(newvalues));
        }
      } else {
        const newvalues = {
          nomineeDetails: {
            ...values
          },
          investorDetails: {
            ...investorDetails
          },
          action: NOMINEE_DETAILS
        };
        dispatch(commonInvestorDetailsAdd(newvalues));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: 0,
      nomineeName: '',
      age: '',
      nomineeRelationship: '',
      mobileNo: ''
    },
    validationSchema: yup.object({
      nomineeName: yup
        .string()
        .test(
          'is-alphabet',
          'Nominee Name should consist of alphabetic characters only.',
          value => isAlphabet(value)
        ),
      age: yup
        .number()
        .typeError('Age must be a number')
        .min(18, 'Nominee must be at least 18 years old')
        .max(100, 'Nominee must not be more than 100 years old'),
      nomineeRelationship: yup.string(),
      mobileNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Mobile number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
    }),
    onSubmit: values => {
      handleSubmitWithValidation(values);
    }
  });

  useEffect(() => {
    formik.setValues({
      id: investorId || 0,
      nomineeName: nomineeDetails?.nomineeName || '',
      age: nomineeDetails?.age || '',
      nomineeRelationship: nomineeDetails?.nomineeRelationship || '',
      mobileNo: nomineeDetails?.mobileNo || ''
    });
  }, [nomineeDetails]);

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <FormContent>
        <Typography
          marginBottom={'1rem'}
          align="center"
          variant="body2"
          fontWeight={700}
        >
          Nominee Details
        </Typography>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            size="small"
            label="Nominee Name"
            inputProps={{ maxLength: 60 }}
            name="nomineeName"
            value={formik.values.nomineeName}
            onChange={e =>
              formik.setFieldValue(
                'nomineeName',
                enterOnlyAlphabates(e.target.value)
              )
            }
            error={Boolean(
              formik.touched.nomineeName && formik.errors.nomineeName
            )}
            helperText={formik.touched.nomineeName && formik.errors.nomineeName}
          />
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            size="small"
            label="Nominee Age"
            inputProps={{ maxLength: 3 }}
            name="age"
            value={formik.values.age}
            onChange={e =>
              formik.setFieldValue('age', enterOnlyNumbers(e.target.value))
            }
            error={Boolean(formik.touched.age && formik.errors.age)}
            helperText={formik.touched.age && formik.errors.age}
          />
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <InputLabel id="select-relationship">Nominee Relationship</InputLabel>
          <Select
            labelId="select-relationship"
            id="select-relationship"
            label="Nominee Relationship"
            value={formik.values.nomineeRelationship}
            onChange={formik.handleChange}
            name="nomineeRelationship"
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {isArrayNotEmpty(relationTypeList) &&
              relationTypeList.map(relation => {
                return (
                  <MenuItem key={relation?.key} value={relation?.key}>
                    <em>{relation?.value}</em>
                  </MenuItem>
                );
              })}
          </Select>
          {formik.touched.nomineeRelationship &&
            formik.errors.nomineeRelationship && (
              <FormHelperText sx={{ textAlign: 'start' }}>
                {formik.errors.nomineeRelationship}
              </FormHelperText>
            )}
        </FormControl>

        <Box display="flex" alignItems="center" marginBottom="1rem">
          <ErrorIcon sx={{ fontSize: 'large', color: colorGolden }} />
          <Typography marginLeft="5px" variant="body2">
            If non blood relatives, physical KYC needed.
          </Typography>
        </Box>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            size="small"
            type="tel"
            label="Nominee Mobile No"
            inputProps={{ maxLength: 10 }}
            name="mobileNo"
            value={formik.values.mobileNo}
            onChange={e =>
              formik.setFieldValue('mobileNo', enterOnlyNumbers(e.target.value))
            }
            error={Boolean(formik.touched.mobileNo && formik.errors.mobileNo)}
            helperText={formik.touched.mobileNo && formik.errors.mobileNo}
          />
        </FormControl>

        <FormControl fullWidth size="small"></FormControl>
      </FormContent>

      <Box>
        <FormButtonBottom>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleInvesterBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </Box>
    </form>
  );
}
NomineeDetails.propTypes = {
  investorId: PropTypes.object
};
export default NomineeDetails;
