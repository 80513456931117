import { Skeleton } from '@mui/material';

function SkeletonBars() {
  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        <Skeleton variant="rectangular" height={100} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="rounded" height={60} />
      </div>
    </>
  );
}

export default SkeletonBars;
