import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutTitle } from '../redux/reducers/layoutSlice';
import {
  createTitle,
  formatDateTimeStamp,
  formatINR,
  getLoanStatus,
  handleError,
  isEmptyString,
  isNumber,
  isValidBureauResult
} from '../utils/utils';
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Box,
  Container,
  TablePagination as Pagination,
  Icon,
  Button
} from '@mui/material';
import Searchbar from '../components/Searchbar';
import AccordionComponent from '../components/AccordionComponent';
import { FormContent } from '../utils/styledLayout';
import PropTypes from 'prop-types';
import { colorGolden, colorMidGray } from '../config/theme';
import {
  clearOtpVerificationState,
  getLeadByRM,
  getLeadByRMBorrower,
  getProspectByRM,
  getProspectByRMBorrower,
  sendOtpForLeadCreation
} from '../redux/reducers/leadManagementSlice';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SkeletonBars from '../components/SkeletonBars';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { manuallyUpdateAddLeadDetails } from '../redux/reducers/addLeadSlice';
import Loader from '../components/Loader';
import { SENDING_OTP } from '../utils/messages';
import SnackBarBox from '../components/SnackBarBox';
import { setInvestorId } from '../redux/reducers/investorSlice';
import {
  clearBureauDetails,
  clearLoanDetails,
  fetchBureauResult,
  fetchLoanStatus,
  setBorrowerId
} from '../redux/reducers/borrowerSlice';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1, display: 'flex' }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const LeadManagement = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const INVESTOR_LAYOUT_TITLE = 'Investor Lead Management';
  const BORROWER_LAYOUT_TITLE = 'Borrower Lead Management';

  const [searchQuery, setSearchQuery] = useState('');
  const [leadsData, setLeadsData] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState({});
  const [pageData, setPageData] = useState({ page: 0, size: 10 });

  const { authData } = useSelector(state => state.auth);
  const {
    isLoadingLeads,
    leadsContainer,
    isLoadingProspect,
    isSendLeadCreationOtpLoading,
    isSendLeadCreationOtpSuccess,
    isSendLeadCreationOtpFailed,
    sendLeadCreationOtpErrorContainer
  } = useSelector(state => state.leadManagement);

  useEffect(() => {
    document.title = createTitle(
      `${
        props.type === 'investor'
          ? INVESTOR_LAYOUT_TITLE
          : BORROWER_LAYOUT_TITLE
      }`
    );

    dispatch(
      setLayoutTitle({
        title: `Lead Management ${
          props.type === 'investor' ? '| Investor' : '| Borrower'
        }`,
        backwardLinkText: 'Go to Homepage',
        backwardLink: '/homepage'
      })
    );

    return () => dispatch(clearOtpVerificationState());
  }, []);

  useEffect(() => {
    initialFetch();
  }, [pageData]);

  const initialFetch = () => {
    if (props.type === 'investor') {
      dispatch(
        tabValue == 0
          ? getLeadByRM({
              rmId: authData.userId,
              ...pageData
            })
          : getProspectByRM({
              rmId: authData.userId,
              ...pageData
            })
      );
    } else {
      dispatch(
        tabValue == 0
          ? getLeadByRMBorrower({
              rmId: authData.userId,
              ...pageData
            })
          : getProspectByRMBorrower({ rmId: authData.userId, ...pageData })
      );
    }
  };

  useEffect(() => {
    if (props.type === 'investor') {
      if (leadsContainer) {
        setLeadsData(leadsContainer.content);
      } else {
        setLeadsData([]);
      }
    } else {
      if (leadsContainer) {
        setLeadsData([...leadsContainer.content]);
      } else {
        setLeadsData([]);
      }
    }
  }, [leadsContainer]);

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpanded({ [panel]: isExpanded });
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setPageData({ page: 0, size: 10 });
    setSearchQuery('');
    setExpanded({});
  };

  const handleChangeRowsPerPage = event => {
    setPageData({
      ...pageData,
      page: 0,
      size: parseInt(event.target.value, 10)
    });
  };

  const leadsLayout = values => {
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={5}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              {props.type === 'investor' ? 'Investor' : 'Borrower'} ID
            </Typography>
            <Typography
              fontSize={14}
              textAlign={'start'}
              fontWeight={500}
              marginTop={0.5}
            >
              {values?.id}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              {props.type === 'investor' ? 'Investor' : 'Borrower'} Name
            </Typography>
            <Typography
              fontSize={14}
              textAlign={'start'}
              fontWeight={500}
              marginTop={0.5}
            >
              {values?.fullname}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  function handleAddEditLead(mode) {
    if (mode === 'add') {
      navigate(`/add-lead-${props.type}`);
    }
  }

  const handleEditLeadCreation = item => {
    dispatch(sendOtpForLeadCreation({ userId: item?.id }));
    const newDetails = {
      id: item?.id,
      fullname: item?.fullname,
      mobileNo: item?.mobile_no,
      email: item?.email
    };
    dispatch(manuallyUpdateAddLeadDetails(newDetails));
  };

  useEffect(() => {
    if (!isSendLeadCreationOtpLoading && isSendLeadCreationOtpSuccess) {
      navigate(`/edit-lead-otp-${props.type}`);
      return;
    }
  }, [isSendLeadCreationOtpSuccess]);

  const handleUpdateInvestorDetails = item => {
    dispatch(setInvestorId(item?.id));
    navigate(`/add-investor`);
    return;
  };

  const handleUpdateBorrowerDetails = item => {
    dispatch(setBorrowerId(item?.id));
    navigate(`/add-borrower`);
    return;
  };

  const InvestorDataComponent = item => {
    return (
      <>
        <Grid container>
          <Grid item xs={5}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              KYC Verification
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item.isKycVerified ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Bank Account Verification
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item.bankAccountVerified ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Lendbox Status
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item.isCurrentStageLendBoxRegistration ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Payment Amount
            </Typography>
            <Typography
              fontSize={14}
              textAlign={'start'}
              marginTop={0.5}
              fontWeight={500}
              sx={{ display: 'flex' }}
            >
              ₹{' '}
              {isNaN(item?.amount)
                ? new Intl.NumberFormat('en-IN').format(0)
                : new Intl.NumberFormat('en-IN').format(item?.amount)}
              {item.isWalletOrderCompleted ? (
                <Icon
                  sx={{ marginLeft: '10px' }}
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon
                  sx={{ marginLeft: '10px' }}
                  component={ErrorIcon}
                  fontSize="medium"
                  color="warning"
                />
              )}
            </Typography>
          </Grid>
          {!item.isCurrentStageLendBoxRegistration && (
            <Grid item xs={5} sx={{ marginTop: '1rem' }}>
              <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
                Action
              </Typography>
              <Typography
                marginTop={0.5}
                fontSize={14}
                style={{
                  color: colorGolden,
                  cursor: 'pointer'
                }}
                fontWeight={'500'}
                textAlign={'start'}
                onClick={() => handleUpdateInvestorDetails(item)}
              >
                Update Details
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Date/Time
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              textAlign={'start'}
              marginTop={0.5}
            >
              {item.updateDate
                ? formatDateTimeStamp(item.updateDate)
                : '-' || '-'}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const BorrorwerDataComponent = item => {
    const dispatch = useDispatch();

    const { loanStatusDetails, bureauResult } = useSelector(
      state => state.borrowers
    );

    useEffect(() => {
      if (item?.lbLoanId && item?.lbUserId && item?.borrowingDetailId) {
        dispatch(
          fetchLoanStatus({ borrowingDetailsId: item?.borrowingDetailId })
        );
      }

      if (item?.lbUserId) {
        dispatch(fetchBureauResult({ lbUserId: item?.lbUserId }));
      }
      return () => {
        dispatch(clearLoanDetails());
        dispatch(clearBureauDetails());
      };
    }, [item]);

    return (
      <>
        <Grid container>
          <Grid item xs={5}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              KYC Verification
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item?.isKycVerified ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Bank Account Verification
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item?.bankAccountVerified ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Bureau Result
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {isValidBureauResult(bureauResult) ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Loan Status
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={14}
              textAlign={'start'}
              marginTop={0.5}
            >
              {getLoanStatus(loanStatusDetails)}
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Final Amount
            </Typography>
            <Typography
              fontSize={14}
              textAlign={'start'}
              marginTop={0.5}
              fontWeight={500}
              sx={{ display: 'flex' }}
            >
              {item?.isWalletOrderCompleted ? formatINR(item?.amount) : '-'}
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              KFS Status
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item.kfsStatus ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Esign Status
            </Typography>
            <Typography fontSize={14} textAlign={'start'} marginTop={0.5}>
              {item.esignStatus ? (
                <Icon
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon component={ErrorIcon} fontSize="medium" color="warning" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ marginTop: '1rem' }}>
            <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
              Date/Time
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              textAlign={'start'}
              marginTop={0.5}
            >
              {item.updateDate
                ? formatDateTimeStamp(item.updateDate)
                : '-' || '-'}
            </Typography>
          </Grid>

          {!item.isCurrentStageLendBoxRegistration && (
            <Grid item xs={5} sx={{ marginTop: '1rem' }}>
              <Typography fontSize={14} textAlign={'start'} fontWeight={'600'}>
                Action
              </Typography>
              <Typography
                marginTop={0.5}
                fontSize={14}
                style={{
                  color: colorGolden,
                  cursor: 'pointer'
                }}
                fontWeight={'500'}
                textAlign={'start'}
                onClick={() => handleUpdateBorrowerDetails(item)}
              >
                Update Details
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Container className="container_css">
        <FormContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Searchbar
                value={searchQuery}
                onChange={e => {
                  let tempSearchQuery = !isEmptyString(e.target.value)
                    ? isNumber(e.target.value)
                      ? { id: e.target.value }
                      : { fullname: e.target.value }
                    : null;
                  if (debounceTimeout) {
                    clearTimeout(debounceTimeout);
                  }
                  const newTimeout = setTimeout(async () => {
                    if (props.type === 'investor') {
                      dispatch(
                        tabValue == 0
                          ? getLeadByRM({
                              rmId: authData.userId,
                              ...pageData,
                              ...tempSearchQuery,
                              page: 0
                            })
                          : getProspectByRM({
                              rmId: authData.userId,
                              ...pageData,
                              ...tempSearchQuery,
                              page: 0
                            })
                      );
                    } else if (props.type === 'borrower') {
                      dispatch(
                        tabValue == 0
                          ? getLeadByRMBorrower({
                              rmId: authData.userId,
                              ...pageData,
                              ...tempSearchQuery,
                              page: 0
                            })
                          : getProspectByRMBorrower({
                              rmId: authData.userId,
                              ...pageData,
                              ...tempSearchQuery,
                              page: 0
                            })
                      );
                    }
                  }, 1000);
                  setDebounceTimeout(newTimeout);
                  setSearchQuery(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                size="medium"
                style={{ height: 50, width: '100%' }}
                variant="contained"
                onClick={() => {
                  handleAddEditLead('add');
                }}
              >
                <AddCircleOutlineIcon sx={{ marginRight: '8px' }} />
                Add Lead
              </Button>
            </Grid>
          </Grid>
          <Box paddingTop={2}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleChange}
              aria-label="leads-management-tab"
              TabIndicatorProps={{
                style: {
                  backgroundColor: colorGolden
                }
              }}
            >
              <Tab
                style={{
                  fontWeight: 'bolder',
                  color: tabValue == 0 ? colorGolden : colorMidGray
                }}
                label="Lead "
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  fontWeight: 'bolder',
                  color: tabValue == 1 ? colorGolden : colorMidGray
                }}
                label="Prospect"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          {isLoadingLeads || isLoadingProspect ? (
            <SkeletonBars text="Loading lead management ...." />
          ) : (
            <>
              <Grid container padding={0}>
                <Grid item style={{ width: '100%' }}>
                  <Box>
                    <CustomTabPanel value={tabValue} index={0}>
                      <Grid
                        container
                        justifyContent="space-between"
                        marginTop={2}
                      >
                        <Grid item xs={12}>
                          {leadsData.length > 0 ? (
                            leadsData.map((item, idx) => {
                              return (
                                <AccordionComponent
                                  titleStyleProp={{ width: '100%' }}
                                  key={idx}
                                  title={leadsLayout(item)}
                                  onChange={handleAccordionChange(
                                    `item-${idx}`
                                  )}
                                  expanded={Boolean(expanded[`item-${idx}`])}
                                >
                                  {expanded[`item-${idx}`] && (
                                    <>
                                      <Typography
                                        fontSize={14}
                                        textAlign={'start'}
                                        fontWeight={'600'}
                                      >
                                        Action
                                      </Typography>
                                      <Typography
                                        fontSize={14}
                                        style={{
                                          color: colorGolden,
                                          cursor: 'pointer'
                                        }}
                                        fontWeight={'500'}
                                        textAlign={'start'}
                                        onClick={() =>
                                          handleEditLeadCreation(item)
                                        }
                                      >
                                        Edit
                                      </Typography>
                                    </>
                                  )}
                                </AccordionComponent>
                              );
                            })
                          ) : (
                            <>No leads Found</>
                          )}
                        </Grid>
                      </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={tabValue}
                      index={1}
                      sx={{ marginTop: '1rem' }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        marginTop={2}
                      >
                        <Grid item xs={12}>
                          {leadsData.length > 0 ? (
                            leadsData.map((item, idx) => {
                              return (
                                <AccordionComponent
                                  titleStyleProp={{ width: '100%' }}
                                  key={idx}
                                  title={leadsLayout(item)}
                                  onChange={handleAccordionChange(
                                    `item-${idx}`
                                  )}
                                  expanded={Boolean(expanded[`item-${idx}`])}
                                >
                                  {expanded[`item-${idx}`] &&
                                    (props.type === 'investor' ? (
                                      <InvestorDataComponent {...item} />
                                    ) : (
                                      <BorrorwerDataComponent {...item} />
                                    ))}
                                </AccordionComponent>
                              );
                            })
                          ) : (
                            <>No Prospect Found</>
                          )}
                        </Grid>
                      </Grid>
                    </CustomTabPanel>
                  </Box>
                </Grid>
              </Grid>

              {Array.isArray(leadsData) && leadsData.length > 0 && (
                <Pagination
                  padding="0"
                  style={{ display: 'flex', marginTop: '2rem' }}
                  count={leadsContainer?.totalElements}
                  page={pageData?.page}
                  rowsPerPage={pageData?.size}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={(_, newPage) => {
                    setPageData({ ...pageData, page: newPage });
                  }}
                />
              )}
            </>
          )}
        </FormContent>
        {isSendLeadCreationOtpLoading && <Loader message={SENDING_OTP} />}

        {!isSendLeadCreationOtpLoading && isSendLeadCreationOtpFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              sendLeadCreationOtpErrorContainer?.errorMessage
            )}
          />
        )}
      </Container>
    </>
  );
};

export default LeadManagement;
