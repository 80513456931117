import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTitle,
  enterOnlyAlphabates,
  enterOnlyEmail,
  enterOnlyNumbers,
  handleError,
  isAlphabet,
  isValidEmail,
  isValidMobileNumber
} from '../../utils/utils';
import { setLayoutTitle } from '../../redux/reducers/layoutSlice';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import { FormControl, Grid, TextField, Tooltip } from '@mui/material';
import { Container } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  CANCEL_BUTTON_MESSAGE,
  CREATING_LEAD,
  LEAD_ADDED_SUCCESS,
  NEXT_BUTTON_MESSAGE
} from '../../utils/messages';
import { useNavigate } from 'react-router';
import {
  addLeadDetails,
  clearAddLeadDetailsState
} from '../../redux/reducers/addLeadSlice';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import { ADD_BASIC_DETAILS } from '../../utils/constant';
import { clearOtpVerificationState } from '../../redux/reducers/leadManagementSlice';

const formControl = {
  margin: '0.8rem auto'
};

function AddLead(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { authData } = useSelector(state => state.auth);

  const {
    isAddLeadDataLoading,
    isAddLeadDataSuccess,
    isAddLeadDataFailed,
    leadDetailErrorContainer
  } = useSelector(state => state.addlead);

  useEffect(() => {
    // dispatch(resetAddLeadDetails()); // To reset form data
    document.title = createTitle('Add Lead');
    dispatch(
      setLayoutTitle({
        title: 'Add Lead',
        backwardLinkText: 'Go to Lead Management',
        backwardLink: `/lead-management-${props.type}`
      })
    );
    return () => {
      dispatch(clearAddLeadDetailsState());
      dispatch(clearOtpVerificationState());
    };
  }, []);

  useEffect(() => {
    if (!isAddLeadDataLoading && isAddLeadDataSuccess) {
      navigate(`/add-lead-otp-${props.type}`);
      return;
    }
  }, [isAddLeadDataSuccess]);

  // useEffect(() => {
  //   formik.setValues({
  //     rmId: authData?.userId,
  //     fullname: addLeadData?.fullname || '',
  //     mobileNo: addLeadData?.mobileNo || '',
  //     email: addLeadData?.email || ''
  //   });
  // }, [addLeadData]);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      rmId: authData?.userId,
      fullname: '',
      mobileNo: '',
      email: ''
    },
    validationSchema: yup.object({
      fullname: yup
        .string()
        .test(
          'is-alphabet',
          'Full Name should consist of alphabetic characters only.',
          value => isAlphabet(value)
        )
        .required('Full Name is required'),
      mobileNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Mobile number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .required('Mobile Number is required'),
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email ID is required')
    }),
    onSubmit: values => {
      let leadType =
        props.type === 'investor' ? { isInvestor: true } : { isBorrower: true };
      const newValues = { ...values, action: ADD_BASIC_DETAILS, ...leadType };
      dispatch(addLeadDetails(newValues));
    }
  });

  const handleOnlyAlphabtes = (e, field) => {
    formik.setFieldValue(field, enterOnlyAlphabates(e.target.value));
  };

  const handleOnlyNumber = (e, field) => {
    formik.setFieldValue(field, enterOnlyNumbers(e.target.value));
  };

  const handleOnlyEmail = (e, field) => {
    const value = e.target.value
      ? String(e.target.value).toLowerCase()
      : e.target.value;
    formik.setFieldValue(field, enterOnlyEmail(value));
  };

  return (
    <>
      <FormContent>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Container>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                label="Full Name (As Per PAN)"
                required
                inputProps={{ maxLength: 50 }}
                value={formik.values.fullname}
                onChange={e => handleOnlyAlphabtes(e, 'fullname')}
                error={Boolean(
                  formik.touched.fullname && formik.errors.fullname
                )}
                helperText={formik.touched.fullname && formik.errors.fullname}
              />
            </FormControl>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                type="tel"
                label="Mobile Number"
                required
                inputProps={{ maxLength: 10 }}
                value={formik.values.mobileNo}
                onChange={e => handleOnlyNumber(e, 'mobileNo')}
                error={Boolean(
                  formik.touched.mobileNo && formik.errors.mobileNo
                )}
                helperText={formik.touched.mobileNo && formik.errors.mobileNo}
              />
            </FormControl>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                type="email"
                label="Email ID"
                required
                inputProps={{ maxLength: 64 }}
                value={formik.values.email}
                onChange={e => handleOnlyEmail(e, 'email')}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>

            <FormButtonBottom>
              <Grid container spacing={2} marginTop={1}>
                <Grid item xs={6}>
                  <Tooltip title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      fullWidth
                      style={{ textTransform: 'uppercase' }}
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => navigate(`/lead-management-${props.type}`)}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title={NEXT_BUTTON_MESSAGE}>
                    <LoadingButton
                      fullWidth
                      style={{ textTransform: 'uppercase' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save & Next
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </FormButtonBottom>
          </Container>
        </form>
      </FormContent>
      {isAddLeadDataLoading && <Loader text={CREATING_LEAD} />}
      {!isAddLeadDataLoading && isAddLeadDataFailed && (
        <SnackBarBox
          type="error"
          message={handleError(leadDetailErrorContainer?.errorMessage)}
        />
      )}
      ,
      {!isAddLeadDataLoading && isAddLeadDataSuccess && (
        <SnackBarBox type="success" message={LEAD_ADDED_SUCCESS} />
      )}
    </>
  );
}

export default AddLead;
