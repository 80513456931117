import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import SnackBarBox from './SnackBarBox';
import { viewImage } from '../redux/reducers/documentSlice';
import { handleError, isCaptureImageExtensionAllowed } from '../utils/utils';
import {
  CAPTURE_IMAGE,
  CaptureImageAllowedTypes,
  SELECT_IMAGE_MESSAGE,
  imageAllowedTypes
} from '../utils/constant';
import Loader from './Loader';
import { useSelector } from 'react-redux';
import { colorLightYellow, colorPrimaryBlue } from '../config/theme';
import ImageCropper from './ImageCropper';

const capptureButton = {
  backgroundColor: colorLightYellow,
  border: '1px dashed #F5B103',
  color: colorPrimaryBlue,
  padding: '5px',
  width: '100%'
};
const uploadButton = {
  backgroundColor: colorLightYellow,
  border: '1px dashed #F5B103',
  color: colorPrimaryBlue,
  padding: '5px',
  width: '100%'
};

const PhotoCaptureForm = ({ onImageUpload, docType = null, handleDelete }) => {
  const [cameraStream, setCameraStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isCameraSupported, setIsCameraSupported] = useState(
    Boolean(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
  );

  const [actualImage, setActualImage] = useState(null);
  const [isLoadingSavedImage, setIsLoadingSavedImage] = useState(false);

  const { documentDetails } = useSelector(state => state.documents);

  const [currentUploadedFile, setCurrentUploadedFile] = useState(null);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');

  useEffect(() => {
    const getImage = async () => {
      setIsLoadingSavedImage(true);
      const uploadedLink = documentDetails[docType];

      if (uploadedLink) {
        setCurrentUploadedFile(uploadedLink);
        const img = await viewImage(uploadedLink?.s3ObjectKey);
        setActualImage(img);
      } else {
        handleDeleteImage();
      }
      setIsLoadingSavedImage(false);
    };
    getImage();
  }, [documentDetails, docType]);

  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (imageUploadError.error) {
      const timeoutId = setTimeout(() => {
        setImageUploadError({
          error: false,
          errorMessage: ''
        });
        handleDeleteImage();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [imageUploadError]);

  useEffect(() => {
    startCamera();
    return () => {
      stopCamera();
    };
  }, []);

  const startCamera = async () => {
    try {
      if (isCameraSupported) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' }
        });
        setCameraStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      }
    } catch (error) {
      if (error && String(error).includes('Permission denied')) {
        setIsCameraSupported(false);
      }
      console.warn('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach(track => {
        track.stop();
      });
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setCameraStream(null);
    }
  };

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const width = video.videoWidth;
      const height = video.videoHeight;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(video, 0, 0, width, height);
      canvas.toBlob(
        blob => {
          if (blob) {
            blob.arrayBuffer().then(buffer => {
              const file = new File([buffer], 'selfie.jpg', {
                type: 'image/jpeg'
              });
              onImageUpload({ file, docType });
            });
          }
        },
        'image/jpeg',
        0.9
      );
    }
    stopCamera();
  };

  const deleteActualImage = () => {
    handleDelete(currentUploadedFile?.fileName, docType);
    setActualImage(null);
    setUploadedImage(null);
    startCamera();
  };

  const handleDeleteImage = () => {
    setCapturedImage(null);
    setUploadedImage(null);
    setActualImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleUploadedImage = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = CaptureImageAllowedTypes;
      const maxSizeMB = process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isCaptureImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            reader.onload = e => {
              setUploadedImage(e.target.result);
            };
            reader.readAsDataURL(file);
            stopCamera();
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
            handleDeleteImage();
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: SELECT_IMAGE_MESSAGE
          });
          handleDeleteImage();
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: SELECT_IMAGE_MESSAGE
        });
        handleDeleteImage();
      }
    }
  };

  const handleFileUpload = file => {
    onImageUpload({ file: file, docType: docType });
    setUploadedImage(null);
    stopCamera();
  };

  const handleClearImage = () => {
    setUploadedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    startCamera();
  };

  return (
    <>
      {isLoadingSavedImage ? (
        <Loader />
      ) : (
        <div>
          <Card
            variant="outlined"
            sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto' }}
          >
            <CardContent>
              <div>
                {uploadedImage && (
                  <ImageCropper
                    key={docType}
                    uploadedFileName={uploadedFileName}
                    incomingFile={uploadedImage}
                    handleFileUpload={handleFileUpload}
                    handleFileCancel={handleClearImage}
                  />
                )}
                {!capturedImage && !actualImage && (
                  <>
                    {!cameraStream && (
                      <Typography align="center" variant="subtitle2">
                        Starting camera..!
                      </Typography>
                    )}
                    <video
                      ref={videoRef}
                      autoPlay
                      playsInline
                      muted
                      style={{
                        display: cameraStream ? 'block' : 'none',
                        width: '100%',
                        maxWidth: '350px',
                        height: 'auto',
                        borderRadius: '10px'
                      }}
                      onClick={capturePhoto}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      marginTop={3}
                    >
                      <Grid>
                        <Tooltip title={CAPTURE_IMAGE} placement="top">
                          <Button
                            fullWidth
                            startIcon={<CameraAltIcon />}
                            size="small"
                            sx={capptureButton}
                            onClick={capturePhoto}
                          >
                            Capture Photo
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid>
                        <Tooltip title={SELECT_IMAGE_MESSAGE} placement="top">
                          <Button
                            fullWidth
                            component="label"
                            startIcon={<DriveFolderUploadIcon />}
                            size="small"
                            sx={{ ...uploadButton }}
                          >
                            Upload Photo
                            <input
                              ref={fileInputRef}
                              type="file"
                              style={{ display: 'none' }}
                              accept={imageAllowedTypes.join(',')}
                              onChange={handleUploadedImage}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </>
                )}

                {actualImage && (
                  <>
                    <Box>
                      <img
                        style={{
                          borderRadius: '10px',
                          margin: '10px',
                          width: '70%'
                        }}
                        src={actualImage}
                        alt="actualImage"
                      />
                    </Box>
                    <Tooltip title="Delete uploaded image">
                      <Button
                        color="error"
                        size="small"
                        onClick={deleteActualImage}
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </>
                )}
                <canvas ref={canvasRef} style={{ display: 'none' }} />
              </div>
            </CardContent>
          </Card>
          {imageUploadError.error && (
            <SnackBarBox
              type="error"
              message={handleError(imageUploadError.errorMessage)}
            />
          )}
        </div>
      )}
    </>
  );
};

PhotoCaptureForm.propTypes = {
  onImageUpload: PropTypes.func,
  docType: PropTypes.string,
  handleDelete: PropTypes.func
};

export default PhotoCaptureForm;
