import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderRadius: 1,
    borderStyle: 'dotted',
    borderColor: 'gray',
    borderTop: 'none'
  }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  width: '100%',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#FEC310'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#FEC310',
    zIndex: 1,
    fontSize: 20
  },
  '& .QontoStepIcon-circle': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    border: '1px solid red'
  }
}));

function QontoStepIcon(props) {
  const { active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <div className="QontoStepIcon-circle" style={{ cursor: 'pointer' }} />
    </QontoStepIconRoot>
  );
}

export default function TimeLineComponent({
  steps,
  activeStep = 0,
  handleStepClick
}) {
  return (
    <Stepper
      sx={{ marginTop: 2, width: '100%' }}
      alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      {steps.map(label => (
        <Step key={label} onClick={() => handleStepClick(label)}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
