import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import {
  Box,
  FormControl,
  Grid,
  Tooltip,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
  Skeleton
} from '@mui/material';
import {
  DOC_TYPE_CHEQUE_OCR,
  PENNY_DROP_VERIFICATION,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON,
  USER_TYPE_INVESTOR
} from '../../utils/constant';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import {
  createTitle,
  enterOnlyAlphabates,
  enterOnlyAlphabetsAndNumbers,
  enterOnlyNumbers,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import SnackBarBox from '../../components/SnackBarBox';
import {
  clearPennyDropVerificationState,
  commonInvestorDetailsAdd,
  commonInvestorDetailsUpdate,
  fetchBankDetails,
  getNameMatchDetails,
  handleInvesterBackStep,
  resetPreDataOfOcr,
  validateInvestorDetails,
  verifyChequeOcr
} from '../../redux/reducers/investorSlice';
import ErrorIcon from '@mui/icons-material/Error';
import { fetchAccountTypeList } from '../../redux/reducers/dropDownSlice';
import Loader from '../../components/Loader';
import UploadImageCheque from '../../components/UploadImageCheque';
import { investorDocumentsList } from '../../redux/reducers/documentSlice';
import { colorGolden, colorGrayish, colorSuccess } from '../../config/theme';

const formControl = {
  margin: '0.5rem auto'
};

const lableStyle = {
  color: colorGrayish,
  fontSize: '0.8em'
};
const cardStyle = {
  backgroundColor: '#F9F9F9',
  borderRadius: '10px',
  marginTop: '1rem'
};
function PennyDropVerification({ investorId }) {
  const dispatch = useDispatch();

  const [isBankDetailsRegistred, setIsBankDetailsRegistred] = useState(false);

  const {
    investorDetails,
    bankDetails,
    isFetchBankDetailsLoading,
    isFetchBankDetailsSuccess,
    isFetchBankDetailsFailed,
    fetchBankDetailsErrorContainer,
    isNameMatchedLoading,
    isNameMatchedSuccess,
    isNameMatchedFailed,
    NameMatchedErrorContainer,
    isNameMatched,
    isChequeVerificationByOcrLoading,
    isChequeVerificationByOcrSuccess,
    isChequeVerificationByOcrFailed,
    chequeVerificationErrorContainer
  } = useSelector(state => state.investor);

  const { accountTypeList } = useSelector(state => state.dropdown);

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    document.title = createTitle('Bank Account Verification');
    dispatch(fetchAccountTypeList());
    dispatch(investorDocumentsList({ userId: investorId }));
    return () => dispatch(clearPennyDropVerificationState());
  }, []);

  const handleSubmitWithValidation = async values => {
    try {
      let investor = await validateInvestorDetails(investorId);
      if (investor && investor.bankDetails) {
        const {
          accountHolderName,
          accountType,
          accountNo,
          confirmAccountNo,
          ifsc
        } = investor.bankDetails;
        if (
          accountHolderName &&
          accountType &&
          accountNo &&
          confirmAccountNo &&
          ifsc
        ) {
          // dispatch(handleInvesterNextStep());
          const newvalues = {
            bankDetails: {
              ...values,
              id: investor.bankDetails?.id
            },
            investorDetails: {
              ...investorDetails
            },
            action: PENNY_DROP_VERIFICATION
          };
          if (!isFetchBankDetailsFailed) {
            dispatch(commonInvestorDetailsUpdate(newvalues));
          }
        }
      } else {
        const newvalues = {
          bankDetails: {
            ...values
          },
          investorDetails: {
            ...investorDetails
          },
          action: PENNY_DROP_VERIFICATION
        };

        if (isNameMatched && isNameMatched === true) {
          dispatch(commonInvestorDetailsAdd(newvalues));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: 0,
      userId: 0,
      accountHolderName: '',
      accountType: '',
      accountNo: '',
      confirmAccountNo: '',
      ifsc: '',
      branch: '',
      bank: '',
      address: ''
    },
    validationSchema: yup.object({
      accountHolderName: yup
        .string()
        .required('Account holder name is required'),
      accountType: yup.string().required('Account type is required'),
      accountNo: yup
        .string()
        .required('Account number is required')
        .matches(/^\d+$/, 'Account number must be a valid number')
        .min(8, 'Account number must be at least 8 digits long'),
      confirmAccountNo: yup
        .string()
        .required('Confirm account number is required')
        .oneOf([yup.ref('accountNo'), null], 'Account numbers must match'),
      ifsc: yup
        .string()
        .length(
          11,
          'After entering the 11-digit IFSC code, click outside or press tab.'
        )
        .required(
          'After entering the 11-digit IFSC code, click outside or press tab.'
        )
    }),
    onSubmit: values => {
      handleSubmitWithValidation(values);
    }
  });

  useEffect(() => {
    formik.setValues({
      id: bankDetails?.id || 0,
      userId: investorId || 0,
      accountHolderName: bankDetails?.accountHolderName || '',
      accountType: bankDetails?.accountType || '',
      accountNo: bankDetails?.accountNo || '',
      confirmAccountNo: bankDetails?.confirmAccountNo || '',
      ifsc: bankDetails?.ifsc || '',
      branch: bankDetails?.branch || '',
      bank: bankDetails?.bank || '',
      address: bankDetails?.address || ''
    });
  }, [bankDetails]);

  const fetchInvestorDetails = async () => {
    try {
      let investor = await validateInvestorDetails(investorId);

      if (investor && investor.bankDetails) {
        const {
          accountHolderName,
          accountType,
          accountNo,
          confirmAccountNo,
          ifsc
        } = investor.bankDetails;
        if (
          accountHolderName &&
          accountType &&
          accountNo &&
          confirmAccountNo &&
          ifsc
        ) {
          setIsBankDetailsRegistred(val => !val);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaste = e => {
    const { name } = e.target;
    formik.setFieldTouched(name, true);
  };

  useEffect(() => {
    if (!isBankDetailsRegistred) {
      fetchInvestorDetails();
    }
  }, []);

  useEffect(() => {
    if (!isBankDetailsRegistred) {
      fetchInvestorDetails();
    }
    const errors = formik.errors;
    const hasAccountNoError = !!errors.accountNo;
    const hasIfscError = !!errors.ifsc;
    const isAccountNoEmpty = !formik.values.accountNo;
    const isIfscEmpty = !formik.values.ifsc;
    const isIfscTouched = Boolean(formik.touched.ifsc);
    const isAccountTouched = Boolean(formik.touched.accountNo);

    if (
      !hasAccountNoError &&
      !hasIfscError &&
      !isAccountNoEmpty &&
      !isIfscEmpty &&
      (isIfscTouched || isAccountTouched)
    ) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const newTimeout = setTimeout(async () => {
        fetchBankDetailsByAccountNumberAndIfsc();
      }, 1000);
      setDebounceTimeout(newTimeout);
    }
  }, [
    formik.values.accountNo,
    formik.values.ifsc,
    formik.errors.accountNo,
    formik.errors.ifsc
  ]);

  useEffect(() => {
    if (!isChequeVerificationByOcrLoading && isChequeVerificationByOcrSuccess) {
      formik.setFieldTouched('accountNo', false);
      formik.setFieldTouched('ifsc', false);

      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const newTimeout = setTimeout(async () => {
        fetchBankDetailsByAccountNumberAndIfsc();
      }, 1000);
      setDebounceTimeout(newTimeout);
    }
  }, [isChequeVerificationByOcrSuccess]);

  const fetchBankDetailsByAccountNumberAndIfsc = async () => {
    try {
      formik.setFieldTouched('accountNo', false);
      formik.setFieldTouched('ifsc', false);

      const existingbankDetails = await dispatch(
        fetchBankDetails({
          accountNumber: formik.values.accountNo
            ? formik.values.accountNo
            : bankDetails?.accountNo,
          ifsc: formik.values.ifsc ? formik.values.ifsc : bankDetails?.ifsc
        })
      );

      if (
        existingbankDetails &&
        existingbankDetails?.payload &&
        existingbankDetails?.payload?.accountHolderName
      ) {
        await dispatch(
          getNameMatchDetails({
            userId: investorId,
            nameToBeMatched: existingbankDetails?.payload?.accountHolderName,
            userType: USER_TYPE_INVESTOR
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('file', fileData?.file);
      onDelete();
      dispatch(
        verifyChequeOcr({
          formData: formData,
          userId: investorId,
          ocrType: DOC_TYPE_CHEQUE_OCR,
          userType: USER_TYPE_INVESTOR
        })
      );
    }
  };

  const onDelete = () => {
    formik.setValues({
      accountHolderName: '',
      accountType: '',
      accountNo: '',
      confirmAccountNo: '',
      ifsc: '',
      branch: '',
      bank: '',
      address: ''
    });
    dispatch(resetPreDataOfOcr());
  };
  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <FormContent>
        <Typography
          marginBottom={'1rem'}
          align="center"
          variant="body2"
          fontWeight={700}
        >
          Bank Account Verification
        </Typography>

        <Typography
          marginBottom={'1rem'}
          marginTop={'1.5rem'}
          align="left"
          variant="subtitle2"
          fontWeight={600}
        >
          Add Bank Account
        </Typography>

        <FormControl
          sx={{ ...formControl, marginTop: '1rem' }}
          fullWidth
          size="small"
        >
          <Typography variant="subtitle2" fontWeight={600} align="left">
            Cheque Image
          </Typography>
          <Grid container marginTop={2} marginBottom={4}>
            <Grid item justifyContent="start" alignItems="start" display="flex">
              <UploadImageCheque
                onDelete={onDelete}
                onImageUpload={onImageUpload}
                key={DOC_TYPE_CHEQUE_OCR}
                docName={DOC_TYPE_CHEQUE_OCR}
              />
            </Grid>
          </Grid>
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            type="tel"
            size="small"
            label="Account Number"
            required
            inputProps={{ maxLength: 30 }}
            name="accountNo"
            value={formik.values.accountNo}
            onPaste={handlePaste}
            onChange={e => {
              formik.setFieldValue(
                'accountNo',
                enterOnlyNumbers(e.target.value)
              );
              formik.setFieldTouched('accountNo', true);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.accountNo && formik.errors.accountNo)}
            helperText={formik.touched.accountNo && formik.errors.accountNo}
          />
        </FormControl>

        <FormControl sx={formControl} fullWidth size="small">
          <TextField
            size="small"
            type="string"
            required
            label="IFSC Code"
            onPaste={handlePaste}
            inputProps={{ maxLength: 11 }}
            name="ifsc"
            value={formik.values.ifsc}
            onChange={e => {
              formik.setFieldValue(
                'ifsc',
                enterOnlyAlphabetsAndNumbers(e.target.value).toUpperCase()
              );
              formik.setFieldTouched('ifsc', true);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.ifsc && formik.errors.ifsc)}
            helperText={formik.touched.ifsc && formik.errors.ifsc}
          />
        </FormControl>
        <Box display="flex" alignItems="center" marginBottom="1rem">
          <ErrorIcon sx={{ fontSize: 'large', color: colorGolden }} />
          <Typography marginLeft="5px" variant="body2">
            Valid A/C No. & IFSC trigger auto-fetch of bank details.
          </Typography>
        </Box>

        {isFetchBankDetailsLoading && (
          <>
            <Typography
              align="center"
              variant="body2"
              sx={{ color: colorSuccess }}
            >
              Fetching bank details..
            </Typography>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}

        {(isFetchBankDetailsSuccess || isBankDetailsRegistred) && (
          <>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                type="password"
                required
                label="Confirm Account Number"
                inputProps={{ maxLength: 30 }}
                name="confirmAccountNo"
                value={formik.values.confirmAccountNo}
                onChange={e =>
                  formik.setFieldValue(
                    'confirmAccountNo',
                    enterOnlyNumbers(e.target.value)
                  )
                }
                error={Boolean(
                  formik.touched.confirmAccountNo &&
                    formik.errors.confirmAccountNo
                )}
                helperText={
                  formik.touched.confirmAccountNo &&
                  formik.errors.confirmAccountNo
                }
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                required
                label="Account Holder Name"
                inputProps={{ maxLength: 60 }}
                name="accountHolderName"
                value={formik.values.accountHolderName}
                disabled
                onChange={e =>
                  formik.setFieldValue(
                    'accountHolderName',
                    enterOnlyAlphabates(e.target.value)
                  )
                }
                error={Boolean(
                  formik.touched.accountHolderName &&
                    formik.errors.accountHolderName
                )}
                helperText={
                  formik.touched.accountHolderName &&
                  formik.errors.accountHolderName
                }
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <InputLabel id="select-account-type" required>
                Account Type
              </InputLabel>
              <Select
                labelId="select-account-type"
                id="select-account-type"
                label="Account Type"
                value={formik.values.accountType}
                onChange={formik.handleChange}
                name="accountType"
                sx={{ textAlign: 'left' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {isArrayNotEmpty(accountTypeList) &&
                  accountTypeList.map(type => {
                    return (
                      <MenuItem key={type?.key} value={type?.key}>
                        <em>{type?.value}</em>
                      </MenuItem>
                    );
                  })}
              </Select>
              {formik.touched.accountType && formik.errors.accountType && (
                <FormHelperText sx={{ textAlign: 'start' }}>
                  {formik.errors.accountType}
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}

        {!isFetchBankDetailsLoading && isFetchBankDetailsSuccess && (
          <Card sx={cardStyle}>
            <CardContent>
              <Grid container spacing={2} sx={{ textAlign: 'left' }}>
                <Grid item xs={6}>
                  <Typography sx={lableStyle}>Bank Name</Typography>
                  <Typography variant="subtitle2">
                    {formik.values.bank}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={lableStyle}>Branch</Typography>
                  <Typography variant="subtitle2">
                    {formik.values.branch}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={lableStyle}>Address</Typography>
                  <Typography variant="subtitle2">
                    {formik.values.address}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </FormContent>

      <Box>
        <FormButtonBottom>
          {(isNameMatchedFailed ||
            (!isNameMatchedLoading &&
              isNameMatchedSuccess &&
              !isNameMatched)) && (
            <FormHelperText sx={{ textAlign: 'start' }}>
              The account holder&apos;s name doesn&apos;t matched with PAN, so
              we can&apos;t proceed.
            </FormHelperText>
          )}
          {!isFetchBankDetailsLoading &&
            !isFetchBankDetailsSuccess &&
            isFetchBankDetailsFailed && (
              <FormHelperText sx={{ textAlign: 'start' }}>
                Unable to fetch bank details by provided Account no and IFSC.
              </FormHelperText>
            )}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleInvesterBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </Box>
      {!isFetchBankDetailsLoading && isFetchBankDetailsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(fetchBankDetailsErrorContainer?.errorMessage)}
        />
      )}
      {!isFetchBankDetailsLoading && isFetchBankDetailsSuccess && (
        <SnackBarBox
          type="success"
          message="Bank Details Fetched Successfully."
        />
      )}

      {!isNameMatchedLoading && isNameMatchedFailed && (
        <SnackBarBox
          type="error"
          message={handleError(NameMatchedErrorContainer?.errorMessage)}
        />
      )}
      {/* {!isChequeVerificationByOcrLoading &&
        isChequeVerificationByOcrSuccess && (
          <SnackBarBox
            type="success"
            message="Account details fetched via cheque."
          />
        )} */}
      {!isChequeVerificationByOcrLoading && isChequeVerificationByOcrFailed && (
        <SnackBarBox
          type="error"
          message={handleError(chequeVerificationErrorContainer?.errorMessage)}
        />
      )}
      {isNameMatchedLoading && (
        <Loader text="Validating account holder name with PAN." />
      )}
      {isChequeVerificationByOcrLoading && (
        <Loader text="Fetching account details via cheque." />
      )}
    </form>
  );
}

PennyDropVerification.propTypes = {
  investorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PennyDropVerification;
