import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ModalBox({ title = '', children, handleCloseModal = null }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const closeDialog = handleClose => {
    handleClose();
    handleCloseModal();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <DialogTitle id="max-width-dialog-title">
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                marginTop={2}
                marginLeft={2}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className="closeButton"
                onClick={() => closeDialog(handleClose)}
              >
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="MuiDialogContentRootc">
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
ModalBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};
export default ModalBox;
