import { Component } from 'react';
import { Typography } from '@mui/material';
import { IMG_ERROR_404 } from '../utils/imageUrls';

function CustomizedError() {
  return (
    <section className="error_container div_not_found">
      <Typography className="error_message" align="center" variant="h5">
        Something went wrong..!
      </Typography>
      <img className="not_found_image" src={IMG_ERROR_404} alt="Error 404" />
    </section>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <CustomizedError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
