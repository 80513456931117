import { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';

function GenericInput({ label, startIcon, error, value, ...props }) {
  const [isShrinked, setIsShrinked] = useState(value !== '');

  const handleFocus = () => {
    setIsShrinked(true);
  };

  const handleBlur = e => {
    if (!e.target.value) setIsShrinked(false);
  };

  useEffect(() => {
    if (value && value !== '') {
      setIsShrinked(true);
    }
  }, [value]);

  return (
    <TextField
      {...props}
      fullWidth
      label={label}
      variant="outlined"
      onFocus={handleFocus}
      onBlur={handleBlur}
      error={error ? true : false}
      helperText={error}
      size="small"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment type="button" position="start">
            {startIcon && (
              <span
                style={{
                  padding: '0.5rem',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
              >
                {startIcon}
              </span>
            )}
          </InputAdornment>
        ),
        tabIndex: -1
      }}
      InputLabelProps={{
        shrink: isShrinked,
        style: { paddingLeft: isShrinked ? '8px' : '30px' }
      }}
    />
  );
}

export default GenericInput;
