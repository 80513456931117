import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  DOC_TYPE_PHOTO,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON,
  UPLOAD_LATEST_PHOTO
} from '../../utils/constant';
import PhotoCaptureForm from '../../components/PhotoCaptureForm';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import {
  clearDocumentUploadState,
  fetchDocuments,
  investorDeleteDocument,
  investorDocumentsList,
  investorUploadDocument
} from '../../redux/reducers/documentSlice';
import { useEffect, useState } from 'react';
import {
  commonInvestorDetailsAdd,
  handleInvesterBackStep
} from '../../redux/reducers/investorSlice';

function UploadLatestPhoto({ investorId }) {
  const dispatch = useDispatch();

  const { investorDetails } = useSelector(state => state.investor);

  useEffect(() => {
    document.title = createTitle('Upload Photo');
    if (investorId) {
      dispatch(investorDocumentsList({ userId: investorId }));
    }
    return () => dispatch(clearDocumentUploadState());
  }, []);

  const [isDocumentUploadHasError, setIsDocumentUploadHasError] = useState({
    status: false,
    message: ''
  });

  const {
    documentDetails,
    isDocumentUploadLoading,
    isDocumentUploadFailed,
    documentUploadErrorContainer,
    isDeleteDocumentLoading,
    isDeleteDocumentFailed,
    deleteDocumentErrorContainer,
    isDocumentUploadSuccess,
    isDeleteDocumentSuccess
  } = useSelector(state => state.documents);

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('file', fileData?.file);
      dispatch(
        investorUploadDocument({
          data: formData,
          userId: investorId,
          docType: fileData?.docType
        })
      );
    }
  };

  const handleDelete = (filename, docType) => {
    dispatch(
      investorDeleteDocument({
        fileName: filename,
        userId: investorId,
        docType: docType
      })
    );
  };

  const validateFileUploaded = async documentTypes => {
    try {
      const uploadedDocuments = await fetchDocuments(investorId);

      const types = {
        [DOC_TYPE_PHOTO]: 'Photo'
      };

      if (isArrayNotEmpty(uploadedDocuments)) {
        uploadedDocuments.forEach(document => {
          const { documentType } = document;
          if (documentTypes && documentTypes.includes(documentType)) {
            const indexToRemove = documentTypes.indexOf(documentType);
            documentTypes.splice(indexToRemove, 1);
          }
        });
      }

      const validationResult = {};

      for (const documentType of documentTypes) {
        if (
          documentDetails &&
          documentType in documentDetails &&
          documentDetails[documentType] !== ''
        ) {
          validationResult[documentType] = {
            status: true,
            message: `${types[documentType]} document is uploaded`
          };
        } else {
          validationResult[documentType] = {
            status: false,
            message: `${types[documentType]}`
          };
        }
      }
      return validationResult;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const documentTypesToValidate = [DOC_TYPE_PHOTO];

      const validationResults = await validateFileUploaded(
        documentTypesToValidate
      );

      const allDocumentsUploaded = documentTypesToValidate.every(
        documentType => validationResults[documentType].status
      );

      if (allDocumentsUploaded) {
        const newvalues = {
          investorDetails: {
            ...investorDetails
          },
          action: UPLOAD_LATEST_PHOTO,
          backAction: investorDetails?.backAction
        };
        dispatch(commonInvestorDetailsAdd(newvalues));
      } else {
        const errorMessages = documentTypesToValidate
          .filter(documentType => !validationResults[documentType].status)
          .map(documentType => validationResults[documentType].message);

        setIsDocumentUploadHasError({
          status: true,
          message: errorMessages.join(', ').concat('  is required.')
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <FormContent>
        <Typography
          marginBottom={'1rem'}
          align="center"
          variant="body2"
          fontWeight={700}
        >
          Upload latest photo
        </Typography>

        <FormControl fullWidth size="small">
          <PhotoCaptureForm
            onImageUpload={onImageUpload}
            handleDelete={handleDelete}
            docType={DOC_TYPE_PHOTO}
          />
        </FormControl>
      </FormContent>

      <Box>
        <FormButtonBottom>
          {isDocumentUploadHasError.status && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {isDocumentUploadHasError.message}
            </FormHelperText>
          )}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleInvesterBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </Box>
      {isDocumentUploadLoading && <Loader text="Uploading Photo." />}
      {isDeleteDocumentLoading && <Loader text="Deleting Photo" />}

      {!isDocumentUploadLoading && isDocumentUploadFailed && (
        <SnackBarBox
          type="error"
          message={handleError(documentUploadErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteDocumentErrorContainer?.errorMessage)}
        />
      )}
      {!isDocumentUploadLoading && isDocumentUploadSuccess && (
        <SnackBarBox type="success" message="Photo Uploaded Successfully." />
      )}
      {!isDeleteDocumentLoading && isDeleteDocumentSuccess && (
        <SnackBarBox type="success" message="Photo Deleted Successfully." />
      )}
    </>
  );
}

UploadLatestPhoto.propTypes = {
  investorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UploadLatestPhoto;
