import * as Yup from 'yup';
import {
  BORROWER_IMAGE_EXTENSION,
  CaptureImageAllowedExtention,
  NET_WORTH_MANDATORY_LIMIT,
  UNEXPECTED_ERROR,
  UploadDocumentAllowedExtention,
  imageAllowedExtention
} from './constant';
import moment from 'moment';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const isEmail = value => {
  if (value && value.match(emailRegExp)) return true;
  else return false;
};

export const isValidEmailId = email => {
  if (!email) return false;

  email = email.toLowerCase();

  if (/@.*@|\.\.|\.\d|@\d/.test(email)) {
    return false;
  }

  if (email.endsWith('.')) {
    return false;
  }

  const validDomains = ['com', 'in', 'org', 'net', 'co'];
  const domain = email.split('.').pop();
  if (!validDomains.includes(domain)) {
    return false;
  }

  return true;
};

export const isMobile = value => {
  if (value && value.match(phoneRegExp)) return true;
  else return false;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = typeof text === 'string';
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
}

export const validateChar = value => {
  return matchIsNumeric(value);
};

export const createTitle = titleName => {
  const updatedTitle = `UniLyfe | ${titleName}`;
  return updatedTitle;
};

export const getName = firstName => {
  return `${firstName || '<FirstName>'}`;
};

export const maskMobile = mobile => {
  const numberStr = mobile.toString();
  if (isNaN(mobile) || numberStr.length < 5) {
    return 'Invalid Number';
  }
  const firstDigits = numberStr.substring(0, 3);
  const lastDigits = numberStr.substring(numberStr.length - 2);
  const maskedNumber = `${firstDigits}XXXXX${lastDigits}`;
  return maskedNumber;
};

export const maskEmail = email => {
  const [localPart, domainPart] = email.split('@');
  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] + '*'.repeat(localPart.length - 2) + localPart.slice(-1)
      : localPart;
  const maskedEmail = `${maskedLocalPart}@${domainPart}`;
  return maskedEmail;
};

export const isAlphabet = value => /^[A-Za-z ]*$/.test(value);

export const isNumber = value => /^[0-9]*$/.test(value);

export const testPattern = (value, pattern, errorMessage) => {
  return Yup.string().matches(pattern, errorMessage).isValidSync(value);
};

export const isValidMobileNumber = value => {
  return testPattern(
    value,
    /^\d{10}$/,
    'The mobile number should consist of exactly 10 digits.'
  );
};

export const isValidEmail = value => {
  return testPattern(
    value,
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
    'Please enter a valid email address.'
  );
};

export const isValidPassword = value => {
  return testPattern(
    value,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*[@$!%*?&].*$/,
    'Password requires 1 uppercase, 1 lowercase, 1 digit, and at least 1 special character.'
  );
};

export const isValidPinNumber = value => {
  return testPattern(
    value,
    /^[0-9]{4}$/,
    'The PIN should have exactly 4 digits.'
  );
};
export const isValidPanNumber = value => {
  return testPattern(
    value,
    /^[A-Z]{5}\d{4}[A-Z]$/,
    'The PAN Number should consist of exactly 10 digits.'
  );
};
export const isValidAadhaarNumber = value => {
  return testPattern(
    value,
    /^\d{12}$/,
    'The Aadhaar Number should be composed of exactly 12 digits.'
  );
};
export const isValidAddress = value => {
  return testPattern(value, /^[.,:\w\s-]+$/, 'Please provide a valid address.');
};
export const isValidPinCode = value => {
  return testPattern(value, /^\d{6}$/, 'Please enter a valid pincode.');
};

export const handleError = error => {
  return error ? error : UNEXPECTED_ERROR;
};

export const formatNumberWithCommas = number => {
  try {
    const value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value ? value : 0;
  } catch (error) {
    return 0;
  }
};

export const calculateMonthlyReturn = (principal, months) => {
  principal = Number(principal);
  if (
    isNaN(principal) ||
    isNaN(Number(process.env.REACT_APP_RATE_OF_INTEREST)) ||
    isNaN(months)
  ) {
    return 'Invalid input. Please provide valid numbers.';
  }
  const annualRate = Number(process.env.REACT_APP_RATE_OF_INTEREST) / 100;
  const monthlyRate = annualRate / 12;
  const monthlyInterest = principal * monthlyRate;
  const expectedReturn = principal + monthlyInterest * months;
  if (isNaN(expectedReturn)) {
    return 0;
  }
  return Number(expectedReturn).toFixed(2);
};

export const isArrayNotEmpty = array => {
  return Array.isArray(array) && array.length > 0;
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  const formattedDate = moment(new Date(date)).format(format);
  return formattedDate;
};

export const enterOnlyAlphabates = value => {
  return value.replace(/[^A-Za-z\s]+|(?<=\s)\s+/g, '');
};
export const enterOnlyNumbers = value => {
  return value.replace(/[^0-9]/g, '');
};
export const enterOnlyEmail = value => {
  value = value.toLowerCase();
  return value.replace(/[^a-zA-Z@.^0-9]/g, '');
};
export const enterEmailOrMobile = value => {
  const withoutSpaces = value.replace(/\s/g, '');
  const sanitizedValue = withoutSpaces.replace(/[^0-9a-zA-Z@.+-]|@.*@/g, '');
  return sanitizedValue;
};

export const enterWithNoSpaces = value => {
  return value.replace(/\s/g, '');
};
export const enterMaxInvestedAmount = value => {
  value = value.trim().replace(/^0+/, '');
  const sanitizedValue = enterOnlyNumbers(value);
  const numericValue = parseFloat(sanitizedValue);

  if (numericValue < 0) {
    return 0;
  }
  return sanitizedValue;
};

export const enterOnlyAlphabetsAndNumbers = value => {
  return value.replace(/[^a-zA-Z0-9]/g, '');
};

export const convertToDatePicker = inputDateString => {
  var dateFormats = [
    'YYYY-MM-DD',
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'DD-MM-YYYY',
    'YYYY/MM/DD'
  ];

  for (var i = 0; i < dateFormats.length; i++) {
    var parsedDate = moment(inputDateString, dateFormats[i], true);
    if (parsedDate.isValid()) {
      return parsedDate.format('YYYY-MM-DD');
    }
  }
  return inputDateString;
};

export const printCurrentTimeStamp = () => {
  const currentDate = moment();
  const formattedDate = currentDate.format('DD MMMM, YYYY | h:mm A');
  return formattedDate;
};

export const isGreaterThanZero = value => {
  if (typeof value === 'number') {
    return value > 0;
  }
  return false;
};

export const isImageExtensionAllowed = filename => {
  const allowedExtensions = imageAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isBorrowerImageExtensionAllowed = filename => {
  const allowedExtensions = BORROWER_IMAGE_EXTENSION;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isUploadImageExtensionAllowed = filename => {
  const allowedExtensions = UploadDocumentAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isCaptureImageExtensionAllowed = filename => {
  const allowedExtensions = CaptureImageAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};

export const getImageDetails = (docType, documents) => {
  if (isArrayNotEmpty(documents)) {
    const document = documents.find(
      document => document.fileName == docType?.fileName
    );
    return document ? document : null;
  }
};

export function categorizeDocuments(data) {
  const categorizedData = {};

  data.forEach(item => {
    const { documentType } = item;

    if (!categorizedData[documentType]) {
      categorizedData[documentType] = {};
    }

    categorizedData[documentType] = {
      ...categorizedData[documentType],
      ...item
    };
  });

  return categorizedData;
}

export const getFileName = content => {
  try {
    let filename = '';
    const filenameMatch = /filename=([^;\s]+)/.exec(content);
    if (filenameMatch) {
      filename = filenameMatch[1];
    }
    filename = filename.replace(/"/g, '');
    return filename;
  } catch (error) {
    console.error(error);
  }
};

export const isValidOtp = value => {
  return testPattern(value, /^\d{4}$/, 'Please enter a valid OTP.');
};

export const highlightText = (text, query) => {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  return text.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} style={{ backgroundColor: 'yellow' }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const maskAccountNumber = inputString => {
  try {
    if (inputString.length < 4) {
      return 'XXXX' + inputString.slice(-4);
    }
    const maskedCharacters = 'X'.repeat(inputString.length - 4);
    const lastFourDigits = inputString.slice(-4);
    const maskedString = `${maskedCharacters}${lastFourDigits}`;
    return maskedString;
  } catch (error) {
    console.error(error);
  }
};

export const isDisplayNetworthCertificate = (
  currentInvestment,
  previousInvestment
) => {
  try {
    currentInvestment = Number(currentInvestment);
    previousInvestment = Number(previousInvestment);

    const totalInvestment = currentInvestment + previousInvestment;

    return totalInvestment >= Number(NET_WORTH_MANDATORY_LIMIT);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getFullName = (firstName, middleName, lastName) => {
  const nameParts = [];
  if (firstName && firstName.trim() !== '') {
    nameParts.push(firstName);
  }
  if (middleName && middleName.trim() !== '') {
    nameParts.push(middleName);
  }
  if (lastName && lastName.trim() !== '') {
    nameParts.push(lastName);
  }
  const fullName = nameParts.join(' ');
  return fullName;
};

export const generateImageSrc = (base64String, mimeType = 'image') => {
  try {
    if (!base64String || typeof base64String !== 'string') {
      return null;
    }
    return `data:${mimeType};base64,${base64String}`;
  } catch (error) {
    console.error(error);
  }
};

export const formatAmountInINR = amount => {
  return '₹ ' + new Intl.NumberFormat('en-IN').format(amount);
};

export const isEmptyString = str => {
  return str.trim().length === 0;
};

export const formatDateTimeStamp = date => {
  const currentDate = moment(date);
  // const modifiedDate = currentDate.add(5, 'hours').add(30, 'minutes');
  const formattedDate = currentDate.format('DD MMMM, YYYY | h:mm A');
  return formattedDate;
};

export const parseParams = (params = '') => {
  const rawParams = params.replace('?', '').split('&');
  const extractedParams = {};
  rawParams.forEach(item => {
    item = item.split('=');
    extractedParams[item[0]] = item[1];
  });
  return extractedParams;
};

export const formatINR = amount => {
  return amount ? `₹ ${new Intl.NumberFormat('en-IN').format(amount)}` : '-';
};

export const calculateTotalLoanPayment = (principal, months, roi) => {
  const additionalFees = 0 / 100;

  principal = Number(principal);

  if (isNaN(principal) || isNaN(Number(roi)) || isNaN(months) || months <= 0) {
    return 0;
  }

  const emi =
    principal * (1 + (Number(roi) / 100 / 12) * months + additionalFees);

  return emi;
};

export const calculateMonthlyEMI = (principal, months, roi) => {
  if (isNaN(principal)) return 0;

  const monthlyEMI = calculateEMIbyNewFormula(principal, months, roi);
  return isNaN(monthlyEMI) ? 0 : parseFloat(monthlyEMI);
};

export const calculateGraphEMI = (principal, months, decimalPlaces = 2) => {
  if (isNaN(principal)) return 0;
  const emi = (principal / Number(months)).toFixed(decimalPlaces);
  return parseFloat(emi);
};

export const calculateEMIbyNewFormula = (
  loanAmount,
  tenure,
  ROI,
  decimalPlaces = 2
) => {
  const additionalFees = 0 / 100;
  const repaymentFrequency = 1;

  let emi =
    (loanAmount * (1 + (Number(ROI) / 100 / 12) * tenure + additionalFees)) /
    tenure /
    repaymentFrequency;
  emi = emi.toFixed(decimalPlaces);
  return parseFloat(emi);
};

export const getMonthValue = monthNumber => {
  const monthsInwords = [
    { id: 1, value: 'Jan' },
    { id: 2, value: 'Feb' },
    { id: 3, value: 'Mar' },
    { id: 4, value: 'Apr' },
    { id: 5, value: 'May' },
    { id: 6, value: 'Jun' },
    { id: 7, value: 'Jul' },
    { id: 8, value: 'Aug' },
    { id: 9, value: 'Sep' },
    { id: 10, value: 'Oct' },
    { id: 11, value: 'Nov' },
    { id: 12, value: 'Dec' }
  ];
  const monthObject = monthsInwords.find(month => month.id === monthNumber);

  if (monthObject) {
    return monthObject.value;
  } else {
    return 'Invalid month number';
  }
};

export const removeHyphen = value => (value ? value.replace('_', ' ') : '');

export const isValidBureauResult = bureauResult => {
  if (bureauResult && bureauResult.score !== undefined) {
    return (
      bureauResult.score !== 'Score not found.' && bureauResult.score !== 0
    );
  }
  return false;
};

export const getLoanStatus = loanDetails => {
  if (loanDetails && loanDetails?.status !== undefined) {
    const status = removeHyphen(loanDetails?.status);
    return status ? status : 'N/A';
  }
  return 'N/A';
};
