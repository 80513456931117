import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  BORROWER_REQUIREMENTS,
  FINISH,
  USER_TYPE_BORROWER,
  borrowerSteps
} from '../../utils/constant';
import { api, multipartApi } from '../../config/api';
import { getImageDetails } from '../../utils/utils';
import { setDocumentDetails } from './documentSlice';
export const setBorrowerAction = createAction('SET_BORROWER_ACTION');
export const handleBorrowerNextStep = createAction('HANDLE_BORROWER_NEXT_STEP');
export const handleBorrowerBackStep = createAction('HANDLE_BORROWER_BACK_STEP');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const setBackButtonAction = createAction('SET_BACK_BUTTON_ACTION');
export const setBackButtonActionFalse = createAction(
  'SET_BACK_BUTTON_ACTION_FALSE'
);
export const clearAadhaarVerificationState = createAction(
  'CLEAR_AADHAAR_VERIFICATION_STATE'
);
export const clearPanVerificationState = createAction(
  'CLEAR_PAN_VERIFICATION_STATE'
);
export const clearPennyDropVerificationState = createAction(
  'CLEAR_PENNY_DROP_VERIFICATION_STATE'
);
export const clearEsignState = createAction('CLEAR_E_SIGN_STATE');

export const clearNotificationState = createAction('CLEAR_NOTIFICATION_STATE');

export const resetPreDataOfOcr = createAction('RESET_PRE_DATA_OF_OCR');

export const setBorrowerId = createAction('SET_BORROWER_ID');

export const clearLoanDetails = createAction('CLEAR_LOAN_DETAILS');

export const clearBureauDetails = createAction('CLEAR_BUREAU_DETAILS');

export const getListOfProducts = createAsyncThunk(
  'borrower/dropDown/products',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/dropDown/products');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const commonBorrowerDetailsAdd = createAsyncThunk(
  'borrower/common',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post('/borrowers', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const commonBorrowerDetailsUpdate = createAsyncThunk(
  'borrower/common_update',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.put('/borrowers', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPanByPanNumber = createAsyncThunk(
  'borrower/verify_pan_number',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/karza/panVerification',
        detailsToBeUploaded
      );
      return response.data;
      // return new Promise((resolve, _reject) => {
      //   resolve({
      //     aadhaarNumber: null,
      //     nameAsPerAadhaar: null,
      //     dobAsPerAadhaar: null,
      //     address1: null,
      //     address2: null,
      //     landmark: null,
      //     pinCode: null,
      //     city: null,
      //     state: null,
      //     panNumber: 'CWJPP7963C',
      //     nameAsPerPan: 'DIPESH NANDKUMAR PATIL',
      //     dobAsPerPan: '24/11/1996',
      //     gender: null,
      //     fatherName: 'NANDKUMAR PATIL',
      //     originalFileName: 'pan_Borrower_122_Pancard.jpg'
      //   });
      // });
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('service unavailable')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Please enter a valid PAN Number.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Please enter a valid PAN Number.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyPanByPanOcr = createAsyncThunk(
  'borrower/verify_pan_ocr',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        `/karza/ocrDetails`,
        detailsToBeUploaded
      );
      return response.data;

      // return new Promise((resolve, _reject) => {
      //   resolve({
      //     aadhaarNumber: null,
      //     nameAsPerAadhaar: null,
      //     dobAsPerAadhaar: null,
      //     address1: null,
      //     address2: null,
      //     landmark: null,
      //     pinCode: null,
      //     city: null,
      //     state: null,
      //     panNumber: 'CWJPP7963C',
      //     nameAsPerPan: 'DIPESH NANDKUMAR PATIL',
      //     dobAsPerPan: '24/11/1996',
      //     gender: null,
      //     fatherName: 'NANDKUMAR PATIL',
      //     originalFileName: 'pan_Borrower_122_Pancard.jpg'
      //   });
      // });
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('service unavailable')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'The OCR image service is currently unavailable. Please try Aadhaar OTP option.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'Please upload a clear image, or try Aadhaar OTP option.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyChequeOcr = createAsyncThunk(
  'borrower/verify_cheque_ocr',
  async (
    { formData, userId, ocrType, userType },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await multipartApi.post(
        `/karza/chequeOcr?userId=${userId}&ocrType=${ocrType}&userType=${userType}`,
        formData
      );

      const originalFileName = response?.data?.originalFileName;

      const docList = await api.get(
        `/documents/list?userId=${userId}&userType=${USER_TYPE_BORROWER}`
      );
      const imageDetails = getImageDetails(
        { fileName: originalFileName },
        docList.data
      );

      dispatch(
        setDocumentDetails({ moduleType: ocrType, imageDetails: imageDetails })
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   resolve(
      //     {
      //       "accountHolderName": "DIPESH NANDKUMAR PATIL",
      //       "accountType": null,
      //       "accountNo": "32460062613",
      //       "confirmAccountNo": null,
      //       "ifsc": "SBIN0013822",
      //       "branch": null,
      //       "bank": "STATE BANK OF INDIA",
      //       "address": null,
      //       "originalFileName": "cheque_Borrower_51_cancelledcheque.jpg"
      //     }
      //   )
      // })
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('service unavailable')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'The cheque image service is currently unavailable. Please enter the bank details manually.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'Please upload a clear image, or enter the bank details manually.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddharOtp = createAsyncThunk(
  'borrower/get_addhar_otp',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post('/karza/aadhaarOTP', detailsToBeUploaded);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAadhaarByOtp = createAsyncThunk(
  'borrower/verify_aadhar_details_by_otp',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/karza/aadhaarDetailsByOTP',
        detailsToBeUploaded
      );
      return response.data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'The OTP provided is invalid. Please double-check and try again.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAadhaarByAadhaarOcr = createAsyncThunk(
  'borrower/verify_aadhaar_ocr',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        '/karza/ocrDetails',
        detailsToBeUploaded
      );
      return response.data;

      // return new Promise((resolve, _reject) => {
      //   resolve({
      //     aadhaarNumber: '451833851402',
      //     nameAsPerAadhaar: 'Diposh Nandkumar Patil',
      //     dobAsPerAadhaar: '24/11/1996',
      //     address1: 'Saphale',
      //     address2: null,
      //     landmark: 'Grand Central',
      //     pinCode: 401102,
      //     city: 'Mumbai',
      //     state: 'Maharashtra',
      //     panNumber: null,
      //     nameAsPerPan: null,
      //     dobAsPerPan: null,
      //     gender: 'MALE',
      //     fatherName: null,
      //     originalFileName: 'aadhaarFront_Borrower_178_addharFront.jpg'
      //   });
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBankDetails = createAsyncThunk(
  'borrower/bankDetails',
  async (detailsToBeUploaded, { rejectWithValue }) => {
    try {
      const response = await api.post('/karza/pennyDrop', detailsToBeUploaded);
      return response.data;

      // return new Promise((resolve, _reject) => {
      //   resolve({
      //     "accountHolderName": "DIPESH NANDKUMAR PATIL",
      //     "accountType": null,
      //     "accountNo": "32460062613",
      //     "confirmAccountNo": null,
      //     "ifsc": "SBIN0013822",
      //     "branch": "SAPHALE",
      //     "bank": "STATE BANK OF INDIA",
      //     "address": "RADHACHANDRA HERITAGE,TANDULWADI ROAD,SAPHALE EAST,THANE,DISTTHANE401102",
      //     "originalFileName": null
      //   })
      // })
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid id number or combination of inputs')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage:
            'Please ensure the entered Account Number and IFSC Code is correct.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getBorrowerDetails = createAsyncThunk(
  'borrower/getDetailsById',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/borrowers?userId=${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getNameMatchDetails = createAsyncThunk(
  'borrower/karza/nameMatch',
  async ({ userId, nameToBeMatched, userType }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/karza/nameMatch?userId=${userId}&userType=${userType}&nameToBeMatched=${nameToBeMatched}`
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   resolve(
      //     {
      //       "isNameMatched": true
      //     }
      //   )
      // })
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateBorrowerDetails = async userId => {
  try {
    const response = await api.get(`/borrowers?userId=${userId}`);
    return response?.data;
  } catch (error) {
    console.error('Error while fetching borrower details ', error);
  }
};

export const notifyBorrower = createAsyncThunk(
  'borrower/notifications/sendNotificationToProspectBorrower',
  async ({ userId, rmId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/notifications/sendNotificationToProspectBorrower?userId=${userId}&rmId=${rmId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBureauResult = createAsyncThunk(
  'borrower/fetch_bureau_result',
  async ({ lbUserId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/borrowers/fetchBureauPull?lbUserId=${lbUserId}`
      );
      const bureauData = response?.data['data'];
      return bureauData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchLoanStatus = createAsyncThunk(
  'borrower/fetch_loan_status',
  async ({ borrowingDetailsId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/borrowers/lendbox/loanStatus?borrowingDetailsId=${borrowingDetailsId}`
      );
      const loanData = response?.data?.data?.details;
      return loanData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  productList: [],
  borrowerDetails: {
    borrowerId: 0,
    action: BORROWER_REQUIREMENTS,
    backAction: false,
    id: 0,
    productType: '',
    tenureInMonths: 0,
    amount: 0,
    panNumber: '',
    nameAsPerPan: '',
    dobAsPerPan: '',
    gender: '',
    aadhaarNumber: '',
    nameAsPerAadhaar: '',
    dobAsPerAadhaar: '',
    address1: '',
    address2: '',
    landmark: '',
    pinCode: 0,
    city: '',
    state: '',
    borrowingDetails: []
  },
  referenceDetails: {
    id: 0,
    referenceTag: '',
    referenceName: '',
    referenceRelationship: '',
    mobileNo: '',
    emailId: ''
  },
  bankDetails: {
    accountHolderName: '',
    accountType: '',
    accountNo: '',
    confirmAccountNo: '',
    ifsc: '',
    branch: '',
    bank: '',
    address: ''
  },
  aadharOtpDetails: {
    requestId: '',
    result: {
      message: ''
    },
    statusCode: 0
  },
  isLoading: false,
  isSuccess: false,
  isNameMatched: false,
  isLoadingProducts: false,
  isLoadProductsSuccess: false,
  isLoadProductsFailed: false,
  isPanVerificationByNumberLoading: false,
  isPanVerificationByNumberSuccess: false,
  isPanVerificationByNumberFailed: false,
  isPanVerificationByOcrLoading: false,
  isPanVerificationByOcrSuccess: false,
  isPanVerificationByOcrFailed: false,
  isAadhaarVerificationByOtpLoading: false,
  isAadhaarVerificationByOtpSuccess: false,
  isAadhaarVerificationByOtpFailed: false,
  isAadhaarVerificationByOcrLoading: false,
  isAadhaarVerificationByOcrSuccess: false,
  isAadhaarVerificationByOcrFailed: false,
  isGetAadhaarOtpLoading: false,
  isGetAadhaarOtpSuccess: false,
  isGetAadhaarOtpFailed: false,
  isFetchBankDetailsLoading: false,
  isFetchBankDetailsSuccess: false,
  isFetchBankDetailsFailed: false,
  isNameMatchedLoading: false,
  isNameMatchedSuccess: false,
  isNameMatchedFailed: false,
  isEsignVerificationLoading: false,
  isEsignVerificationSuccess: false,
  isEsignVerificationFailed: false,
  EsignVerificationErrorContainer: {
    error: false,
    errorMessage: 'Esign details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isChequeVerificationByOcrLoading: false,
  isChequeVerificationByOcrSuccess: false,
  isChequeVerificationByOcrFailed: false,
  chequeVerificationErrorContainer: {
    error: false,
    errorMessage: 'Cheque details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  NameMatchedErrorContainer: {
    error: false,
    errorMessage: 'Error occured while fetching name match details.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  fetchBankDetailsErrorContainer: {
    error: false,
    errorMessage: 'Error occured while fetching aadhaar details.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  getAddharOtpErrorContainer: {
    error: false,
    errorMessage: 'Aadhaar OTP could not be received.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  aadharVerificationByOcrErrorContainer: {
    error: false,
    errorMessage: 'Aadhaar details could not be verified via image.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  aadhaarVerificationByOtpErrorContainer: {
    error: false,
    errorMessage: 'Aadhaar details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  panVerificationByOcrErrorContainer: {
    error: false,
    errorMessage: 'PAN details could not be verified via image.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  panVerificationByNumberErrorContainer: {
    error: false,
    errorMessage: 'PAN details verification unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  productsErrorContainer: {
    error: false,
    errorMessage: 'Products retrieval unsuccessful.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isError: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isNotifyBorrowerLoading: false,
  isNotifyBorrowerSuccess: false,
  isNotifyBorrowerFailed: false,
  notifyBorrowerErrorContainer: {
    error: false,
    errorMessage: 'Notification Failed.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isFetchBureauResultLoading: false,
  isFetchBureauResultSuccess: false,
  isFetchBureauResultFailed: false,
  fetchBureauResultErrorContainer: {
    error: false,
    errorMessage: 'Bureau result Failed.',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  bureauResult: {
    score: 0,
    totalActiveAccounts: 0,
    totalSecuredAccounts: 0,
    totalUnSecuredAccounts: 0,
    numberOfSpecialMentionedAccounts: 0,
    numberOfSubStandardAccounts: 0,
    numberOfDoubtfullAccounts: 0,
    numberOfLossAccounts: 0,
    numberOf30PlusDpdIn3Months: 0,
    numberOf90PlusDpdIn12Months: 0,
    totalOverDueAmount: 0,
    activeMonthlyEmi: 0
  },
  isFetchLoanStatusLoading: false,
  isFetchLoanStatusSuccess: false,
  isFetchLoanStatusFailed: false,
  loanStatusDetails: {
    loanId: null,
    lbUserId: null,
    status: null,
    disbursalDate: null,
    metadata: null
  },
  fetchLoanStatusErrorContainer: {
    error: false,
    errorMessage: 'Bureau result Failed.',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const borrowerSlice = createSlice({
  name: 'borrower',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(setBorrowerAction, (state, action) => {
        state.borrowerDetails.action = action.payload;
      })
      .addCase(setBorrowerId, (state, action) => {
        state.borrowerDetails = {};
        state.bankDetails = {};
        state.referenceDetails = {};
        state.borrowerDetails.borrowerId = action.payload;
      })
      .addCase(handleBorrowerNextStep, state => {
        const currentIndex = borrowerSteps.indexOf(
          state.borrowerDetails.action
        );
        if (currentIndex < borrowerSteps.length - 1) {
          state.borrowerDetails.action = borrowerSteps[currentIndex + 1];
        } else {
          state.borrowerDetails.action = FINISH;
        }
      })
      .addCase(handleBorrowerBackStep, state => {
        state.borrowerDetails.backAction = true;
        const currentIndex = borrowerSteps.indexOf(
          state.borrowerDetails.action
        );
        if (currentIndex < borrowerSteps.length) {
          state.borrowerDetails.action = borrowerSteps[currentIndex - 1];
        }
      })
      .addCase(setBackButtonAction, state => {
        state.borrowerDetails.backAction = true;
      })
      .addCase(setBackButtonActionFalse, state => {
        state.borrowerDetails.backAction = false;
      })
      .addCase(getListOfProducts.pending, state => {
        (state.isLoadingProducts = true),
          (state.isLoadProductsSuccess = false),
          (state.isLoadProductsFailed = false);
      })
      .addCase(getListOfProducts.fulfilled, (state, action) => {
        (state.isLoadingProducts = false), (state.productList = action.payload);
      })
      .addCase(getListOfProducts.rejected, (state, action) => {
        (state.isLoadProductsSuccess = false),
          (state.isLoadingProducts = false),
          (state.isLoadProductsFailed = true),
          (state.productsErrorContainer = {
            ...state.productsErrorContainer,
            ...action.payload
          });
      })
      .addCase(commonBorrowerDetailsAdd.pending, state => {
        (state.isLoading = true),
          (state.isSuccess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(commonBorrowerDetailsAdd.fulfilled, (state, action) => {
        state.borrowerDetails.backAction = false;
        if (action.payload?.action === 'lendboxRegistration') {
          state.borrowerDetails.action = FINISH;
        } else {
          const currentIndex = borrowerSteps.indexOf(action?.payload?.action);
          if (currentIndex < borrowerSteps.length - 1) {
            state.borrowerDetails.action = borrowerSteps[currentIndex + 1];
          } else {
            state.borrowerDetails.action = FINISH;
          }
        }

        const updatedDetails = { ...state.borrowerDetails };
        const payloadDetails = action.payload?.borrowerDetails;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });

        const updatedReferenceDetails = { ...state.referenceDetails };
        const payloadReferenceDetails = action.payload?.referenceDetails
          ? action.payload?.referenceDetails
          : null;

        if (payloadReferenceDetails) {
          Object.keys(payloadReferenceDetails).forEach(key => {
            if (payloadReferenceDetails[key] !== null) {
              updatedReferenceDetails[key] =
                payloadReferenceDetails[key] &&
                payloadReferenceDetails[key] !== 'null'
                  ? payloadReferenceDetails[key]
                  : '';
            }
          });
          state.referenceDetails = { ...updatedReferenceDetails };
        }

        const updatedBankDetails = { ...state.bankDetails };
        const payloadbankDetails = action.payload?.bankDetails
          ? action.payload?.bankDetails
          : null;

        if (payloadbankDetails) {
          Object.keys(payloadbankDetails).forEach(key => {
            if (payloadbankDetails[key] !== null) {
              updatedBankDetails[key] =
                payloadbankDetails[key] && payloadbankDetails[key] !== 'null'
                  ? payloadbankDetails[key]
                  : '';
            }
          });
          state.bankDetails = { ...updatedBankDetails };
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.borrowerDetails = { ...updatedDetails };
      })
      .addCase(commonBorrowerDetailsAdd.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isSuccess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(verifyPanByPanNumber.pending, state => {
        (state.isPanVerificationByNumberLoading = true),
          (state.isPanVerificationByNumberSuccess = false),
          (state.isPanVerificationByNumberFailed = false),
          (state.panVerificationByNumberErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyPanByPanNumber.fulfilled, (state, action) => {
        state.isPanVerificationByNumberLoading = false;
        state.isPanVerificationByNumberSuccess = true;

        const updatedDetails = { ...state.borrowerDetails };
        const payloadDetails = action.payload?.borrowerDetails
          ? action.payload?.borrowerDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.borrowerDetails = { ...updatedDetails };
      })
      .addCase(verifyPanByPanNumber.rejected, (state, action) => {
        (state.isPanVerificationByNumberLoading = false),
          (state.isPanVerificationByNumberLoading = false),
          (state.isPanVerificationByNumberFailed = true),
          (state.panVerificationByNumberErrorContainer = {
            ...state.panVerificationByNumberErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyPanByPanOcr.pending, state => {
        (state.isPanVerificationByOcrLoading = true),
          (state.isPanVerificationByOcrSuccess = false),
          (state.isPanVerificationByOcrFailed = false),
          (state.panVerificationByOcrErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyPanByPanOcr.fulfilled, (state, action) => {
        state.isPanVerificationByOcrLoading = false;
        state.isPanVerificationByOcrSuccess = true;

        const updatedDetails = { ...state.borrowerDetails };
        const payloadDetails = action.payload?.borrowerDetails
          ? action.payload?.borrowerDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.borrowerDetails = { ...updatedDetails };
      })
      .addCase(verifyPanByPanOcr.rejected, (state, action) => {
        (state.isPanVerificationByOcrLoading = false),
          (state.isPanVerificationByOcrSuccess = false),
          (state.isPanVerificationByOcrFailed = true),
          (state.panVerificationByOcrErrorContainer = {
            ...state.panVerificationByOcrErrorContainer,
            ...action.payload
          });
      })
      .addCase(getAddharOtp.pending, state => {
        (state.isGetAadhaarOtpLoading = true),
          (state.isGetAadhaarOtpSuccess = false),
          (state.isGetAadhaarOtpFailed = false),
          (state.aadharOtpDetails = {}),
          (state.getAddharOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getAddharOtp.fulfilled, (state, action) => {
        state.isGetAadhaarOtpLoading = false;
        state.isGetAadhaarOtpSuccess = true;
        state.aadharOtpDetails = {
          ...state.aadharOtpDetails,
          ...action.payload
        };
      })
      .addCase(getAddharOtp.rejected, (state, action) => {
        (state.isGetAadhaarOtpLoading = false),
          (state.aadharOtpDetails = {}),
          (state.isGetAadhaarOtpSuccess = false),
          (state.isGetAadhaarOtpFailed = true),
          (state.getAddharOtpErrorContainer = {
            ...state.getAddharOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyAadhaarByOtp.pending, state => {
        (state.isAadhaarVerificationByOtpLoading = true),
          (state.isAadhaarVerificationByOtpSuccess = false),
          (state.isAadhaarVerificationByOtpFailed = false),
          (state.aadhaarVerificationByOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyAadhaarByOtp.fulfilled, (state, action) => {
        state.isAadhaarVerificationByOtpLoading = false;
        state.isAadhaarVerificationByOtpSuccess = true;

        const updatedDetails = { ...state.borrowerDetails };
        const payloadDetails = action.payload?.borrowerDetails
          ? action.payload?.borrowerDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.borrowerDetails = { ...updatedDetails };
      })
      .addCase(verifyAadhaarByOtp.rejected, (state, action) => {
        (state.isAadhaarVerificationByOtpLoading = false),
          (state.isAadhaarVerificationByOtpSuccess = false),
          (state.isAadhaarVerificationByOtpFailed = true),
          (state.aadhaarVerificationByOtpErrorContainer = {
            ...state.aadhaarVerificationByOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyAadhaarByAadhaarOcr.pending, state => {
        (state.isAadhaarVerificationByOcrLoading = true),
          (state.isAadhaarVerificationByOcrSuccess = false),
          (state.isAadhaarVerificationByOcrFailed = false),
          (state.aadharVerificationByOcrErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyAadhaarByAadhaarOcr.fulfilled, (state, action) => {
        state.isAadhaarVerificationByOcrLoading = false;
        state.isAadhaarVerificationByOcrSuccess = true;

        const updatedDetails = { ...state.borrowerDetails };
        const payloadDetails = action.payload?.borrowerDetails
          ? action.payload?.borrowerDetails
          : action.payload;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.borrowerDetails = { ...updatedDetails };
      })
      .addCase(verifyAadhaarByAadhaarOcr.rejected, (state, action) => {
        (state.isAadhaarVerificationByOcrLoading = false),
          (state.isAadhaarVerificationByOcrSuccess = false),
          (state.isAadhaarVerificationByOcrFailed = true),
          (state.aadharVerificationByOcrErrorContainer = {
            ...state.aadharVerificationByOcrErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchBankDetails.pending, state => {
        (state.isFetchBankDetailsLoading = true),
          (state.isFetchBankDetailsSuccess = false),
          (state.isFetchBankDetailsFailed = false),
          (state.fetchBankDetailsErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchBankDetails.fulfilled, (state, action) => {
        state.isFetchBankDetailsLoading = false;
        state.isFetchBankDetailsSuccess = true;
        state.isFetchBankDetailsFailed = false;

        const updatedDetails = { ...state.bankDetails };
        const payloadDetails = action.payload ? action.payload : null;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.bankDetails = { ...updatedDetails };
      })
      .addCase(fetchBankDetails.rejected, (state, action) => {
        (state.isFetchBankDetailsLoading = false),
          (state.isFetchBankDetailsSuccess = false),
          (state.isFetchBankDetailsFailed = true),
          (state.fetchBankDetailsErrorContainer = {
            ...state.fetchBankDetailsErrorContainer,
            ...action.payload
          });
      })
      .addCase(getBorrowerDetails.pending, state => {
        (state.isLoading = true),
          (state.isSuccess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getBorrowerDetails.fulfilled, (state, action) => {
        if (action.payload?.action === 'lendboxRegistration') {
          state.borrowerDetails.action = FINISH;
        } else {
          const currentIndex = borrowerSteps.indexOf(action?.payload?.action);
          if (currentIndex < borrowerSteps.length - 1) {
            state.borrowerDetails.action = borrowerSteps[currentIndex + 1];
          } else {
            state.borrowerDetails.action = FINISH;
          }
        }

        const updatedDetails = { ...state.borrowerDetails };

        const payloadDetails = action.payload?.borrowerDetails
          ? action.payload?.borrowerDetails
          : null;

        const updatedBankDetails = { ...state.bankDetails };
        const payloadbankDetails = action.payload?.bankDetails
          ? action.payload?.bankDetails
          : null;

        const updatedReferenceDetails = { ...state.referenceDetails };
        const payloadReferenceDetails = action.payload?.referenceDetails
          ? action.payload?.referenceDetails
          : null;

        if (payloadDetails) {
          Object.keys(payloadDetails).forEach(key => {
            if (payloadDetails[key] !== null) {
              updatedDetails[key] =
                payloadDetails[key] && payloadDetails[key] !== 'null'
                  ? payloadDetails[key]
                  : '';
            }
          });
          state.borrowerDetails = { ...updatedDetails };
        }
        if (payloadbankDetails) {
          Object.keys(payloadbankDetails).forEach(key => {
            if (payloadbankDetails[key] !== null) {
              updatedBankDetails[key] =
                payloadbankDetails[key] && payloadbankDetails[key] !== 'null'
                  ? payloadbankDetails[key]
                  : '';
            }
          });
          state.bankDetails = { ...updatedBankDetails };
        }
        if (payloadReferenceDetails) {
          Object.keys(payloadReferenceDetails).forEach(key => {
            if (payloadReferenceDetails[key] !== null) {
              updatedReferenceDetails[key] =
                payloadReferenceDetails[key] &&
                payloadReferenceDetails[key] !== 'null'
                  ? payloadReferenceDetails[key]
                  : '';
            }
          });
          state.referenceDetails = { ...updatedReferenceDetails };
        }

        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(getBorrowerDetails.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isSuccess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(getNameMatchDetails.pending, state => {
        (state.isNameMatchedLoading = true),
          (state.isNameMatchedSuccess = false),
          (state.isNameMatchedFailed = false),
          (state.NameMatchedErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getNameMatchDetails.fulfilled, (state, action) => {
        state.isNameMatchedLoading = false;
        state.isNameMatchedSuccess = true;
        state.isNameMatched = action.payload?.isNameMatched
          ? action.payload?.isNameMatched
          : false;
      })
      .addCase(getNameMatchDetails.rejected, (state, action) => {
        (state.isNameMatchedLoading = false),
          (state.isNameMatchedSuccess = false),
          (state.isNameMatchedFailed = true),
          (state.NameMatchedErrorContainer = {
            ...state.NameMatchedErrorContainer,
            ...action.payload
          });
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(clearAadhaarVerificationState, state => {
        (state.isAadhaarVerificationByOtpSuccess = false),
          (state.isAadhaarVerificationByOtpFailed = false),
          (state.isAadhaarVerificationByOcrSuccess = false),
          (state.isAadhaarVerificationByOcrFailed = false),
          (state.isGetAadhaarOtpSuccess = false),
          (state.isGetAadhaarOtpFailed = false);
      })
      .addCase(clearPanVerificationState, state => {
        (state.isPanVerificationByNumberSuccess = false),
          (state.isPanVerificationByNumberFailed = false),
          (state.isPanVerificationByOcrSuccess = false),
          (state.isPanVerificationByOcrFailed = false);
      })
      .addCase(clearPennyDropVerificationState, state => {
        (state.isFetchBankDetailsSuccess = false),
          (state.isFetchBankDetailsFailed = false),
          (state.isNameMatchedFailed = false),
          (state.isChequeVerificationByOcrSuccess = false),
          (state.isChequeVerificationByOcrFailed = false);
      })
      .addCase(clearEsignState, state => {
        (state.isEsignVerificationSuccess = false),
          (state.isEsignVerificationFailed = false);
      })
      .addCase(resetPreDataOfOcr, state => {
        (state.bankDetails.accountNo = ''),
          (state.bankDetails.ifsc = ''),
          (state.bankDetails.accountHolderName = ''),
          (state.bankDetails.accountType = ''),
          (state.bankDetails.confirmAccountNo = ''),
          (state.bankDetails.branch = ''),
          (state.bankDetails.bank = ''),
          (state.bankDetails.address = ''),
          (state.isFetchBankDetailsLoading = false),
          (state.isFetchBankDetailsSuccess = false);
      })
      .addCase(commonBorrowerDetailsUpdate.pending, state => {
        (state.isLoading = true),
          (state.isSuccess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(commonBorrowerDetailsUpdate.fulfilled, (state, action) => {
        if (action.payload?.action === 'lendboxRegistration') {
          state.borrowerDetails.action = FINISH;
        } else {
          const currentIndex = borrowerSteps.indexOf(action?.payload?.action);
          if (currentIndex < borrowerSteps.length - 1) {
            state.borrowerDetails.action = borrowerSteps[currentIndex + 1];
          } else {
            state.borrowerDetails.action = FINISH;
          }
        }

        const updatedDetails = { ...state.borrowerDetails };
        const payloadDetails = action.payload?.borrowerDetails;

        const updatedReferenceDetails = { ...state.referenceDetails };
        const payloadReferenceDetails = action.payload?.referenceDetails
          ? action.payload?.referenceDetails
          : null;

        if (payloadReferenceDetails) {
          Object.keys(payloadReferenceDetails).forEach(key => {
            if (payloadReferenceDetails[key] !== null) {
              updatedReferenceDetails[key] =
                payloadReferenceDetails[key] &&
                payloadReferenceDetails[key] !== 'null'
                  ? payloadReferenceDetails[key]
                  : '';
            } else if (payloadReferenceDetails[key] === null) {
              updatedReferenceDetails[key] =
                payloadReferenceDetails[key] &&
                payloadReferenceDetails[key] !== 'null'
                  ? payloadReferenceDetails[key]
                  : '';
            }
          });
          state.referenceDetails = { ...updatedReferenceDetails };
        }

        const updatedBankDetails = { ...state.bankDetails };
        const payloadbankDetails = action.payload?.bankDetails
          ? action.payload?.bankDetails
          : null;

        if (payloadbankDetails) {
          Object.keys(payloadbankDetails).forEach(key => {
            if (payloadbankDetails[key] !== null) {
              updatedBankDetails[key] =
                payloadbankDetails[key] && payloadbankDetails[key] !== 'null'
                  ? payloadbankDetails[key]
                  : '';
            }
          });
          state.bankDetails = { ...updatedBankDetails };
        }

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });

        state.isLoading = false;
        state.isSuccess = true;
        state.borrowerDetails = { ...updatedDetails };
      })
      .addCase(commonBorrowerDetailsUpdate.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isSuccess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(verifyChequeOcr.pending, state => {
        (state.isChequeVerificationByOcrLoading = true),
          (state.isChequeVerificationByOcrSuccess = false),
          (state.isChequeVerificationByOcrFailed = false),
          (state.chequeVerificationErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyChequeOcr.fulfilled, (state, action) => {
        state.isChequeVerificationByOcrLoading = false;
        state.isChequeVerificationByOcrSuccess = true;

        const updatedDetails = { ...state.bankDetails };
        const payloadDetails = action.payload ? action.payload : null;

        Object.keys(payloadDetails).forEach(key => {
          if (payloadDetails[key] !== null) {
            updatedDetails[key] =
              payloadDetails[key] && payloadDetails[key] !== 'null'
                ? payloadDetails[key]
                : '';
          }
        });
        state.bankDetails = { ...updatedDetails };
      })
      .addCase(verifyChequeOcr.rejected, (state, action) => {
        (state.isChequeVerificationByOcrLoading = false),
          (state.isChequeVerificationByOcrSuccess = false),
          (state.isChequeVerificationByOcrFailed = true),
          (state.chequeVerificationErrorContainer = {
            ...state.chequeVerificationErrorContainer,
            ...action.payload
          });
      })
      .addCase(notifyBorrower.pending, state => {
        (state.isNotifyBorrowerLoading = true),
          (state.isNotifyBorrowerSuccess = false),
          (state.isNotifyBorrowerFailed = false),
          (state.notifyBorrowerErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(notifyBorrower.fulfilled, state => {
        state.isNotifyBorrowerLoading = false;
        (state.isNotifyBorrowerSuccess = true),
          (state.isNotifyBorrowerFailed = false);
      })
      .addCase(notifyBorrower.rejected, (state, action) => {
        (state.isNotifyBorrowerLoading = false),
          (state.isNotifyBorrowerSuccess = false),
          (state.isNotifyBorrowerFailed = true),
          (state.notifyBorrowerErrorContainer = {
            ...state.notifyBorrowerErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearNotificationState, state => {
        (state.isNotifyBorrowerSuccess = false),
          (state.isNotifyBorrowerFailed = false);
      })
      .addCase(fetchBureauResult.pending, state => {
        (state.isFetchBureauResultLoading = true),
          (state.isFetchBureauResultSuccess = false),
          (state.isFetchBureauResultFailed = false),
          (state.fetchBureauResultErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchBureauResult.fulfilled, (state, action) => {
        state.isFetchBureauResultLoading = false;
        (state.isFetchBureauResultSuccess = true),
          (state.isFetchBureauResultFailed = false),
          (state.bureauResult = action.payload);
      })
      .addCase(fetchBureauResult.rejected, (state, action) => {
        (state.isFetchBureauResultLoading = false),
          (state.isFetchBureauResultSuccess = false),
          (state.isFetchBureauResultFailed = true),
          (state.fetchBureauResultErrorContainer = {
            ...state.fetchBureauResultErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchLoanStatus.pending, state => {
        (state.isFetchLoanStatusLoading = true),
          (state.isFetchLoanStatusSuccess = false),
          (state.isFetchLoanStatusFailed = false),
          (state.fetchLoanStatusErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchLoanStatus.fulfilled, (state, action) => {
        state.isFetchLoanStatusLoading = false;
        (state.isFetchLoanStatusSuccess = true),
          (state.isFetchLoanStatusFailed = false),
          (state.loanStatusDetails = action.payload);
      })
      .addCase(fetchLoanStatus.rejected, (state, action) => {
        (state.isFetchLoanStatusLoading = false),
          (state.isFetchLoanStatusSuccess = false),
          (state.isFetchLoanStatusFailed = true),
          (state.fetchLoanStatusErrorContainer = {
            ...state.fetchLoanStatusErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearLoanDetails, state => {
        state.loanStatusDetails = {
          loanId: null,
          lbUserId: null,
          status: null,
          disbursalDate: null,
          metadata: null
        };
      })
      .addCase(clearBureauDetails, state => {
        state.bureauResult = {
          score: 0,
          totalActiveAccounts: 0,
          totalSecuredAccounts: 0,
          totalUnSecuredAccounts: 0,
          numberOfSpecialMentionedAccounts: 0,
          numberOfSubStandardAccounts: 0,
          numberOfDoubtfullAccounts: 0,
          numberOfLossAccounts: 0,
          numberOf30PlusDpdIn3Months: 0,
          numberOf90PlusDpdIn12Months: 0,
          totalOverDueAmount: 0,
          activeMonthlyEmi: 0
        };
      });
  }
});

export default borrowerSlice.reducer;
