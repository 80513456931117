import { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  IMG_SPLASH_FULL_LOGO,
  IMG_SPLASH_LOGO,
  IMG_SPLASH_LOGO_LEFT_TOP,
  IMG_SPLASH_LOGO_RIGHT_BOTTOM
} from '../utils/imageUrls';

const styles = {
  imageContainer: {
    position: 'absolute'
  },
  leftTop: {
    top: 0,
    left: 0
  },
  rightBottom: {
    bottom: 0,
    right: 0
  },
  center: {
    width: '12rem',
    height: '12rem',
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block'
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    display: 'block'
  },
  textContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    margin: '10px 10px'
  }
};

function SplashScreen() {
  useEffect(() => {
    document.title = 'Loading';
  }, []);

  return (
    <>
      <div style={{ ...styles.imageContainer, ...styles.leftTop }}>
        <img
          style={{ ...styles.image }}
          src={IMG_SPLASH_LOGO_LEFT_TOP}
          alt="Left Top Image"
        />
      </div>
      <div style={{ ...styles.imageContainer, ...styles.center }}>
        <img
          style={{ ...styles.image }}
          className="flash"
          src={IMG_SPLASH_LOGO}
          alt="Center Image"
        />
      </div>
      <div style={{ ...styles.imageContainer, ...styles.rightBottom }}>
        <img
          style={{ ...styles.image }}
          src={IMG_SPLASH_LOGO_RIGHT_BOTTOM}
          alt="Left Top Image"
        />
      </div>

      <div style={styles.textContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={6} md={6} lg={2} xl={2}>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: 15,
                  md: 19,
                  lg: 19,
                  xl: 19
                }
              }}
            >
              Copyrights with
            </Typography>
          </Grid>
          <Grid xs={6} md={6} lg={2} xl={2}>
            <img
              width={150}
              src={IMG_SPLASH_FULL_LOGO}
              alt="tbc_splash_full_logo"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SplashScreen;
