export const SENDING_OTP = 'Sending OTP';

export const RE_SENDING_OTP = 'Re-Sending OTP';

export const VERIFYING_OTP = 'Verifying OTP';

export const OTP_SENT_TO_NEW_EMAIL_ID = 'OTP sent to registered Email ID.';

export const EMAIL_UPDATED_SUCCESSFULLY = 'Email ID updated successfully.';

export const OTP_SENT_TO_NEW_MOBILE_NO = 'OTP sent to registered Mobile No.';

export const OTP_RE_SENT_TO_NEW_MOBILE_NO =
  'OTP Re-sent to registered Mobile No.';

export const MOBILE_NO_UPDATED_SUCCESSFULLY = 'Mobile No updated successfully.';

export const PLEASE_WAIT = 'Please wait.';

export const UPDATING_DETAILS = 'Updating details.';

export const NOMINEE_DETAILS_UPDATED_SUCCESSFULLY =
  'Nominee details updated successfully.';

export const SEND_REINVESTMENT_REQUEST = 'Sending Re-Investment request.';
export const SEND_REINVESTMENT_REQUEST_SUCCESS =
  'Re-Investment request submitted successfully.';

export const SEND_WITHDRAWAL_REQUEST = 'Sending withdrawal request.';
export const SEND_WITHDRAWAL_REQUEST_SUCCESS =
  'Withdrawal request submitted successfully.';

export const SEND_REFER_REQUEST = 'Sending Refer request.';

export const SEND_REFER_REQUEST_SUCCESS =
  'Refer request submitted successfully.';

export const SEND_RAISE_COMPLAINT_REQUEST = 'Sending raise complaint request.';

export const SEND_RAISE_COMPLAINT_REQUEST_SUCCESS =
  'Raise complaint submitted successfully.';

export const REFER_SMS_CONTENT = `Explore Exciting Investment Opportunities with UniLyfe!   ${process.env.REACT_APP_PORTAL_URL}`;

export const REFER_SMS_BODY_CONTENT = `Explore Exciting Investment Opportunities with UniLyfe!`;

export const OTP_VERIFICATION_MESSAGE = `OTP Verification`;

export const CANCEL_BUTTON_MESSAGE = `Cancel`;

export const NEXT_BUTTON_MESSAGE = `Save the details.`;

export const LEAD_ADDED_SUCCESS = `Lead added successfully.`;

export const OTP_VERIFIED_SUCCESS = `OTP Verified successfully.`;

export const CREATING_LEAD = `Creating a new lead.`;

export const CONSENT_ACCEPTED_SUCCESSFULLY = `Consent accepted successfully.`;

export const BRANCH_UPDATED_SUCCESSFULLY = 'Branch updated successfully.';
