import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

function SnackBarBox({ type, message = 'Error occurred' }) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 4000);
  }, []);

  let severity = 'info';
  if (type === 'error') {
    severity = 'error';
  } else if (type === 'warning') {
    severity = 'warning';
  } else if (type === 'info') {
    severity = 'info';
  } else if (type === 'success') {
    severity = 'success';
  }
  return (
    <>
      {isOpen && (
        <Snackbar
          open={isOpen}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={severity} variant="filled" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default SnackBarBox;
