import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FormButtonBottom, FormContent } from '../../utils/styledLayout';
import { LoadingButton } from '@mui/lab';
import UploadImage from '../../components/UploadImage';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import {
  convertToDatePicker,
  createTitle,
  enterOnlyAlphabates,
  enterOnlyAlphabetsAndNumbers,
  formatDate,
  handleError,
  isAlphabet,
  isArrayNotEmpty,
  isValidPanNumber
} from '../../utils/utils';
import {
  DOC_TYPE_PAN,
  PAN_VERIFICATION,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON,
  USER_TYPE_BORROWER
} from '../../utils/constant';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import ConfirmBox from '../../components/ConfirmBox';
import { fetchGenderList } from '../../redux/reducers/dropDownSlice';
import { colorSuccess } from '../../config/theme';
import {
  clearPanVerificationState,
  commonBorrowerDetailsAdd,
  handleBorrowerBackStep,
  handleBorrowerNextStep,
  validateBorrowerDetails,
  verifyPanByPanNumber,
  verifyPanByPanOcr
} from '../../redux/reducers/borrowerSlice';
import dayjs from 'dayjs';

const formControl = {
  margin: '0.5rem auto'
};
function PanVerificationBorrower({ borrowerId }) {
  const dispatch = useDispatch();

  const { genderList } = useSelector(state => state.dropdown);

  const {
    isPanVerificationByNumberLoading,
    isPanVerificationByNumberSuccess,
    isPanVerificationByNumberFailed,
    panVerificationByNumberErrorContainer,
    isPanVerificationByOcrLoading,
    isPanVerificationByOcrSuccess,
    isPanVerificationByOcrFailed,
    panVerificationByOcrErrorContainer,
    borrowerDetails
  } = useSelector(state => state.borrowers);

  useEffect(() => {
    document.title = createTitle('PAN Verification');
    dispatch(fetchGenderList());
    return () => dispatch(clearPanVerificationState());
  }, []);

  const [panVerifier, setPanVerifier] = useState({
    panNumber: '',
    isPanError: false,
    errorMessage: ''
  });

  const isPanDetailsAavailble = Boolean(
    borrowerDetails?.panNumber &&
      borrowerDetails?.nameAsPerPan &&
      borrowerDetails?.dobAsPerPan &&
      borrowerDetails?.gender
  );

  const handleSubmitWithValidation = async values => {
    try {
      let borrower = await validateBorrowerDetails(borrowerId);

      const { panNumber, nameAsPerPan, dobAsPerPan, gender } =
        borrower.borrowerDetails;

      if (panNumber && nameAsPerPan && dobAsPerPan && gender) {
        dispatch(handleBorrowerNextStep());
      } else {
        const newvalues = {
          borrowerDetails: {
            ...values,
            dobAsPerPan: formatDate(values.dobAsPerPan, 'YYYY-MM-DD')
          },
          action: PAN_VERIFICATION
        };
        dispatch(commonBorrowerDetailsAdd(newvalues));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    profileFormik.setValues({
      userId: borrowerId || 0,
      id: borrowerDetails?.id || 0,
      panNumber: borrowerDetails?.panNumber || '',
      dobAsPerPan: borrowerDetails?.dobAsPerPan
        ? dayjs(convertToDatePicker(borrowerDetails?.dobAsPerPan))
        : null,
      nameAsPerPan: borrowerDetails?.nameAsPerPan || '',
      gender: borrowerDetails?.gender || ''
    });
  }, [borrowerDetails]);

  const profileFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: 0,
      id: '',
      panNumber: '',
      dobAsPerPan: null,
      nameAsPerPan: '',
      gender: ''
    },
    validationSchema: yup.object({
      profileType: yup.string(),
      panNumber: yup
        .string()
        .test('validate-pan', 'Please enter a valid PAN number.', value =>
          isValidPanNumber(value)
        )
        .required('Please provide your PAN number.'),
      dobAsPerPan: yup.date().required('Please provide your Date of Birth.'),
      nameAsPerPan: yup
        .string()
        .required('Please provide your name as per PAN.')
        .test(
          'is-alphabet',
          'Name should consist of alphabetic characters only.',
          value => isAlphabet(value)
        ),
      gender: yup.string().required('Please select your gender.')
    }),
    onSubmit: values => {
      handleSubmitWithValidation(values);
    }
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePanVerification = () => {
    const validPan = isValidPanNumber(panVerifier.panNumber);
    if (validPan) {
      setPanVerifier({
        ...panVerifier,
        isPanError: false,
        errorMessage: ''
      });

      setIsModalOpen(true);
    } else {
      setPanVerifier({
        ...panVerifier,
        isPanError: true,
        errorMessage: 'PAN Number is required.'
      });
    }
  };
  const handlePanChange = e => {
    setPanVerifier({
      ...panVerifier,
      panNumber: enterOnlyAlphabetsAndNumbers(
        e.target.value.trim().toUpperCase()
      )
    });
  };

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('userId', borrowerId);
      formData.append('userType', USER_TYPE_BORROWER);
      formData.append('file', fileData?.file);
      formData.append('ocrType', DOC_TYPE_PAN);
      dispatch(verifyPanByPanOcr(formData));
    }
  };

  const [profileType, setProfileType] = useState('panProfile');

  const handleProfileChange = event => {
    setProfileType(event.target.value);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setProfileType('');
  };
  const handleSubmit = () => {
    setIsModalOpen(false);
    dispatch(verifyPanByPanNumber({ pan: panVerifier.panNumber }));
  };

  return (
    <>
      <form autoComplete="off" onSubmit={profileFormik.handleSubmit}>
        <FormContent>
          <Typography
            marginBottom={'1rem'}
            align="center"
            variant="body1"
            fontWeight={700}
          >
            PAN Verification
          </Typography>

          {!(
            isPanVerificationByOcrSuccess ||
            isPanVerificationByNumberSuccess ||
            isPanDetailsAavailble
          ) && (
            <>
              <FormControl sx={formControl} fullWidth size="small">
                <Typography
                  marginBottom={'1rem'}
                  align="left"
                  variant="body2"
                  fontWeight={500}
                  color="black"
                >
                  Do you want to process with PAN profile or PAN Card Image?{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </FormControl>

              <FormControl fullWidth size="small">
                <RadioGroup
                  name="profileType"
                  value={profileType}
                  onChange={handleProfileChange}
                >
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top"
                        title="Choose the PAN profile, enter a valid PAN number, and details will be fetched automatically."
                      >
                        <FormControlLabel
                          value="panProfile"
                          control={<Radio color="golden" />}
                          label="PAN Profile"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top"
                        title="Select PAN Card Image, upload the PAN, and details will be fetched automatically."
                      >
                        <FormControlLabel
                          value="panOcr"
                          control={<Radio color="golden" />}
                          label="PAN Image"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>

              {profileType === 'panProfile' ? (
                <FormControl sx={formControl} fullWidth size="small">
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <TextField
                        size="small"
                        label="PAN Number"
                        required
                        inputProps={{ maxLength: 10 }}
                        value={panVerifier.panNumber}
                        onChange={e => handlePanChange(e)}
                        error={Boolean(panVerifier.isPanError)}
                        helperText={panVerifier.errorMessage}
                      />
                    </Grid>
                    {isPanVerificationByNumberSuccess ? (
                      <Grid item xs={5}>
                        <div style={{ display: 'inline-flex', padding: '8px' }}>
                          <CheckCircleIcon color="success" />
                          <Typography
                            variant="subtitle2"
                            color="success"
                            sx={{ color: colorSuccess }}
                          >
                            PAN Verified
                          </Typography>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={5}>
                        <LoadingButton
                          fullWidth
                          style={{ textTransform: 'uppercase' }}
                          variant="contained"
                          color="golden"
                          type="button"
                          onClick={handlePanVerification}
                        >
                          Verify
                        </LoadingButton>
                      </Grid>
                    )}
                  </Grid>
                </FormControl>
              ) : (
                <>
                  <FormControl sx={formControl} fullWidth size="small">
                    <Typography
                      align="left"
                      variant="body2"
                      fontWeight={600}
                      color="black"
                    >
                      Upload PAN card <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </FormControl>
                  <FormControl
                    sx={{ ...formControl, float: 'left' }}
                    size="small"
                  >
                    <UploadImage onImageUpload={onImageUpload} />
                  </FormControl>
                </>
              )}
            </>
          )}

          {(isPanVerificationByOcrSuccess ||
            isPanVerificationByNumberSuccess ||
            isPanDetailsAavailble) && (
            <>
              <FormControl
                sx={{ ...formControl, marginTop: '1.5rem' }}
                fullWidth
                size="small"
              >
                <TextField
                  label="PAN Number"
                  name="panNumber"
                  required
                  inputProps={{ maxLength: 10 }}
                  value={profileFormik.values.panNumber}
                  size="small"
                  onChange={e =>
                    profileFormik.setFieldValue(
                      'panNumber',
                      enterOnlyAlphabetsAndNumbers(e.target.value)
                    )
                  }
                  disabled
                  error={Boolean(
                    profileFormik.touched.panNumber &&
                      profileFormik.errors.panNumber
                  )}
                  helperText={
                    profileFormik.touched.panNumber &&
                    profileFormik.errors.panNumber
                  }
                />
              </FormControl>

              <FormControl sx={formControl} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    disableFuture
                    slotProps={{ textField: { size: 'small' } }}
                    format="DD/MM/YYYY"
                    disabled={Boolean(borrowerDetails?.dobAsPerPan)}
                    onChange={value =>
                      profileFormik.setFieldValue('dobAsPerPan', value, true)
                    }
                    value={profileFormik.values.dobAsPerPan || null}
                    textField={props => (
                      <TextField
                        {...props}
                        label="Date of Birth"
                        margin="normal"
                        name="dobAsPerPan"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {profileFormik.touched.dobAsPerPan &&
                  profileFormik.errors.dobAsPerPan && (
                    <FormHelperText sx={{ textAlign: 'start' }}>
                      {profileFormik.errors.dobAsPerPan}
                    </FormHelperText>
                  )}
              </FormControl>
              <FormControl sx={formControl} fullWidth size="small">
                <TextField
                  required
                  label="Name (As Per PAN)"
                  name="nameAsPerPan"
                  inputProps={{ maxLength: 40 }}
                  value={profileFormik.values.nameAsPerPan || ''}
                  size="small"
                  disabled={Boolean(borrowerDetails?.nameAsPerPan)}
                  onChange={e =>
                    profileFormik.setFieldValue(
                      'nameAsPerPan',
                      enterOnlyAlphabates(e.target.value)
                    )
                  }
                  error={Boolean(
                    profileFormik.touched.nameAsPerPan &&
                      profileFormik.errors.nameAsPerPan
                  )}
                  helperText={
                    profileFormik.touched.nameAsPerPan &&
                    profileFormik.errors.nameAsPerPan
                  }
                />
              </FormControl>

              <FormControl sx={formControl} fullWidth size="small">
                <InputLabel id="select-gender" required>
                  Gender
                </InputLabel>
                <Select
                  labelId="select-gender"
                  id="select-gender"
                  label="Gender"
                  disabled={Boolean(borrowerDetails?.gender)}
                  value={profileFormik.values.gender}
                  onChange={profileFormik.handleChange}
                  name="gender"
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {isArrayNotEmpty(genderList) &&
                    genderList.map(gender => {
                      return (
                        <MenuItem key={gender?.key} value={gender?.key}>
                          <em>{gender?.value}</em>
                        </MenuItem>
                      );
                    })}
                </Select>
                {profileFormik.touched.gender &&
                  profileFormik.errors.gender && (
                    <FormHelperText sx={{ textAlign: 'start' }}>
                      {profileFormik.errors.gender}
                    </FormHelperText>
                  )}
              </FormControl>
            </>
          )}
        </FormContent>

        <Box marginTop={22}>
          <FormButtonBottom>
            {profileFormik.touched.panNumber &&
              profileFormik.errors.panNumber && (
                <FormHelperText sx={{ textAlign: 'start' }}>
                  Please complete the PAN verification process first and then
                  click the Save & Next button.
                </FormHelperText>
              )}

            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={6}>
                <Tooltip title={SELECT_BACK_BUTTON}>
                  <LoadingButton
                    fullWidth
                    style={{ textTransform: 'uppercase' }}
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => dispatch(handleBorrowerBackStep())}
                  >
                    Back
                  </LoadingButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={SELECT_NEXT_BUTTON}>
                  <LoadingButton
                    fullWidth
                    style={{ textTransform: 'uppercase' }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save & next
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </FormButtonBottom>

          {isModalOpen && (
            <ConfirmBox
              title="Confirm PAN"
              subtitle={
                <>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Please confirm whether the entered PAN number is correct.
                  </Typography>
                  <Typography marginTop={1} variant="subtitle2">
                    <strong>PAN Number : </strong> {panVerifier.panNumber}
                  </Typography>
                </>
              }
              isOpen={isModalOpen}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          )}
        </Box>
        {isPanVerificationByNumberLoading && (
          <Loader text="Verifying details by PAN Number" />
        )}
        {isPanVerificationByOcrLoading && (
          <Loader text="Verifying details by Image" />
        )}
        {!isPanVerificationByOcrLoading && isPanVerificationByOcrFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              panVerificationByOcrErrorContainer?.errorMessage
            )}
          />
        )}
        {!isPanVerificationByNumberLoading &&
          isPanVerificationByNumberFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                panVerificationByNumberErrorContainer?.errorMessage
              )}
            />
          )}
        {!isPanVerificationByOcrLoading && isPanVerificationByOcrSuccess && (
          <SnackBarBox
            type="success"
            message="PAN verification via Image successful."
          />
        )}
        {!isPanVerificationByNumberLoading &&
          isPanVerificationByNumberSuccess && (
            <SnackBarBox
              type="success"
              message="PAN verification via PAN Number successful."
            />
          )}
      </form>
    </>
  );
}

PanVerificationBorrower.propTypes = {
  borrowerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PanVerificationBorrower;
