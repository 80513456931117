import { calculateMonthlyReturn } from '../../utils/utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function roundToNearestLakh(value) {
  return value;
}

const InvestorRequirementGraph = ({ amount }) => {
  const labels = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const maximumAmount = useMemo(() => {
    const principalAmount = Number(amount);
    const annualInterestRate =
      Number(process.env.REACT_APP_RATE_OF_INTEREST) / 100;
    return principalAmount * Math.pow(1 + annualInterestRate, 1);
  }, [amount]);

  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: labels,
      title: {
        text: 'Tenure (Months)'
      },
      gridLineWidth: 1
    },
    yAxis: {
      max: roundToNearestLakh(maximumAmount),
      title: {
        text: 'Amount(₹)'
      },
      labels: {
        formatter: function () {
          return `₹ ${new Intl.NumberFormat('en-IN').format(this.value)}`;
        }
      }
    },
    tooltip: {
      borderRadius: 10,
      formatter: function () {
        return `<span style="font-weight: bold;">Expected Returns at month ${
          this.x
        }</span><br/><br/><span style="font-size: larger;font-weight: bold;">₹ ${new Intl.NumberFormat(
          'en-IN'
        ).format(this.y)}</span>`;
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        showInLegend: false,
        name: 'Expected Returns',
        data: labels.map(tenure =>
          Number(calculateMonthlyReturn(amount, tenure))
        ),
        color: '#FEC310'
      }
    ]
  };

  return (
    <>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <HighchartsReact
          allowChartUpdate={true}
          immutable={false}
          updateArgs={[true, true, true]}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </>
  );
};

InvestorRequirementGraph.propTypes = {
  amount: PropTypes.any
};

export default InvestorRequirementGraph;
